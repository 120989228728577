import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
    lsCommunications: {},
    communicationItem: {},
    communicationUpdate: {},
    insertCommunication: {},
    communicationDelete: {},
    communicationsFailed: false,
    communicationItemFailed: false,
    communicationUpdateFailed: false,
    insertCommunicationFailed: false,
    communicationDeleteFailed: false,
};

// get all
const getCommunicationData = (state, action) => {
    return updateObject(state, {
        lsCommunications: action.lsCommunications
    });
};

// get by id
const getCommunicationById = (state, action) => {
    return updateObject(state, {
        communicationItem: action.communicationItem
    });
};

// update
const updateCommunication = (state, action) => {
    return updateObject(state, {
        communicationUpdate: action.communicationUpdate
    });
};

// update
const postCommunication = (state, action) => {
    return updateObject(state, {
        insertCommunication: action.insertCommunication
    });
};

// delete
const deleteCommunication = (state, action) => {
    return updateObject(state, {
        communicationDelete: action.communicationDelete
    });
};

// ++++++++++++++++ DATA FAIL ++++++++++++++++++++++++
const getCommunicationFailed = (state, action) => {
    return updateObject(state, {
        communicationsFailed: action.communicationsFailed
    });
};

// get by id
const getCommunicationByIdFailed = (state, action) => {
    return updateObject(state, {
        communicationItemFailed: action.communicationItemFailed
    });
};

const updateCommunicationFailed = (state, action) => {
    return updateObject(state, {
        communicationUpdateFailed: action.communicationUpdateFailed
    });
};

const postCommunicationFailed = (state, action) => {
    return updateObject(state, {
        insertCommunicationFailed: action.insertCommunicationFailed
    });
};

const deleteCommunicationFailed = (state, action) => {
    return updateObject(state, {
        communicationDeleteFailed: action.communicationDeleteFailed
    });
};

//
const CommunicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMMUNICATIONS_DATA:
            return getCommunicationData(state, action);
        case actionTypes.GET_COMMUNICATIONS_DATA_FAILED:
            return getCommunicationFailed(state, action);

        case actionTypes.GET_COMMUNICATION_BY_ID:
            return getCommunicationById(state, action);
        case actionTypes.GET_COMMUNICATION_BY_ID_FAILED:
            return getCommunicationByIdFailed(state, action);

        case actionTypes.POST_COMMUNICATION_DATA:
            return postCommunication(state, action);
        case actionTypes.POST_COMMUNICATION_DATA_FAILED:
            return postCommunicationFailed(state, action);

        case actionTypes.PUT_COMMUNICATION_DATA:
            return updateCommunication(state, action);
        case actionTypes.PUT_COMMUNICATION_FAILED:
            return updateCommunicationFailed(state, action);

        case actionTypes.DELETE_COMMUNICATION_DATA:
            return deleteCommunication(state, action);
        case actionTypes.DELETE_COMMUNICATION_FAILED:
            return deleteCommunicationFailed(state, action);

        default:
            return state;
    }
};

export default CommunicationReducer;