import axios from 'axios';

// @Get()
export const GetProductions = () => {
    return axios.get(`/production`)
        .then(response => response.data);
}

// @Get(':id') 
export const GetProductionById = (id) => {
    return axios.get(`/production/${id}`)
        .then(response => response.data);
}

// @Put(":id")
export const PutProduction = (id, obj) => {
    return axios.put(`/production/${id}`, obj)
        .then(response => response.data);
}

// @Post()
export const PostProduction = (obj) => {
    return axios.post(`/production/`, obj)
        .then(response => response.data);
}

// @Delete(":id")
export const DeleteProduction = (id) => {
    return axios.delete(`/production/${id}`)
        .then(response => response.data);
}