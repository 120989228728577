import axios from 'axios';

// @Get()
export const GetInsurers = () => {
    return axios.get(`/insurer`)
        .then(response => response.data);
}

// @Get(':id') 
export const GetInsurerById = (id) => {
    return axios.get(`/insurer/${id}`)
        .then(response => response.data);
}

// @Put(":id")
export const PutInsurer = (id, obj) => {
    return axios.put(`/insurer/${id}`, obj)
        .then(response => response.data);
}

// @Post()
export const PostInsurer = (obj) => {
    return axios.post(`/insurer/`, obj)
        .then(response => response.data);
}

// @Delete(":id")
export const DeleteInsurer = (id) => {
    return axios.delete(`/insurer/${id}`)
        .then(response => response.data);
}