// ##############################
// // // javascript library for creating charts
// #############################
var Chartist = require("chartist");
var Legend = require("chartist-plugin-legend");
var tooltip = require("chartist-plugin-tooltips");
var { numberFormatText } = require("utils/utils");
// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
	durations = 500;

const dashGraph = {
	options: {
		lineSmooth: Chartist.Interpolation.cardinal({
			tension: 10
		}),
		axisY: {
			// type: Chartist.AutoScaleAxis,
			// onlyInteger: false,
			showGrid: true,
			offset: 80,
			labelInterpolationFnc: function (value) {
				return "R$ " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			}
		},
		axisX: {
			showGrid: false,
			labelInterpolationFnc: function (value) {
				return value;
			}
		},
		showPoint: true,
		height: "214px",
		fullWidth: true,
		plugins: [
			Chartist.plugins.tooltip({
				transformTooltipTextFnc: function (tooltip) {
					// console.log(tooltip);
					let value = tooltip;
					value = value.toString().replace(".", ";");
					value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
					value = value.toString().replace(";", ",");
					return "R$ " + value;
				}
			}),
			// Chartist.plugins.tooltip(),
			Chartist.plugins.legend({
				position: 'bottom'
			})
		]
	},

	animation: {
		draw: function (data) {
			if (data.type === "line" || data.type === "area") {
				data.element.animate({
					d: {
						begin: 600,
						dur: 700,
						from: data.path
							.clone()
							.scale(1, 0)
							.translate(0, data.chartRect.height())
							.stringify(),
						to: data.path.clone().stringify(),
						easing: Chartist.Svg.Easing.easeOutQuint
					}
				});
			} else if (data.type === "point") {
				data.element.animate({
					opacity: {
						begin: (data.index + 1) * delays,
						dur: durations,
						from: 0,
						to: 1,
						easing: "ease"
					}
				});
			}
		}
	}
};

module.exports = {
	dashGraph
};