import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
    lsBusinessUnit: {},
    businessUnitItem: {},
    businessUnitUpdate: {},
    insertBusinessUnit: {},
    businessUnitDelete: {},
    businessUnitsFailed: false,
    businessUnitItemFailed: false,
    businessUnitUpdateFailed: false,
    insertBusinessUnitFailed: false,
    businessUnitDeleteFailed: false,
};

// get all
const getBusinessUnitData = (state, action) => {
    return updateObject(state, {
        lsBusinessUnit: action.lsBusinessUnit
    });
};

// get by id
const getBusinessUnitById = (state, action) => {
    return updateObject(state, {
        businessUnitItem: action.businessUnitItem
    });
};

// update
const updateBusinessUnit = (state, action) => {
    return updateObject(state, {
        businessUnitUpdate: action.businessUnitUpdate
    });
};

// update
const postBusinessUnit = (state, action) => {
    return updateObject(state, {
        insertBusinessUnit: action.insertBusinessUnit
    });
};

// delete
// const deleteBusinessUnit = (state, action) => {
//     return updateObject(state, {
//         businessUnitDelete: action.businessUnitDelete
//     });
// };

// ++++++++++++++++ DATA FAIL ++++++++++++++++++++++++
const getBusinessUnitFailed = (state, action) => {
    return updateObject(state, {
        businessUnitsFailed: action.businessUnitsFailed ? action.businessUnitsFailed.response.data : action.businessUnitsFailed
    });
};

// get by id
const getBusinessUnitByIdFailed = (state, action) => {
    return updateObject(state, {
        businessUnitItemFailed: action.businessUnitItemFailed.response.data
    });
};

const updateBusinessUnitFailed = (state, action) => {
    return updateObject(state, {
        businessUnitUpdateFailed: action.businessUnitUpdateFailed.response.data
    });
};

const postBusinessUnitFailed = (state, action) => {
    return updateObject(state, {
        insertBusinessUnitFailed: action.insertBusinessUnitFailed.response.data
    });
};

// const deleteBusinessUnitFailed = (state, action) => {
//     return updateObject(state, {
//         businessUnitDeleteFailed: action.businessUnitDeleteFailed
//     });
// };

//
const BusinessUnitReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BUSINESS_UNIT_DATA:
            return getBusinessUnitData(state, action);
        case actionTypes.GET_BUSINESS_UNIT_DATA_FAILED:
            return getBusinessUnitFailed(state, action);

        case actionTypes.GET_BUSINESS_UNIT_BY_ID:
            return getBusinessUnitById(state, action);
        case actionTypes.GET_BUSINESS_UNIT_BY_ID_FAILED:
            return getBusinessUnitByIdFailed(state, action);

        case actionTypes.POST_BUSINESS_UNIT_DATA:
            return postBusinessUnit(state, action);
        case actionTypes.POST_BUSINESS_UNIT_DATA_FAILED:
            return postBusinessUnitFailed(state, action);

        case actionTypes.PUT_BUSINESS_UNIT_DATA:
            return updateBusinessUnit(state, action);
        case actionTypes.PUT_BUSINESS_UNIT_FAILED:
            return updateBusinessUnitFailed(state, action);

        // case actionTypes.DELETE_BUSINESS_UNIT_DATA:
        //     return deleteBusinessUnit(state, action);
        // case actionTypes.DELETE_BUSINESS_UNIT_FAILED:
        //     return deleteBusinessUnitFailed(state, action);

        default:
            return state;
    }
};

export default BusinessUnitReducer;