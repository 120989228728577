import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import Backend from "i18next-chained-backend";
// import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		backend: {
			backends: [XHR],
			backendOptions: [
				{
					prefix: "i18next_",
					versions: { en: "v1.0", pt: "v1.0", es: "v1.0" },
				},
				{
					loadPath: "/locales/{{lng}}/{{ns}}.json",
				},
			],
		},
		fallbackLng: {
			en: ["en"],
			es: ["es"],
			pt: ["pt-BR"],
			default: ["pt-BR"],
		},
		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		react: {
			wait: true,
		},
		ns: ["common"],
		defaultNS: "common",
	});

export default i18n;
