import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
    lsPartners: {},
    partnerItem: {},
    partnerUpdate: {},
    insertPartner: {},
    partnerDelete: {},
    partnersFailed: false,
    partnerItemFailed: false,
    partnerUpdateFailed: false,
    insertPartnerFailed: false,
    partnerDeleteFailed: false,
};

// get all
const getPartnerData = (state, action) => {
    return updateObject(state, {
        lsPartners: action.lsPartners
    });
};

// get by id
const getPartnerById = (state, action) => {
    return updateObject(state, {
        partnerItem: action.partnerItem
    });
};

// update
const updatePartner = (state, action) => {
    return updateObject(state, {
        partnerUpdate: action.partnerUpdate
    });
};

// update
const postPartner = (state, action) => {
    return updateObject(state, {
        insertPartner: action.insertPartner
    });
};

// delete
const deletePartner = (state, action) => {
    return updateObject(state, {
        partnerDelete: action.partnerDelete
    });
};

// ++++++++++++++++ DATA FAIL ++++++++++++++++++++++++
const getPartnerFailed = (state, action) => {
    return updateObject(state, {
        partnersFailed: action.partnersFailed ? action.partnersFailed.response.data : action.partnersFailed
    });
};

// get by id
const getPartnerByIdFailed = (state, action) => {
    return updateObject(state, {
        partnerItemFailed: action.partnerItemFailed.response.data
    });
};

const updatePartnerFailed = (state, action) => {
    return updateObject(state, {
        partnerUpdateFailed: action.partnerUpdateFailed.response.data
    });
};

const postPartnerFailed = (state, action) => {
    return updateObject(state, {
        insertPartnerFailed: action.insertPartnerFailed.response.data
    });
};

const deletePartnerFailed = (state, action) => {
    return updateObject(state, {
        partnerDeleteFailed: action.partnerDeleteFailed.response.data
    });
};

//
const PartnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PARTNERS_DATA:
            return getPartnerData(state, action);
        case actionTypes.GET_PARTNERS_DATA_FAILED:
            return getPartnerFailed(state, action);

        case actionTypes.GET_PARTNER_BY_ID:
            return getPartnerById(state, action);
        case actionTypes.GET_PARTNER_BY_ID_FAILED:
            return getPartnerByIdFailed(state, action);

        case actionTypes.POST_PARTNER_DATA:
            return postPartner(state, action);
        case actionTypes.POST_PARTNER_DATA_FAILED:
            return postPartnerFailed(state, action);

        case actionTypes.PUT_PARTNER_DATA:
            return updatePartner(state, action);
        case actionTypes.PUT_PARTNER_FAILED:
            return updatePartnerFailed(state, action);

        case actionTypes.DELETE_PARTNER_DATA:
            return deletePartner(state, action);
        case actionTypes.DELETE_PARTNER_FAILED:
            return deletePartnerFailed(state, action);

        default:
            return state;
    }
};

export default PartnerReducer;