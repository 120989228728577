import React from "react";
import NumberFormat from 'react-number-format';
import * as _ from "lodash";

import { createMuiTheme } from "@material-ui/core/styles";

export const numberFormatText = (_data, _prefix, _toFix) => {
    let _toFixed = _toFix === undefined ? 0 : _toFix;
    let tSeparator = ".";
    let dSeparator = ",";
    //
    let v = _toFixed === 0 ? _.toInteger(_data) : Number(_data).toFixed(_toFixed);
    return <NumberFormat value={v} displayType={'text'} thousandSeparator={tSeparator} decimalSeparator={dSeparator} decimalScale={_toFixed}
        prefix={_prefix} isNumericString={true} />;
}


const brandColor = '#3c4858';
export const themeUtil = () => {

    return createMuiTheme({
        overrides: {
            MuiButtonBase: { // Name of the component ⚛️ / style sheet
                root: { // Name of the rule
                    border: '1px solid green !important',
                    borderRadius: '3px',
                    padding: '0 12px 0 12px !important',
                    maxWidth: '32px !important',
                    height: '33px',
                    margin: '3px',
                    "&:hover": {
                        background: "green !important",
                        color: "white"
                    }
                }
            }
        },
        palette: {
            primary: {
                main: brandColor,
            },
        },
        typography: {
            useNextVariants: true,
        },
    })
}

export const VerifyCPF = (cpf) => {
    let valido = true;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') valido = false;
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999") {
        valido = false;
    }
    // Valida 1o digito	
    let add = 0;
    for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) {
        rev = 0;
    }
    if (rev != parseInt(cpf.charAt(9))) {
        valido = false;
    }
    // Valida 2o digito	
    add = 0;
    for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) { rev = 0; }
    if (rev != parseInt(cpf.charAt(10))) {
        valido = false;
    }
    //
    if (valido) {
        // console.log('O CPF INFORMADO É VÁLIDO.');
    } else {
        // console.log("o CPF é inválido");
    }
    return valido;
}

export const VerifyCNPJ = (cnpj) => {
    let valido = true;
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj == '') return false;
    if (cnpj.length != 14) {
        valido = false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999") {
        valido = false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    let
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) {
        valido = false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) {
        valido = false;
    }
    //
    if (valido) {
        // console.log('O CNPJ INFORMADO É VÁLIDO.');
    } else {
        // console.log("o CNPJ é inválido");
    }
    return valido;
}