import * as actionTypes from "./actionsTypes";
import * as API from "../api/attachments.js";
import * as Loader from './loader';

// GET ALL
export const GetAttachments = () => {
    return dispatch => (
        API.DeleteAttachment().then(rest => {
            dispatch(success(rest));
            return rest;
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_ATTACHMENTS_DATA,
            lsAttachments: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_ATTACHMENTS_DATA_FAILED,
            attachmentsFailed: error
        };
    };
}

// GET BY ID
export const GetAttachmentsById = (id) => {
    return dispatch => (
        API.GetAttachmentById(id).then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_ATTACHMENT_BY_ID,
            attachmentItem: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_ATTACHMENT_BY_ID_FAILED,
            attachmentItemFailed: error
        };
    };
}

// GET BY PAGE TITLE
export const GetAttachmentsByPage = (page) => {
    return dispatch => (
        API.GetAttachmentsByPage(page).then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_ATTACHMENT_BY_PAGE,
            attachmentsPage: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_ATTACHMENT_BY_PAGE_FAILED,
            attachmentsPageFailed: error
        };
    };
}

// REGISTER


// UPDATE
export const PutAttachments = (id, obj) => {
    return dispatch => (
        API.PutAttachment(id, obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.PUT_ATTACHMENT_DATA,
            attachmentUpdate: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.PUT_ATTACHMENT_FAILED,
            attachmentUpdateFailed: error
        };
    };
}

// POST
export const PostAttachmentsData = (obj) => {
    return dispatch => {
        API.PostAttachment(obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.POST_ATTACHMENT_DATA,
            insertAttachment: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.POST_ATTACHMENT_DATA_FAILED,
            insertAttachmentFailed: error
        };
    };
}

// DELETE
export const DeleteAttachments = (id) => {
    return dispatch => {
        API.DeleteAttachment(id).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.DELETE_ATTACHMENT_DATA,
            attachmentDelete: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.DELETE_ATTACHMENT_DATA_FAILED,
            attachmentDeleteFailed: error
        };
    };
}
