import * as actionTypes from "./actionsTypes";
import * as API from "../api/businessUnit.js";
import * as Loader from './loader';

// GET ALL
export const GetBusinessUnit = () => {
    return dispatch => (
        API.GetBusinessUnit().then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_BUSINESS_UNIT_DATA,
            lsBusinessUnit: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_BUSINESS_UNIT_DATA_FAILED,
            businessUnitsFailed: error
        };
    };
}

// GET BY ID
export const GetBusinessUnitById = (id) => {
    return dispatch => (
        API.GetBusinessUnitById(id).then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_BUSINESS_UNIT_BY_ID,
            businessUnitItem: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_BUSINESS_UNIT_BY_ID_FAILED,
            businessUnitItemFailed: error
        };
    };
}

// UPDATE
export const PutBusinessUnit = (id, obj) => {
    return dispatch => (
        API.PutBusinessUnit(id, obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.PUT_BUSINESS_UNIT_DATA,
            businessUnitUpdate: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.PUT_BUSINESS_UNIT_FAILED,
            businessUnitUpdateFailed: error
        };
    };
}

// POST
export const PostBusinessUnitData = (obj) => {
    return dispatch => {
        API.PostBusinessUnit(obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.POST_BUSINESS_UNIT_DATA,
            insertBusinessUnit: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.POST_BUSINESS_UNIT_DATA_FAILED,
            insertBusinessUnitFailed: error
        };
    };
}

// DELETE
// export const DeleteBusinessUnit = (id) => {
//     return dispatch => {
//         API.DeleteBusinessUnit(id).then(response => {
//             dispatch(success(response));
//         }).catch(error => {
//             console.error(error);
//             dispatch(failed());
//             dispatch(Loader.hideLoader());
//         })
//     };

//     function success(data) {
//         return {
//             type: actionTypes.POST_BUSINESS_UNIT_DATA,
//             businessUnitDelete: data
//         };
//     };

//     function failed(error) {
//         return {
//             type: actionTypes.POST_BUSINESS_UNIT_DATA_FAILED,
//             businessUnitDeleteFailed: error
//         };
//     };
// }
