import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
    lsInsurers: {},
    insurerItem: {},
    insurerUpdate: {},
    insertInsurer: {},
    insurerDelete: {},
    insurersFailed: false,
    insurerItemFailed: false,
    insurerUpdateFailed: false,
    insertInsurerFailed: false,
    insurerDeleteFailed: false,
};

// get all
const getInsurerData = (state, action) => {
    return updateObject(state, {
        lsInsurers: action.lsInsurers
    });
};

// get by id
const getInsurerById = (state, action) => {
    return updateObject(state, {
        insurerItem: action.insurerItem
    });
};

// update
const updateInsurer = (state, action) => {
    return updateObject(state, {
        insurerUpdate: action.insurerUpdate
    });
};

// update
const postInsurer = (state, action) => {
    return updateObject(state, {
        insertInsurer: action.insertInsurer
    });
};

// delete
const deleteInsurer = (state, action) => {
    return updateObject(state, {
        insurerDelete: action.insurerDelete
    });
};

// ++++++++++++++++ DATA FAIL ++++++++++++++++++++++++
const getInsurerFailed = (state, action) => {
    return updateObject(state, {
        insurersFailed: action.insurersFailed.response.data
    });
};

// get by id
const getInsurerByIdFailed = (state, action) => {
    return updateObject(state, {
        insurerItemFailed: action.insurerItemFailed.response.data
    });
};

const updateInsurerFailed = (state, action) => {
    return updateObject(state, {
        insurerUpdateFailed: action.insurerUpdateFailed.response.data
    });
};

const postInsurerFailed = (state, action) => {
    return updateObject(state, {
        insertInsurerFailed: action.insertInsurerFailed.response.data
    });
};

const deleteInsurerFailed = (state, action) => {
    return updateObject(state, {
        insurerDeleteFailed: action.insurerDeleteFailed.response.data
    });
};

//
const InsurerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INSURERS_DATA:
            return getInsurerData(state, action);
        case actionTypes.GET_INSURERS_DATA_FAILED:
            return getInsurerFailed(state, action);

        case actionTypes.GET_INSURER_BY_ID:
            return getInsurerById(state, action);
        case actionTypes.GET_INSURER_BY_ID_FAILED:
            return getInsurerByIdFailed(state, action);

        case actionTypes.POST_INSURER_DATA:
            return postInsurer(state, action);
        case actionTypes.POST_INSURER_DATA_FAILED:
            return postInsurerFailed(state, action);

        case actionTypes.PUT_INSURER_DATA:
            return updateInsurer(state, action);
        case actionTypes.PUT_INSURER_FAILED:
            return updateInsurerFailed(state, action);

        case actionTypes.DELETE_INSURER_DATA:
            return deleteInsurer(state, action);
        case actionTypes.DELETE_INSURER_FAILED:
            return deleteInsurerFailed(state, action);

        default:
            return state;
    }
};

export default InsurerReducer;