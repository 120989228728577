import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {
    loggedIn: true,
    user: user,
    changePassword: false,
    changePasswordFailed: false
} : {
        loggedIn: false,
        logginFailed: false,
        user: {}
    };

export function authentication(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case actionTypes.LOGIN_FAILURE:
            return {
                logginIn: false,
                logginFailed: true
            }
        case actionTypes.LOGOUT:
            return {};
        case actionTypes.CHANGE_PASSWORD:
            return updateObject(state, {
                changePassword: action.changePassword,
                changePasswordFailed: false
            });
        case actionTypes.CHANGE_PASSWORD_FAILURE:
            return updateObject(state, {
                changePassword: false,
                changePasswordFailed: action.error
            });
        // return {
        //     changePasswordFailed: action
        // };

        default:
            return state;
    }
}