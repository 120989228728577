import axios from 'axios';

// @Get()
export const GetDocuments = () => {
    return axios.get(`/document`)
        .then(response => response.data);
}

// @Get(':id') 
export const GetDocumentById = (id) => {
    return axios.get(`/document/${id}`)
        .then(response => response.data);
}

// @Put(":id")
export const PutDocument = (id, obj) => {
    return axios.put(`/document/${id}`, obj)
        .then(response => response.data);
}

// @Post()
export const PostDocument = (obj) => {
    return axios.post(`/document/`, obj)
        .then(response => response.data);
}

// @Delete(":id")
export const DeleteDocument = (id) => {
    return axios.delete(`/document/${id}`)
        .then(response => response.data);
}