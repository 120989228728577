import axios from 'axios';

// @Get()
export const GetAttachments = () => {
    return axios.get(`/attachments`)
        .then(response => response.data);
}

// @Get(':id') 
export const GetAttachmentById = (id) => {
    return axios.get(`/attachments/${id}`)
        .then(response => response.data);
}

// @Get("attachmentsByPage/:page")
export const GetAttachmentsByPage = (page) => {
    return axios.get(`/findAttachmentsByPage/${page}`)
        .then(response => response.data);
}


// @Put(":id")
export const PutAttachment = (id, obj) => {
    return axios.put(`/attachments/${id}`, obj)
        .then(response => response.data);
}

// @Post()
export const PostAttachment = (obj) => {
    return axios.post(`/attachments/`, obj)
        .then(response => response.data);
}

// @Delete(":id")
export const DeleteAttachment = (id) => {
    return axios.delete(`/attachments/${id}`)
        .then(response => response.data);
}