import React, { Fragment } from "react";
import * as _ from "lodash";
import compose from "utils/compose";
import MaskedInput from 'react-text-mask';
import cx from "classnames";
import Alert from "react-bootstrap-sweetalert";

import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import AvatarUpload from "components/CustomUpload/AvatarUpload.js";
import Loader from "components/Loader/Loader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import styles from './styles.module.scss';

import avatar from "assets/img/image_placeholder.jpg";

import Section from "components/Section/Section.js";

// MOMENT JS
import * as moment from 'moment';

function MobilePhoneMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

function PhoneMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

class Profile extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            classes,
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleCancel,
            handleEdit,
            handleBack,
            businessUnitList,
            handleStateChange,
            disabledForm,
            editMode,
            setFieldTouched,
            setFieldValue
        } = this.props;

        const change = (name, e) => {
            e.persist();
            handleChange(e);
            setFieldTouched(name, true, false);
        };

        return (
            <Fragment>
                <form>
                    <GridContainer>
                        {/* FORM CARD */}
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{ padding: '28px' }}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={8} md={7}>
                                            <Section title="Meu Perfil" />
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                            {editMode &&
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={handleSubmit}
                                                >Salvar
                                                </Button>
                                            }
                                            {editMode &&
                                                <Button
                                                    color="info"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={handleCancel}
                                                >Cancelar</Button>}
                                            {disabledForm &&
                                                <Fragment>
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className={classes.marginRight}
                                                        onClick={handleEdit}
                                                    >Editar
                                                    </Button>
                                                    {/* <Button
                                                        color="info"
                                                        size="sm"
                                                        className={classes.marginRight}
                                                        onClick={handleBack}
                                                    >Voltar
                                                    </Button> */}
                                                </Fragment>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody style={{ padding: '0 28px' }}>
                                    <GridContainer>
                                        <GridItem xs={6} md={4}>
                                            {disabledForm &&
                                                <div className="picture-container-disabled" style={{ marginBottom: '37px' }}>
                                                    <div className="picture" style={{ margin: '5px 0 0 0' }}>
                                                        <img
                                                            style={{
                                                                objectFit: 'cover',
                                                                height: '100px',
                                                                width: '100px'
                                                            }} className="picture-src" src={values.logo && values.logo !== "" ? values.logo : avatar} alt="..." />
                                                    </div>
                                                </div>
                                            }
                                            {!disabledForm && <AvatarUpload
                                                avatar
                                                addButtonProps={{
                                                    color: "success",
                                                    round: true
                                                }}
                                                changeButtonProps={{
                                                    color: "success",
                                                    round: true
                                                }}
                                                removeButtonProps={{
                                                    color: "danger",
                                                    round: true,
                                                    disabled: disabledForm
                                                }}
                                                value={values.logo}
                                            />}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        {/* NAME */}
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                error={touched.name && Boolean(errors.name)}
                                                labelText="Nome"
                                                id="name"
                                                name="name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: change.bind(null, "name"),
                                                    disabled: disabledForm,
                                                    value: values.name
                                                }}
                                            />
                                        </GridItem>
                                        {/* EMAIL */}
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                error={touched.email && Boolean(errors.email)}
                                                labelText={"Email"}
                                                id="email"
                                                name="email"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: change.bind(null, "email"),
                                                    disabled: !values.newItem,
                                                    value: values.email
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    {/* L2 */}
                                    <GridContainer>
                                        {/* TELEFONE */}
                                        <GridItem xs={12} sm={6} md={6}>
                                            <CustomInput
                                                error={touched.phone && Boolean(errors.phone)}
                                                labelText="Telefone"
                                                id="phone"
                                                name="phone"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: change.bind(null, "phone"),
                                                    disabled: disabledForm,
                                                    value: values.phone,
                                                    inputComponent: PhoneMaskCustom
                                                }}
                                            />
                                        </GridItem>

                                        {/* CELULAR */}
                                        <GridItem xs={12} sm={6} md={6}>
                                            <CustomInput
                                                error={touched.mobilePhone && Boolean(errors.mobilePhone)}
                                                labelText="Celular"
                                                id="mobilePhone"
                                                name="mobilePhone"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: change.bind(null, "mobilePhone"),
                                                    disabled: disabledForm,
                                                    value: values.mobilePhone,
                                                    inputComponent: MobilePhoneMaskCustom
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                error={touched.partners && Boolean(errors.partners)}
                                                labelText="Sócios"
                                                id="partners"
                                                name="partners"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: change.bind(null, "partners"),
                                                    disabled: disabledForm,
                                                    value: values.partners
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} style={{ marginTop: "0px" }}>
                                            {businessUnitList.length > 0 &&
                                                <FormControl
                                                    fullWidth
                                                    className={cx(classes.selectFormControl, {
                                                        [classes.disabled]: disabledForm,
                                                    })}
                                                >
                                                    <InputLabel
                                                        htmlFor="businessUnit"
                                                        className={(touched.businessUnit && Boolean(errors.businessUnit) ? styles.Error : classes.selectLabel)}>Cidade</InputLabel>
                                                    <Select
                                                        disabled={disabledForm}
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select,
                                                        }}
                                                        id="businessUnit"
                                                        name="businessUnit"
                                                        value={values.businessUnit}
                                                        inputProps={{
                                                            onChange: change.bind(null, "businessUnit")
                                                        }}>
                                                        {businessUnitList.map(item => (
                                                            <MenuItem key={item.id} value={item.id} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}>
                                                                {item.title}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                    <hr />
                                    <GridContainer>
                                        {/* DESCRIPTION */}
                                        <GridItem xs={12} sm={12} md={12}>
                                            {/* <InputLabel style={{ color: "#AAAAAA" }}>Descrição</InputLabel> */}
                                            <InputLabel htmlFor="Descrição">Descrição</InputLabel>
                                            <CustomInput
                                                error={touched.description && Boolean(errors.description)}
                                                id="description"
                                                name="description"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    onChange: change.bind(null, "description"),
                                                    disabled: disabledForm,
                                                    value: values.description,
                                                    rows: 5
                                                }}
                                            />
                                        </GridItem>
                                        {/* SPECIALITY */}
                                        {values.chancellors && <GridItem xs={12} sm={12} md={12}>
                                            {/* <InputLabel style={{ color: "#AAAAAA" }}>Descrição</InputLabel> */}
                                            <InputLabel
                                                htmlFor="Especialidade">Especialidade</InputLabel>
                                            <CustomInput
                                                error={touched.chancellorSpecialty && Boolean(errors.chancellorSpecialty)}
                                                id="chancellorSpecialty"
                                                name="chancellorSpecialty"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    onChange: change.bind(null, "chancellorSpecialty"),
                                                    disabled: disabledForm,
                                                    value: values.chancellorSpecialty,
                                                    rows: 5
                                                }}
                                            />
                                        </GridItem>}
                                        <Clearfix />
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </form>
            </Fragment>
        );
    }
}

export default compose(
    withStyles(userProfileStyles),
    withStyles(sweetAlertStyle),
    withStyles(extendedFormsStyle),
)(Profile);