import React, { Fragment } from "react";
import * as _ from "lodash";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from "react-document-title";
import Section from "components/Section/Section.js";
import Skeleton from "react-loading-skeleton";
import compose from "utils/compose";

import { withStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import Eyes from "@material-ui/icons/RemoveRedEye";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";

import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Loader from "components/Loader/Loader.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// REDUX INIT
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	GetInsurers,
	GetInsurerById,
	PutInsurer,
	PostInsurerData,
	DeleteInsurer,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from "redux/actions";

// MOMENT JS
import * as moment from "moment";

class InsurerTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			insurersData: [],
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		this.props.GetInsurers();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lsInsurers !== this.props.lsInsurers) {
			let lsInsurers = _.orderBy(this.props.lsInsurers, ["title", "asc"]);

			_.remove(lsInsurers, function(v) {
				return v.title === "Mitsui Sumitomo Seguros";
			});

			this.setState({
				insurersData: lsInsurers,
			});
			this.props.HideLoader();
		}

		// FAILED
		if (prevProps.insurersFailed !== this.props.insurersFailed) {
			console.log("insurersFailed: ", this.props.insurersFailed);
			this.handleRequestFail("Erro ao consultar as seguradoras");
		}
	}

	handleBack = () => {
		this.props.HideAlert();
		this.props.HideLoader();
		// this.props.history.push(`/manageCommunications`);
	};

	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => {
					this.props.HideAlert();
				}}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		);
	};

	handleView = (id) => {
		this.props.history.push(`/manageInsurer/${id}`);
	};

	handleNew = () => {
		this.props.history.push(`/manageInsurer/new`);
	};
	//
	handleEdit = (id) => {
		this.props.history.push(`/manageInsurer/edit/${id}`);
	};
	//
	askDelete = (id) => {
		this.setState({ deleteItemId: id });
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={""}
				onConfirm={this.handleDelete.bind(this)}
				onCancel={() => this.props.HideAlert()}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				cancelBtnCssClass={
					this.props.classes.button + " " + this.props.classes.info
				}
				confirmBtnText="Sim"
				cancelBtnText="Não"
				showCancel
			>
				Você tem certeza que deseja deletar este item?
			</Alert>
		);
	};

	//
	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		const { t, classes } = this.props;
		const { insurersData, created, open } = this.state;

		return (
			<Fragment>
				<DocumentTitle title="PRx Consultoria - Seguradoras" />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<CardHeader
								style={{
									padding: "28px",
									paddingBottom: "10px",
								}}
							>
								<GridContainer>
									<GridItem xs={12} sm={8} md={7}>
										<Section title="Seguradoras" />
									</GridItem>
									{/* <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            className={classes.marginRight}
                                            onClick={() => this.handleNew()}>Novo</Button>
                                    </GridItem> */}
								</GridContainer>
							</CardHeader>
							<CardBody style={{ padding: "28px" }}>
								{insurersData && insurersData.length > 0 ? (
									<Table
										striped
										tableHead={[
											"Seguradora",
											"Site",
											"Email",
										]}
										tableData={insurersData.map((item) => [
											item.title,
											item.website,
											item.email,
											<Fragment>
												<Button
													justIcon
													size="sm"
													value={item.id}
													color="primary"
													onClick={this.handleView.bind(
														this,
														item.id
													)}
												>
													<Eyes
														className={
															classes.icons
														}
													/>
												</Button>
												{this.props.user.admin && (
													<>
														<Button
															justIcon
															size="sm"
															value={item.id}
															color="primary"
															onClick={this.handleEdit.bind(
																this,
																item.id
															)}
														>
															<Edit
																className={
																	classes.icons
																}
															/>
														</Button>
													</>
												)}
											</Fragment>,
										])}
										customCellClasses={[
											classes.center,
											classes.right,
											classes.right,
										]}
										customClassesForCells={[0, 4, 5]}
										customHeadCellClasses={[
											classes.center,
											classes.right,
											classes.right,
										]}
										customHeadClassesForCells={[0, 4, 5]}
									/>
								) : (
									<h5>Nenhum registro encontrado</h5>
								)}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,

		lsInsurers: state.insurer.lsInsurers,
		insurersFailed: state.insurer.insurersFailed,
		insurerItem: state.insurer.insurerItem,
		insurerItemFailed: state.insurer.insurerItemFailed,
		insurerUpdate: state.insurer.insurerUpdate,

		insurerUpdateFailed: state.insurer.insurerUpdateFailed,
		insertInsurer: state.insurer.insertInsurer,
		insertInsurerFailed: state.insurer.insertInsurerFailed,
		insurerDelete: state.insurer.insurerDelete,
		insurerDeleteFailed: state.insurer.insurerDeleteFailed,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetInsurers,
			GetInsurerById,
			PutInsurer,
			PostInsurerData,
			DeleteInsurer,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(extendedTablesStyle),
	withStyles(sweetAlertStyle),
	withStyles(modalStyle)
)(InsurerTable);
