import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import Alert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import compose from "utils/compose";
import DocumentTitle from "react-document-title";
import Section from "components/Section/Section.js";

// ReactQuill
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import { withStyles } from "@material-ui/core/styles";

import Eyes from "@material-ui/icons/RemoveRedEye";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
// icons
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import Loader from "components/Loader/Loader.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import { numberFormatText } from "utils/utils.js";

import styles from "./styles.module.scss";

// REDUX INIT
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    GetDocuments, GetDocumentById, PutDocument, PostDocumentData, DeleteDocument, SweetAlert, HideAlert, ShowLoader, HideLoader
} from "redux/actions";

class Document extends React.Component {
    constructor(props) {
        super(props);
        this.modal = React.createRef();
        this.state = {
            open: false,
            pageTitle: "PrxDocuments",
            deleteItemId: "",
            lsDocuments: false,
        }
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);
        this.props.GetDocuments();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lsDocuments !== this.props.lsDocuments) {
            // console.log("lsDocuments: ", this.props.lsDocuments);
            this.setState({ lsDocuments: this.props.lsDocuments });
            this.props.HideLoader();
        }

        // if (prevProps.documentItem !== this.props.documentItem) {
        //     console.log("documentItem: ", this.props.documentItem);
        // }

        // if (prevProps.documentUpdate !== this.props.documentUpdate) {
        //     console.log("documentUpdate: ", this.props.documentUpdate);
        // }

        // if (prevProps.insertDocument !== this.props.insertDocument) {
        //     console.log("insertDocument: ", this.props.insertDocument);
        // }

        if (prevProps.documentDelete !== this.props.documentDelete) {
            console.log("documentDelete: ", this.props.documentDelete);
            this.props.GetDocuments();
            this.props.SweetAlert(
                <Alert
                    success
                    style={{ display: "block" }}
                    title={""}
                    onConfirm={() => { this.handleBack(); }}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.primary
                    }
                    confirmBtnText={"OK"}
                >
                    Registro deletado com sucesso!
                </Alert>
            )
            setTimeout(() => this.handleBack(), 3000);
        }
        // 
        //
        // FAILED
        if (prevProps.documentsFailed !== this.props.documentsFailed) {
            this.handleRequestFail("Erro ao consultar documentos");
            console.log("documentsFailed: ", this.props.documentsFailed);
        }
        // if (prevProps.documentItemFailed !== this.props.documentItemFailed) {
        //     this.handleRequestFail("Erro ao consultar documento");
        //     console.log("documentItemFailed: ", this.props.documentItemFailed);
        // }
        // if (prevProps.documentUpdateFailed !== this.props.documentUpdateFailed) {
        //     this.handleRequestFail("Erro ao atualizar documento");
        //     console.log("documentUpdateFailed: ", this.props.documentUpdateFailed);
        // }
        // if (prevProps.insertDocumentFailed !== this.props.insertDocumentFailed) {
        //     this.handleRequestFail("Erro ao inserir documento");
        //     console.log("insertDocumentFailed: ", this.props.insertDocumentFailed);
        // }
        if (prevProps.documentDeleteFailed !== this.props.documentDeleteFailed) {
            this.handleRequestFail("Erro ao deletar documento");
            console.log("documentDeleteFailed: ", this.props.documentDeleteFailed);
        }
    }

    handleRequestFail = (msg) => {
        this.props.HideLoader();
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={"Atenção!"}
                onConfirm={() => { this.props.HideAlert(); }}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                confirmBtnText={"OK"}
            >
                {msg}
            </Alert>
        )
    }

    handleView = (id) => {
        this.props.history.push(`/document/${id}`);
    }

    handleNew = () => {
        this.props.history.push(`/document/new`);
    }

    handleEdit = (id) => {
        this.props.history.push(`/document/edit/${id}`);
    }
    //
    askDelete = id => {
        this.setState({ deleteItemId: id });
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={""}
                onConfirm={this.handleDelete.bind(this)}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.info
                }
                confirmBtnText="Sim"
                cancelBtnText="Não"
                showCancel
            >
                Você tem certeza que deseja deletar este item?
            </Alert>
        );
    }
    handleDelete = () => {
        this.props.HideAlert();
        this.props.DeleteDocument(this.state.deleteItemId);
    }
    handleBack = () => {
        this.props.HideAlert();
        this.props.HideLoader();
        // this.props.history.push(`/manageCommunications`);
    }
    //
    toggleShownRowIndex = (index) => {
        this.setState({
            showRowIndex: index
        })
    }

    render() {
        const { classes } = this.props;
        const { lsDocuments } = this.state;

        return (
            <Fragment>
                <DocumentTitle title="PRx Consultoria - Documentos" />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader style={{ padding: "28px", paddingBottom: "10px" }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={7}>
                                        <Section title="Documentos" />
                                    </GridItem>
                                    {this.props.user.admin &&
                                        <GridItem xs={12} sm={3} md={5} style={{ textAlign: "right" }}>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className={classes.marginRight}
                                                onClick={() => this.handleNew()}>Novo</Button>
                                        </GridItem>}
                                </GridContainer>
                            </CardHeader>
                            <CardBody style={{ padding: "28px" }}>
                                <GridContainer justify="center" style={{ paddingTop: "30px" }}>
                                    <GridItem xs={12}>

                                        {lsDocuments && lsDocuments.length > 0 ?
                                            <Table className={styles.statementTable}>
                                                {lsDocuments.length > 0 ?
                                                    <Fragment>
                                                        {lsDocuments.map((item, index) => (
                                                            [
                                                                <Fragment key={index}>
                                                                    <TableBody className={index % 2 === 0 ? styles.bodyDark : styles.bodyLight} >
                                                                        <TableRow className={styles.rowItems}>
                                                                            <TableCell className={styles.Left} colSpan={1} >
                                                                                <div className={styles.title}>Titulo</div>
                                                                                <div className={styles.detailsContent}>
                                                                                    {item.title}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell className={styles.Left} colSpan={2}>
                                                                                <div className={styles.title}>Descrição</div>
                                                                                <div className={styles.detailsContent}>
                                                                                    {
                                                                                        _.replace(
                                                                                            _.truncate(item.description, {
                                                                                                'length': 200,
                                                                                                'separator': /,? +/
                                                                                            }),
                                                                                            /<[^>]*>/g, ' ')
                                                                                    }
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell rowSpan={2}>
                                                                                <div style={{ display: 'inline-flex' }}>
                                                                                    <Button
                                                                                        justIcon
                                                                                        size="sm"
                                                                                        color="primary"
                                                                                        onClick={this.handleView.bind(this, item.id)}>
                                                                                        <Eyes />
                                                                                    </Button>
                                                                                    {this.props.user.admin &&
                                                                                        <Fragment>
                                                                                            <Button
                                                                                                justIcon
                                                                                                size="sm"
                                                                                                color="primary"
                                                                                                onClick={this.handleEdit.bind(this, item.id)}>
                                                                                                <Edit />
                                                                                            </Button>
                                                                                            <Button
                                                                                                justIcon
                                                                                                size="sm"
                                                                                                color="danger"
                                                                                                onClick={this.askDelete.bind(this, item.id)}>
                                                                                                <Delete />
                                                                                            </Button>
                                                                                        </Fragment>
                                                                                    }
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>

                                                                </Fragment>
                                                            ]))}
                                                    </Fragment>
                                                    :
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan={10} align={"center"}>
                                                                Resultado não encontrado.
                                                        </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                }
                                            </Table>
                                            : <h5>Nenhum registro encontrado</h5>}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer >
            </Fragment >
        )
    }
}

Document.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        lsDocuments: state.document.lsDocuments,
        documentsFailed: state.document.documentsFailed,

        // documentItem: state.document.documentItem,
        // documentItemFailed: state.document.documentItemFailed,

        // documentUpdate: state.document.documentUpdate,
        // documentUpdateFailed: state.document.documentUpdateFailed,

        // insertDocument: state.document.insertDocument,
        // insertDocumentFailed: state.document.insertDocumentFailed,

        documentDelete: state.document.documentDelete,
        documentDeleteFailed: state.document.documentDeleteFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetDocuments, GetDocumentById, PutDocument, PostDocumentData, DeleteDocument, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedTablesStyle),
    withStyles(extendedFormsStyle),
    withStyles(sweetAlertStyle),
    withTranslation(),
)(Document); 