import React, { Fragment } from "react";
import * as _ from "lodash";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from "react-document-title";
import PropTypes from "prop-types";
import compose from "utils/compose";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";

import Eyes from "@material-ui/icons/RemoveRedEye";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loader from "components/Loader/Loader.js";
import Section from "components/Section/Section.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { numberFormatText } from "utils/utils.js";

import styles from "./styles.module.scss";

// REDUX INIT
import {
    DeleteProduction,
    GetBrokers,
    GetBusinessUnit,
    GetProductionById,
    GetProductions,
    HideAlert,
    HideLoader,
    PostProductionData,
    PutProduction,
    ShowLoader,
    SweetAlert,
} from "redux/actions";

class Productions extends React.Component {
    constructor(props) {
        super(props);
        this.modal = React.createRef();
        this.state = {
            open: false,
            productionsData: [],
            businessUnitList: [],
            userBusinessUnit: "",
            // lsInsurersData: null,
            searchBusinessUnit: "",
            searchInsurer: "",
            searchMonth: "",
            searchYear: "",
            deleteItemId: ""
        }
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);
        // this.props.GetBusinessUnit();
        //
        if (_.isEmpty(this.props.lsBrokers)) {
            this.props.GetBrokers();
        } else {
            if (_.isEmpty(this.props.lsBusinessUnit)) {
                this.props.GetBusinessUnit().then(() => {
                    this.props.GetProductions();
                });
            } else {
                this.setState({ businessUnitList: this.props.lsBusinessUnit })
                this.props.GetProductions();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lsProductions !== this.props.lsProductions) {
            let bu = _.filter(this.props.lsBrokers, ["id", this.props.user.id])[0].businessUnit;
            this.setState({ userBusinessUnit: bu });
            //
            let items = [];
            if (!this.props.user.admin) {
                items = _.filter(this.props.lsProductions, ['businessUnit', bu]);
                if (items.length > 0) {
                    this.setState({ productionsData: _.orderBy(items, ['year', 'month'], ["asc", "asc"]) });
                }
            } else {
                this.setState({ productionsData: _.orderBy(this.props.lsProductions, ['year', 'month'], ["asc", "asc"]) });
            }
            this.props.HideLoader();
        }

        if (prevProps.lsBrokers !== this.props.lsBrokers) {
            if (_.isEmpty(this.props.lsBusinessUnit)) {
                this.props.GetBusinessUnit().then(() => {
                    this.props.GetProductions();
                });
            } else {
                this.setState({ businessUnitList: this.props.lsBusinessUnit })
                this.props.GetProductions();
            }
        }

        if (prevState.productionsData !== this.state.productionsData) {
            // if (!this.props.user.admin) {
            //     let items = [];
            //     items = _.filter(this.state.productionsData, ['businessUnit', this.state.userBusinessUnit]);
            //     console.log("items: ", items);
            //     if (items.length > 0) {
            //         this.setState({ productionsData: items });
            //     }
            // }
        }

        if (prevProps.lsBusinessUnit !== this.props.lsBusinessUnit) {
            this.setState({ businessUnitList: this.props.lsBusinessUnit });
        }

        if (prevProps.productionItem !== this.props.productionItem) {
            // console.log("productionItem: ", this.props.productionItem);
            this.props.GetProductions();
            this.props.SweetAlert(
                <Alert
                    success
                    style={{ display: "block" }}
                    title={""}
                    onConfirm={() => { this.handleBack(); }}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.primary
                    }
                    confirmBtnText={"OK"}
                >
                    Produção deletada com sucesso!
                </Alert>
            )
            setTimeout(() => this.handleBack(), 3000);
        }

        if (prevProps.productionDelete !== this.props.productionDelete) {
            console.log("productionDelete: ", this.props.productionDelete);
            this.props.GetProductions();
            this.props.SweetAlert(
                <Alert
                    success
                    style={{ display: "block" }}
                    title={""}
                    onConfirm={() => { this.handleBack(); }}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.primary
                    }
                    confirmBtnText={"OK"}
                >
                    Comunicado deletado com sucesso!
                </Alert>
            )
            setTimeout(() => this.handleBack(), 3000);
        }
        // 
        //
        // FAILED
        // if (prevProps.insurersFailed !== this.props.insurersFailed) {
        //     console.log("Insurers Failed: ", this.props.insurersFailed);
        //     this.handleRequestFail("Erro ao consultar seguradoras");
        // }
        if (prevProps.businessUnitsFailed !== this.props.businessUnitsFailed) {
            console.log("Business Units Failed: ", this.props.businessUnitsFailed);
            this.handleRequestFail("Erro ao consultar unidades de negócios");
        }

        // if (prevProps.productionsFailed !== this.props.productionsFailed) {
        //     console.log("Productions Failed: ", this.props.productionsFailed);
        //     this.handleRequestFail("Erro ao consultar dados");
        // }
        // if (prevProps.productionItemFailed !== this.props.productionItemFailed) {
        //     console.log("Production Item Failed: ", this.props.productionItemFailed);
        //     this.handleRequestFail("Erro ao consultar a informação");
        // }
        // if (prevProps.productionUpdateFailed !== this.props.productionUpdateFailed) {
        //     console.log("Production Update Failed: ", this.props.productionUpdateFailed);
        //     this.handleRequestFail("Erro ao atualizar informação");
        // }
        // if (prevProps.insertProductionFailed !== this.props.insertProductionFailed) {
        //     console.log("Insert Production Failed: ", this.props.insertProductionFailed);
        //     this.handleRequestFail("Erro ao inserir informação");
        // }
        if (prevProps.productionDeleteFailed !== this.props.productionDeleteFailed) {
            console.log("Production Delete Failed: ", this.props.productionDeleteFailed);
            this.handleRequestFail("Erro ao deletar produção");
        }

    }

    handleView = (id) => {
        this.props.history.push(`/production/${id}`);
    }

    handleNew = () => {
        this.props.history.push(`/production/new`);
    }

    handleEdit = (id) => {
        this.props.history.push(`/production/edit/${id}`);
    }
    //
    askDelete = id => {
        this.setState({ deleteItemId: id });
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={""}
                onConfirm={this.handleDelete.bind(this)}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.info
                }
                confirmBtnText="Sim"
                cancelBtnText="Não"
                showCancel
            >
                Você tem certeza que deseja deletar este item?
            </Alert>
        );
    }
    handleDelete = () => {
        this.props.HideAlert();
        this.props.DeleteProduction(this.state.deleteItemId);
    }
    handleBack = () => {
        this.props.HideAlert();
        this.props.HideLoader();
        // this.props.history.push(`/manageCommunications`);
    }
    //
    handleClose = () => {
        this.setState({ open: false });
    }

    handleFilter = () => {
        console.log("FILTER");
        console.log("productionsData: ", this.state.productionsData);
        console.log("searchBusinessUnit: ", this.state.searchBusinessUnit);
        console.log("searchInsurer: ", this.state.searchInsurer);
        console.log("searchMonth: ", this.state.searchMonth);
        console.log("searchYear: ", this.state.searchYear);

        let items = _.filter(this.state.productionsData, { "businessUnit": this.state.searchBusinessUnit });
        //, "insurer": this.state.searchInsurer, "month": this.state.searchMonth, "year": this.state.searchYear 
        console.log(items);
    }

    handleClearFilter = () => {
        this.setState({
            searchBusinessUnit: "",
            searchInsurer: "",
            searchMonth: "",
            searchYear: "",
            productionsData: this.props.lsProductions
        });
    }

    handleChange = (event) => {
        const id = event.target.id || event.target.name;
        const value = event.target.value;
        this.setState({ [id]: value });
    }

    toggleShownRowIndex = (index) => {
        this.setState({
            showRowIndex: index
        })
    }

    handleRequestFail = (msg) => {
        this.props.HideLoader();
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={"Atenção!"}
                onConfirm={() => { this.props.HideAlert(); }}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                confirmBtnText={"OK"}
            >
                {msg}
            </Alert>
        )
    }

    render() {
        const { classes } = this.props;
        const { productionsData, searchBusinessUnit, searchInsurer, searchMonth, searchYear, businessUnitList } = this.state;

        return (
            <Fragment>
                <DocumentTitle title="PRx Consultoria - Produção" />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader style={{ padding: "28px", paddingBottom: "10px" }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={7}>
                                        <Section title="Produção" />
                                    </GridItem>
                                    {this.props.user.admin &&
                                        <GridItem xs={12} sm={3} md={5} style={{ textAlign: "right" }}>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className={classes.marginRight}
                                                onClick={() => this.handleNew()}>Novo</Button>
                                        </GridItem>}
                                </GridContainer>
                            </CardHeader>
                            <CardBody style={{ padding: "28px" }}>
                                <GridContainer justify="center" style={{ paddingTop: "30px" }}>
                                    <GridItem xs={12}>

                                        {productionsData && productionsData.length > 0 && businessUnitList && businessUnitList.length > 0 ?
                                            <Table className={styles.statementTable}>
                                                {productionsData.length > 0 ?
                                                    <Fragment>
                                                        {productionsData.map((item, index) => (
                                                            [
                                                                <Fragment key={index}>
                                                                    <TableBody className={index % 2 === 0 ? styles.bodyDark : styles.bodyLight} >
                                                                        <TableRow className={styles.rowItems}>
                                                                            <TableCell colSpan={2}>
                                                                                <div className={styles.title}>Unidade de Negócio</div>
                                                                                <div className={styles.detailsContent}>
                                                                                    {_.find(businessUnitList, ["id", item.businessUnit]).title}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell className={styles.Left} colSpan={2} >
                                                                                <div className={styles.title}>Mês</div>
                                                                                <div className={styles.detailsContent}>
                                                                                    {item.month}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell className={styles.Left} colSpan={2}>
                                                                                <div className={styles.title}>Ano</div>
                                                                                <div className={styles.detailsContent}>
                                                                                    {item.year}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell rowSpan={2} width="20%">
                                                                                <div style={{ display: 'inline-flex' }}>
                                                                                    {this.props.user.admin &&
                                                                                        <Fragment>
                                                                                            <Button
                                                                                                justIcon
                                                                                                size="sm"
                                                                                                color="primary"
                                                                                                onClick={this.handleView.bind(this, item.id)}>
                                                                                                <Eyes />
                                                                                            </Button>
                                                                                            <Button
                                                                                                justIcon
                                                                                                size="sm"
                                                                                                color="primary"
                                                                                                onClick={this.handleEdit.bind(this, item.id)}>
                                                                                                <Edit />
                                                                                            </Button>
                                                                                            <Button
                                                                                                justIcon
                                                                                                size="sm"
                                                                                                color="danger"
                                                                                                onClick={this.askDelete.bind(this, item.id)}>
                                                                                                <Delete />
                                                                                            </Button>
                                                                                        </Fragment>
                                                                                    }
                                                                                    {!(this.state.showRowIndex === index) ?
                                                                                        <Button
                                                                                            justIcon
                                                                                            size="sm"
                                                                                            color="info"
                                                                                            onClick={() => this.toggleShownRowIndex(index)}>
                                                                                            <ExpandMore />
                                                                                        </Button>
                                                                                        :
                                                                                        <Button
                                                                                            justIcon
                                                                                            size="sm"
                                                                                            color="danger"
                                                                                            onClick={() => this.toggleShownRowIndex(null)}>
                                                                                            <ExpandLess />
                                                                                        </Button>
                                                                                    }
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>

                                                                    <Fragment>
                                                                        <TableBody className={index % 2 === 0 ? styles.subItemsDark : styles.subItemsLight} style={{ display: this.state.showRowIndex === index ? '' : 'none' }}>
                                                                            <TableRow className={styles.rowSubItemsTitle}>
                                                                                <TableCell colSpan={2}>
                                                                                    <b>Seguradora</b>
                                                                                </TableCell>
                                                                                <TableCell className={styles.Left} colSpan={2}>
                                                                                    <b>Realizado</b>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <b>Meta</b>
                                                                                </TableCell>
                                                                            </TableRow>

                                                                            {/* #5 */}
                                                                            <TableRow key={index} className={styles.rowSubItems}>
                                                                                <TableCell colSpan={2}>
                                                                                    {item.insurer5}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2}>
                                                                                    {numberFormatText(item.current5, "R$ ", 2)}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2} className={styles.Left}>
                                                                                    {numberFormatText(item.goal5, "R$ ", 2)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            
                                                                            {/* #6 */}
                                                                            <TableRow key={index} className={styles.rowSubItems}>
                                                                                <TableCell colSpan={2}>
                                                                                    {item.insurer6}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2}>
                                                                                    {numberFormatText(item.current6, "R$ ", 2)}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2} className={styles.Left}>
                                                                                    {numberFormatText(item.goal6, "R$ ", 2)}
                                                                                </TableCell>
                                                                            </TableRow>

                                                                            {/* #1 */}
                                                                            <TableRow key={index} className={styles.rowSubItems}>
                                                                                <TableCell colSpan={2}>
                                                                                    {item.insurer1}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2}>
                                                                                    {numberFormatText(item.current1, "R$ ", 2)}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2} className={styles.Left}>
                                                                                    {numberFormatText(item.goal1, "R$ ", 2)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {/* #2 */}
                                                                            <TableRow key={index} className={styles.rowSubItems}>
                                                                                <TableCell colSpan={2}>
                                                                                    {item.insurer2}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2}>
                                                                                    {numberFormatText(item.current2, "R$ ", 2)}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2} className={styles.Left}>
                                                                                    {numberFormatText(item.goal2, "R$ ", 2)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {/* #3 */}
                                                                            <TableRow key={index} className={styles.rowSubItems}>
                                                                                <TableCell colSpan={2}>
                                                                                    {item.insurer3}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2}>
                                                                                    {numberFormatText(item.current3, "R$ ", 2)}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2} className={styles.Left}>
                                                                                    {numberFormatText(item.goal3, "R$ ", 2)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {/* #4 */}
                                                                            {/* <TableRow key={index} className={styles.rowSubItems}>
                                                                                <TableCell colSpan={2}>
                                                                                    {item.insurer4}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2}>
                                                                                    {numberFormatText(item.current4, "R$ ", 2)}
                                                                                </TableCell>
                                                                                <TableCell colSpan={2} className={styles.Left}>
                                                                                    {numberFormatText(item.goal4, "R$ ", 2)}
                                                                                </TableCell>
                                                                            </TableRow> */}
                                                                            {/* ))
                                                                            } */}
                                                                        </TableBody>
                                                                    </Fragment>

                                                                </Fragment>
                                                            ]))}
                                                    </Fragment>
                                                    :
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan={10} align={"center"}>
                                                                Resultado não encontrado.
                                                        </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                }
                                            </Table>
                                            : <h5>Nenhum registro encontrado</h5>}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer >
            </Fragment >
        )
    }
}

Productions.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        lsBrokers: state.brokers.lsBrokers,
        brokersFailed: state.brokers.brokersFailed,

        lsProductions: state.productions.lsProductions,
        productionsFailed: state.productions.productionsFailed,

        lsBusinessUnit: state.businessUnit.lsBusinessUnit,
        businessUnitsFailed: state.businessUnit.businessUnitsFailed,

        // productionItem: state.productions.productionItem,
        // productionUpdate: state.productions.productionUpdate,
        // insertProduction: state.productions.insertProduction,
        productionDelete: state.productions.productionDelete,

        // productionItemFailed: state.productions.productionItemFailed,
        // productionUpdateFailed: state.productions.productionUpdateFailed,
        // insertProductionFailed: state.productions.insertProductionFailed,
        productionDeleteFailed: state.productions.productionDeleteFailed,

        // lsInsurers: state.insurer.lsInsurers,
        // insurersFailed: state.insurer.insurersFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetBusinessUnit, GetBrokers, GetProductions, GetProductionById, PutProduction, PostProductionData, DeleteProduction, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedTablesStyle),
    withStyles(extendedFormsStyle),
    withStyles(sweetAlertStyle),
    withTranslation(),
)(Productions); 