import axios from 'axios';

// @Get()
export const GetBrokers = () => {
    return axios.get(`/broker`)
        .then(response => response.data);
}

// @Get(':id') 
export const GetBrokerById = (id) => {
    return axios.get(`/broker/${id}`)
        .then(response => response.data);
}

// @Put(":id")
export const PutBroker = (id, obj) => {
    return axios.put(`/broker/${id}`, obj)
        .then(response => response.data);
}

// @Post()
export const PostBroker = (obj) => {
    return axios.post(`/register/`, obj)
        .then(response => response.data);
}

// @Delete(":id")
export const DeleteBroker = (id) => {
    return axios.delete(`/broker/`, id)
        .then(response => response.data);
}

export const ChangePassword = (id, obj) => {
    return axios.put(`/changePassword/${id}`, obj)
        .then(response => response.data);
}

export const ResetPassword = (id, obj) => {
    return axios.put(`/resetPassword/${id}`, obj)
        .then(response => response.data);
}