// LOGIN USER
export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const LOGOUT = "USERS_LOGOUT";
export const GETALL_REQUEST = "USERS_GETALL_REQUEST";
export const GET_CLAIN_USERS = "GET_CLAIN_USERS";
export const GET_CLAIN_USERS_SUCCESS = "GET_CLAIN_USERS_SUCCESS";
export const GET_CLAIN_USERS_FAILURE = "GET_CLAIN_USERS_FAILURE";
export const GETALL_SUCCESS = "USERS_GETALL_SUCCESS";
export const GETALL_FAILURE = "USERS_GETALL_FAILURE";
export const CHANGE_PASSWORD = "USERS_CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAILURE = "USERS_CHANGE_PASSWORD_FAILURE";
export const GET_SUCCESS = "USERS_GET_SUCCESS";
export const GET_FAILURE = "USERS_GET_FAILURE";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const FETCH_UNAUTH_USER = "FETCH_UNAUTH_USER";

// IMAGES
export const SET_IMAGE_DATA = "SET_IMAGE_DATA";
export const POST_IMAGE_DATA = "POST_IMAGE_DATA";
export const POST_IMAGE_FAILED = "POST_IMAGE_FAILED";
export const GET_IMAGE_DATA = "GET_IMAGE_DATA";
export const GET_IMAGE_FAILED = "GET_IMAGE_FAILED";

// FILES
export const POST_FILES_DATA = "POST_FILES_DATA";
export const POST_FILES_FAILED = "POST_FILES_FAILED";
export const SET_FILES_DATA = "SET_FILES_DATA";
export const GET_FILES_DATA = "GET_FILES_DATA";
export const GET_FILES_FAILED = "GET_FILES_FAILED";
export const DELETE_FILE_DATA  = "DELETE_FILE_DATA";
export const DELETE_FILE_DATA_FAILED = "DELETE_FILE_DATA_FAILED";

// SWEET-ALERT
export const SWEET_ALERT = "SWEET_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

// LOADER
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// LOCATION
export const FETCH_CITY_DATA = "FETCH_CITY_DATA";
export const FETCH_STATE_DATA = "FETCH_STATE_DATA";
export const FETCH_COUNTRIES_DATA = "FETCH_COUNTRIES_DATA";
export const FETCH_CITY_FAILED = "FETCH_CITY_DATA_FAILED";
export const FETCH_STATE_FAILED = "FETCH_STATE_FAILED";
export const FETCH_COUNTRIES_FAILED = "FETCH_COUNTRIES_FAILED";

// USER - BY PARTNER ID
export const FETCH_PARTNER_USER_DATA = "FETCH_PARTNER_USER_DATA";
export const POST_PARTNER_USER_DATA = "POST_PARTNER_USER_DATA";
export const POST_PARTNER_USER_FAILED = "POST_PARTNER_USER_FAILED";
export const PUT_PARTNER_USER_DATA = "PUT_PARTNER_USER_DATA";
export const PUT_PARTNER_USER_DATA_FAILED = "PUT_PARTNER_USER_DATA_FAILED";
export const FETCH_PARTNER_USER_FAILED = "FETCH_PARTNER_USER_FAILED";
export const POST_CONSUMER_REGULATION_DATA = "POST_CONSUMER_REGULATION_DATA";
export const POST_PARTNER_NEW_USER_DATA = "POST_PARTNER_NEW_USER_DATA";
export const VERIFY_CPF = "CONSUMER_VERIFY_CPF";

// COMMUNICATIONS
export const GET_COMMUNICATIONS_DATA = "GET_COMMUNICATIONS_DATA";
export const GET_COMMUNICATIONS_DATA_FAILED = "GET_COMMUNICATIONS_DATA_FAILED";

export const GET_COMMUNICATION_BY_ID = "GET_COMMUNICATION_BY_ID";
export const GET_COMMUNICATION_BY_ID_FAILED = "GET_COMMUNICATION_BY_ID_FAILED";

export const POST_COMMUNICATION_DATA = "POST_COMMUNICATION_DATA";
export const POST_COMMUNICATION_DATA_FAILED = "POST_COMMUNICATION_DATA_FAILED";

export const PUT_COMMUNICATION_DATA = "PUT_COMMUNICATION_DATA";
export const PUT_COMMUNICATION_FAILED = "PUT_COMMUNICATION_FAILED";

export const DELETE_COMMUNICATION_DATA = "DELETE_COMMUNICATION_DATA";
export const DELETE_COMMUNICATION_FAILED = "DELETE_COMMUNICATION_FAILED";

// BROKERS
export const GET_BROKERS_DATA = "GET_BROKERS_DATA";
export const GET_BROKERS_DATA_FAILED = "GET_BROKERS_DATA_FAILED";

export const GET_BROKER_BY_ID = "GET_BROKER_BY_ID";
export const GET_BROKER_BY_ID_FAILED = "GET_BROKER_BY_ID_FAILED";

export const POST_BROKER_DATA = "POST_BROKER_DATA";
export const POST_BROKER_DATA_FAILED = "POST_BROKER_DATA_FAILED";

export const PUT_BROKER_DATA = "PUT_BROKER_DATA";
export const PUT_BROKER_FAILED = "PUT_BROKER_FAILED";

export const DELETE_BROKER_DATA = "DELETE_BROKER_DATA";
export const DELETE_BROKER_FAILED = "DELETE_BROKER_FAILED";

// BUSINESS UNIT
export const GET_BUSINESS_UNIT_DATA = "GET_BUSINESS_UNIT_DATA";
export const GET_BUSINESS_UNIT_DATA_FAILED = "GET_BUSINESS_UNIT_DATA_FAILED";

export const GET_BUSINESS_UNIT_BY_ID = "GET_BUSINESS_UNIT_BY_ID";
export const GET_BUSINESS_UNIT_BY_ID_FAILED = "GET_BUSINESS_UNIT_BY_ID_FAILED";

export const PUT_BUSINESS_UNIT_DATA = "PUT_BUSINESS_UNIT_DATA";
export const PUT_BUSINESS_UNIT_FAILED = "PUT_BUSINESS_UNIT_FAILED";

export const POST_BUSINESS_UNIT_DATA = "POST_BUSINESS_UNIT_DATA";
export const POST_BUSINESS_UNIT_DATA_FAILED = "POST_BUSINESS_UNIT_DATA_FAILED";

// // export const DELETE_BUSINESS_UNIT_DATA = "DELETE_BUSINESS_UNIT_DATA";
// // export const DELETE_BUSINESS_UNIT_DATA_FAILED = "DELETE_BUSINESS_UNIT_DATA_FAILED";

// PARTNERS
export const GET_PARTNERS_DATA = "GET_PARTNERS_DATA";
export const GET_PARTNERS_DATA_FAILED = "GET_PARTNERS_DATA_FAILED";

export const GET_PARTNER_BY_ID = "GET_PARTNER_BY_ID";
export const GET_PARTNER_BY_ID_FAILED = "GET_PARTNER_BY_ID_FAILED";

export const POST_PARTNER_DATA = "POST_PARTNER_DATA";
export const POST_PARTNER_DATA_FAILED = "POST_PARTNER_DATA_FAILED";

export const PUT_PARTNER_DATA = "PUT_PARTNER_DATA";
export const PUT_PARTNER_FAILED = "PUT_PARTNER_FAILED";

export const DELETE_PARTNER_DATA = "DELETE_PARTNER_DATA";
export const DELETE_PARTNER_FAILED = "DELETE_PARTNER_FAILED";

// PRODUCTIONS
export const GET_PRODUCTIONS_DATA = "GET_PRODUCTIONS_DATA";
export const GET_PRODUCTIONS_DATA_FAILED = "GET_PRODUCTIONS_DATA_FAILED";

export const GET_PRODUCTION_BY_ID = "GET_PRODUCTION_BY_ID";
export const GET_PRODUCTION_BY_ID_FAILED = "GET_PRODUCTION_BY_ID_FAILED";

export const POST_PRODUCTION_DATA = "POST_PRODUCTION_DATA";
export const POST_PRODUCTION_DATA_FAILED = "POST_PRODUCTION_DATA_FAILED";

export const PUT_PRODUCTION_DATA = "PUT_PRODUCTION_DATA";
export const PUT_PRODUCTION_FAILED = "PUT_PRODUCTION_FAILED";

export const DELETE_PRODUCTION_DATA = "DELETE_PRODUCTION_DATA";
export const DELETE_PRODUCTION_FAILED = "DELETE_PRODUCTION_FAILED";

// INSURERS
export const GET_INSURERS_DATA = "GET_INSURERS_DATA";
export const GET_INSURERS_DATA_FAILED = "GET_INSURERS_DATA_FAILED";

export const GET_INSURER_BY_ID = "GET_INSURER_BY_ID";
export const GET_INSURER_BY_ID_FAILED = "GET_INSURER_BY_ID_FAILED";

export const POST_INSURER_DATA = "POST_INSURER_DATA";
export const POST_INSURER_DATA_FAILED = "POST_INSURER_DATA_FAILED";

export const PUT_INSURER_DATA = "PUT_INSURER_DATA";
export const PUT_INSURER_FAILED = "PUT_INSURER_FAILED";

export const DELETE_INSURER_DATA = "DELETE_INSURER_DATA";
export const DELETE_INSURER_FAILED = "DELETE_INSURER_FAILED";

// ATTACHMENTS
export const GET_ATTACHMENTS_DATA = "GET_ATTACHMENTS_DATA";
export const GET_ATTACHMENTS_DATA_FAILED = "GET_ATTACHMENTS_DATA_FAILED";

export const GET_ATTACHMENT_BY_ID = "GET_ATTACHMENT_BY_ID";
export const GET_ATTACHMENT_BY_ID_FAILED = "GET_ATTACHMENT_BY_ID_FAILED";

export const GET_ATTACHMENT_BY_PAGE = "GET_ATTACHMENT_BY_PAGE";
export const GET_ATTACHMENT_BY_PAGE_FAILED = "GET_ATTACHMENT_BY_PAGE_FAILED";

export const POST_ATTACHMENT_DATA = "POST_ATTACHMENT_DATA";
export const POST_ATTACHMENT_DATA_FAILED = "POST_ATTACHMENT_DATA_FAILED";

export const PUT_ATTACHMENT_DATA = "PUT_ATTACHMENT_DATA";
export const PUT_ATTACHMENT_FAILED = "PUT_ATTACHMENT_FAILED";

export const DELETE_ATTACHMENT_DATA = "DELETE_ATTACHMENT_DATA";
export const DELETE_ATTACHMENT_DATA_FAILED = "DELETE_ATTACHMENT_DATA_FAILED";

// DOCUMENTS
export const GET_DOCUMENTS_DATA = "GET_DOCUMENTS_DATA";
export const GET_DOCUMENTS_DATA_FAILED = "GET_DOCUMENTS_DATA_FAILED";

export const GET_DOCUMENT_BY_ID = "GET_DOCUMENT_BY_ID";
export const GET_DOCUMENT_BY_ID_FAILED = "GET_DOCUMENT_BY_ID_FAILED";

export const POST_DOCUMENT_DATA = "POST_DOCUMENT_DATA";
export const POST_DOCUMENT_DATA_FAILED = "POST_DOCUMENT_DATA_FAILED";

export const PUT_DOCUMENT_DATA = "PUT_DOCUMENT_DATA";
export const PUT_DOCUMENT_FAILED = "PUT_DOCUMENT_FAILED";

export const DELETE_DOCUMENT_DATA = "DELETE_DOCUMENT_DATA";
export const DELETE_DOCUMENT_FAILED = "DELETE_DOCUMENT_FAILED";