import React, { Fragment } from "react";
import * as _ from "lodash";
import compose from "utils/compose";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from 'react-document-title';
import Loader from "components/Loader/Loader.js";
import Skeleton from "react-loading-skeleton";
import Section from "components/Section/Section.js";

import Close from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

// core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import avatar from "assets/img/image_placeholder.jpg";

// REDUX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	GetBusinessUnit, GetBrokers, SweetAlert, HideAlert, ShowLoader, HideLoader
} from 'redux/actions';

function Transition(props) {
	return <Slide direction="down" {...props} />;
}

class Home extends React.Component {

	constructor(props) {
		super(props);
		this.modal = React.createRef();
		this.state = {
			open: false,
			brokersData: false,
			// brokerItem: [],
			businessUnitList: [],
			moduleTitle: "",
			chancellor: false,

			id: "",
			name: "",
			brokerName: "",
			phone: "",
			partners: "",
			email: "",
			logo: "",
			description: "",
			newItem: false,
			chancellors: false,
			admin: false,
			businessUnit: "",
			chancellorSpecialty: "",
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		let _moduleTitle = "";
		if (this.props.match.path.indexOf("brokers") > -1) {
			_moduleTitle = "Corretoras";
		} else {
			_moduleTitle = "Corretoras chanceladas";
			this.setState({ chancellor: true });
		}
		this.setState({ moduleTitle: _moduleTitle });

		if (_.isEmpty(this.props.lsBusinessUnit)) {
			this.props.GetBusinessUnit();
		} else {
			this.setState({ businessUnitList: this.props.lsBusinessUnit })
			this.props.GetBrokers();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lsBrokers !== this.props.lsBrokers) {
			let actives = _.orderBy(_.filter(this.props.lsBrokers, ["isActive", true]), ['name'], ['asc']);
			this.props.HideLoader();
			if (this.state.chancellor) {
				this.setState({ brokersData: _.filter(actives, ["chancellors", true]) });
			} else {
				this.setState({ brokersData: actives });
			}
		}

		if (prevProps.lsBusinessUnit !== this.props.lsBusinessUnit) {
			this.setState({ businessUnitList: this.props.lsBusinessUnit });
			this.props.HideLoader();
			this.props.GetBrokers();
		}

		// FAILED
		if (prevProps.brokersFailed !== this.props.brokersFailed) {
			console.log("Brokers Failed: ", this.props.brokersFailed);
			this.handleRequestFail("Erro ao consultar as corretoras");
		}
		if (prevProps.businessUnitsFailed !== this.props.businessUnitsFailed) {
			console.log("BusinessUnit Failed: ", this.props.businessUnitsFailed);
			this.handleRequestFail("Erro ao consultar as unidades de negócios");
		}
	}

	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => { this.props.HideAlert(); }}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		)
	}

	handleView = (item) => {
		this.setState({ ...item, open: true });
	}

	handleManagement = (item) => {
		if (this.state.chancellor) {
			this.props.history.push(`/chancellorsManagement`);
		} else {
			this.props.history.push(`/brokersManagement`);
		}
	}

	handleClose = () => {
		this.setState({ open: false });
	}

	render() {
		const { classes } = this.props;
		const { open, brokersData, moduleTitle, businessUnitList } = this.state;

		return (
			<Fragment>
				<DocumentTitle title={`PRx Consultoria - ${moduleTitle}`} />
				{this.props.user.admin ?
					<GridContainer style={{ paddingTop: "30px", paddingBottom: "50px" }}>
						<GridItem xs={12} sm={2} md={2}>
							<Section title={`${moduleTitle}: (${brokersData ? brokersData.length : "0"})`} />
						</GridItem>

						<GridItem xs={12} sm={10} md={10} style={{ textAlign: 'right' }}>
							<Button
								color="primary"
								size="sm"
								className={classes.marginRight}
								onClick={() => this.handleManagement()}
							>Gerenciar</Button>
						</GridItem>
					</GridContainer>
					:
					<GridContainer style={{ paddingTop: "30px", paddingBottom: "50px" }}>
						<GridItem xs={12} sm={2} md={2}>
							<Section title={`${moduleTitle}: (${brokersData ? brokersData.length : "0"})`} />
						</GridItem>
					</GridContainer>
				}
				<GridContainer>
					{brokersData.length > 0 ?
						brokersData.map(item => (
							<GridItem xs={12} sm={12} md={4} key={item.id}>
								<Card profile>
									<CardAvatar profile >
										{/* <img src={avatar} alt="..." /> */}
										{/* <div className="picture-container-disabled" style={{ marginBottom: '37px' }}> */}
										<div className="picture" >
											<img
												style={{
													objectFit: 'cover',
													height: '100px',
													width: '100px'
												}} className="picture-src" src={item.logo && item.logo !== "" ? item.logo : avatar} alt="..." />
										</div>
										{/* </div> */}
									</CardAvatar>
									<CardBody profile>
										<h6 className={classes.cardCategory}><a href={`mailto:${item.email}`}>{item.email}</a></h6>
										<h4 className={classes.cardTitle}>{item.name}</h4>
										<h6>{item.chancellors ? <i>Chancelado</i> : ""}</h6>
										<h6 className={classes.cardCategory}>{item.brokerName}</h6>
										<hr />
										{item.chancellors ? <div><strong>Chancela</strong>: {item.seal}<br /></div> : ""}

										{item.mobilePhone ? <div><strong>Celular</strong>: {item.mobilePhone}<br /></div> : ""}

										{item.phone ? <div><strong>Telefone</strong>: {item.phone}<br /></div> : ""}

										{item.businessUnit ? <div><strong>Unidade de negócio</strong>: {_.find(businessUnitList, ["id", item.businessUnit]).title}<br /></div> : ""}

										<Button color="primary" size="sm" round onClick={this.handleView.bind(this, item)}>
											Visualizar
                                    </Button>
									</CardBody>
								</Card>
							</GridItem>
						))
						: <h5>Nenhum registro encontrado</h5>}
				</GridContainer >
				{/* MODAL */}
				<Dialog
					className={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					fullWidth={true}
					maxWidth='sm'
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={this.handleClose.bind(this)}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle
						id="classic-modal-communication"
						disableTypography
						className={classes.modalHeader}
					>
						<Button
							justIcon
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							color="transparent"
							onClick={this.handleClose.bind(this)}
						>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle} ><strong>{this.state.name}</strong></h4>
					</DialogTitle>
					<DialogContent
						id="classic-modal-slide-description"
						className={classes.modalBody}
					>
						{this.state.seal ? <div><strong>Chancela</strong>: {this.state.seal}<br /></div> : ""}
						{this.state.email ? <div><strong>E-mail</strong>: {this.state.email}<br /></div> : ""}
						{this.state.mobilePhone ? <div><strong>Celular</strong>: {this.state.mobilePhone}<br /></div> : ""}
						{this.state.phone ? <div><strong>Telefone</strong>: {this.state.phone}<br /></div> : ""}
						{this.state.businessUnit ? <div><strong>Unidade de negócio</strong>: {_.find(businessUnitList, ["id", this.state.businessUnit]).title}<br /></div> : ""}
						{this.state.partners ? <div><strong>Sócios</strong>: {this.state.partners}<br /></div> : ""}

						{/* TAB */}
						{this.state.description ? <div><br /><strong>Descrição</strong></div> : ""}
						{this.state.description ? <div>{this.state.description}</div> : ""}

						{this.state.chancellors && this.state.chancellorSpecialty ? <div><br /><strong>Especialidade</strong></div> : ""}
						{this.state.chancellors && this.state.chancellorSpecialty ? <div>{this.state.chancellorSpecialty}</div> : ""}

					</DialogContent>
				</Dialog>
			</Fragment >
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.authentication.user,

		lsBrokers: state.brokers.lsBrokers,
		lsBusinessUnit: state.businessUnit.lsBusinessUnit,

		brokersFailed: state.brokers.brokersFailed,
		businessUnitsFailed: state.businessUnit.businessUnitsFailed,
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ GetBusinessUnit, GetBrokers, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(userProfileStyles),
	withStyles(modalStyle),
	withStyles(sweetAlertStyle),
)(Home); 