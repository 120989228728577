import * as actionTypes from './actionsTypes';
import * as API from '../api/filesUpload';
import * as _ from "lodash";
import axios from 'axios';

export const FilesUploadPost = (id, _objs, _pageTitle) => {
    let formData = new FormData();
    let newFiles = [];
    // for (const key of Object.keys(obj)) {
    _.forEach(_objs, file => {
        let newFileName = Date.now().toString() + "_" + file.name;

        let obj = {
            idSource: id,
            pageTitle: _pageTitle,
            originalName: file.name,
            fileName: newFileName,
            fileUrl: "https://prx-filesupload-bucket.s3-sa-east-1.amazonaws.com/" + newFileName
        }
        newFiles.push(obj);
        formData.append('file', file, newFileName);

    });
    //
    return dispatch => (
        axios.post(`/fileUpload`, formData).then(response => {
            dispatch(success(response));
            dispatch(SetFilesData(newFiles));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(failed());
        })
    );

    function success(data) {
        return {
            type: actionTypes.POST_FILES_DATA,
            filesPostObj: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.POST_FILES_FAILED,
            filesFailed: error
        };
    };
};

export const SetFilesData = (data) => {
    return dispatch => (
        dispatch(setFilesData(data))
    );
};

export const setFilesData = (data) => {
    return {
        type: actionTypes.SET_FILES_DATA,
        filesObj: data
    };
};

export const GetFiles = (id) => {
    return dispatch => (
        API.GetFiles(id).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_FILES_DATA,
            getFiles: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_FILES_FAILED,
            getFilesFailed: error
        };
    };
}

// DELETE
export const DeleteObject = (id) => {
    return dispatch => {
        API.DeleteObject(id).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
        })
    };

    function success(data) {
        return {
            type: actionTypes.DELETE_FILE_DATA,
            fileDelete: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.DELETE_FILE_DATA_FAILED,
            fileDeleteFailed: error
        };
    };
}
