import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    imageObj: null,
    imageFile: null,
    imageFailed: false,
    getImage: null,
    getImageFailed: false
};

const setImageData = (state, action) => {
    return updateObject(state, {
        imageFile: action.imageFile
    });
};

const postImageData = (state, action) => {
    return updateObject(state, {
        imageObj: action.imageObj
    });
};

const fetchImage = (state, action) => {
    return updateObject(state, {
        getImage: action.getImage
    });
};

// DATA FAIL
const postImageFailed = (state, action) => {
    return updateObject(state, {
        imageFailed: true
    });
};

const fetchImagesFailed = (state, action) => {
    return updateObject(state, {
        getImageFailed: true
    });
};

const imageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_IMAGE_DATA:
            return setImageData(state, action);
        case actionTypes.POST_IMAGE_DATA:
            return postImageData(state, action);
        case actionTypes.POST_IMAGE_FAILED:
            return postImageFailed(state, action);
        case actionTypes.GET_IMAGE_DATA:
            return fetchImage(state, action);
        case actionTypes.GET_IMAGE_FAILED:
            return fetchImagesFailed(state, action);
        default:
            return state;
    }
};

export default imageReducer;