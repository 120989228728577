import * as actionTypes from "./actionsTypes";
import * as API from "../api/production.js";
import * as Loader from './loader';

// GET ALL
export const GetProductions = () => {
    return dispatch => (
        API.GetProductions().then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_PRODUCTIONS_DATA,
            lsProductions: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_PRODUCTIONS_DATA_FAILED,
            productionsFailed: error
        };
    };
}

// GET BY ID
export const GetProductionById = (id) => {
    return dispatch => (
        API.GetProductionById(id).then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_PRODUCTION_BY_ID,
            productionItem: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_PRODUCTION_BY_ID_FAILED,
            productionItemFailed: error
        };
    };
}

// UPDATE
export const PutProduction = (id, obj) => {
    return dispatch => (
        API.PutProduction(id, obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.PUT_PRODUCTION_DATA,
            productionUpdate: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.PUT_PRODUCTION_FAILED,
            productionUpdateFailed: error
        };
    };
}

// POST
export const PostProductionData = (obj) => {
    return dispatch => {
        API.PostProduction(obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.POST_PRODUCTION_DATA,
            insertProduction: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.POST_PRODUCTION_DATA_FAILED,
            insertProductionFailed: error
        };
    };
}

// DELETE
export const DeleteProduction = (id) => {
    return dispatch => {
        API.DeleteProduction(id).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.DELETE_PRODUCTION_DATA,
            productionDelete: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.DELETE_PRODUCTION_FAILED,
            productionDeleteFailed: error
        };
    };
}