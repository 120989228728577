import React from "react";
import * as _ from "lodash";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from "react-document-title";
import PropTypes from "prop-types";
import Section from "components/Section/Section.js";
import compose from "utils/compose";

import { withStyles } from "@material-ui/core/styles";

import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";

import Loader from "components/Loader/Loader.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// REDUX INIT
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	GetBusinessUnit,
	GetBusinessUnitById,
	PutBusinessUnit,
	PostBusinessUnitData,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from "redux/actions";
class BusinessUnit extends React.Component {
	constructor(props) {
		super(props);
		this.modal = React.createRef();
		this.state = {
			businessUnitData: [],
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		this.props.GetBusinessUnit();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lsBusinessUnit !== this.props.lsBusinessUnit) {
			//			console.log("lsBusinessUnit: ", this.props.lsBusinessUnit);
			this.setState({
				businessUnitData: _.orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
			this.props.HideLoader();
		}
		if (prevProps.businessUnitsFailed !== this.props.businessUnitsFailed) {
			console.log(
				"businessUnitsFailed: ",
				this.props.businessUnitsFailed
			);
		}
		if (prevProps.businessUnitItem !== this.props.businessUnitItem) {
			console.log("businessUnitItem: ", this.props.businessUnitItem);
			this.props.HideLoader();
		}
		if (
			prevProps.businessUnitItemFailed !==
			this.props.businessUnitItemFailed
		) {
			console.log(
				"businessUnitItemFailed: ",
				this.props.businessUnitItemFailed
			);
		}
		if (prevProps.businessUnitUpdate !== this.props.businessUnitUpdate) {
			console.log("businessUnitUpdate: ", this.props.businessUnitUpdate);
			this.props.HideLoader();
		}
		if (
			prevProps.businessUnitUpdateFailed !==
			this.props.businessUnitUpdateFailed
		) {
			console.log(
				"businessUnitUpdateFailed: ",
				this.props.businessUnitUpdateFailed
			);
		}
		if (prevProps.insertBusinessUnit !== this.props.insertBusinessUnit) {
			console.log("insertBusinessUnit: ", this.props.insertBusinessUnit);
			this.props.HideLoader();
		}
		if (
			prevProps.insertBusinessUnitFailed !==
			this.props.insertBusinessUnitFailed
		) {
			console.log(
				"insertBusinessUnitFailed: ",
				this.props.insertBusinessUnitFailed
			);
		}
	}

	handleBack = () => {
		this.props.HideAlert();
		this.props.HideLoader();
		// this.props.history.push(`/manageCommunications`);
	};

	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => {
					this.props.HideAlert();
				}}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		);
	};

	handleNew = () => {
		this.props.history.push(`/businessUnit/new`);
	};

	handleEdit = (id) => {
		this.props.history.push(`/businessUnit/edit/${id}`);
	};
	//
	render() {
		const { t, classes } = this.props;
		const { businessUnitData, date } = this.state;

		return (
			<>
				<DocumentTitle title="PRx Consultoria - Parceiros" />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<CardHeader
								style={{
									padding: "28px",
									paddingBottom: "10px",
								}}
							>
								<GridContainer>
									<GridItem xs={12} sm={8} md={7}>
										<Section title="Unidades de negócios" />
									</GridItem>
									{this.props.user.admin && (
										<GridItem
											xs={12}
											sm={3}
											md={5}
											style={{ textAlign: "right" }}
										>
											<Button
												color="primary"
												size="sm"
												className={classes.marginRight}
												onClick={() => this.handleNew()}
											>
												Novo
											</Button>
										</GridItem>
									)}
								</GridContainer>
							</CardHeader>
							<CardBody style={{ padding: "28px" }}>
								{businessUnitData &&
								businessUnitData.length > 0 ? (
									<Table
										striped
										tableHead={[
											"",
											"Titulo",
											"Descrição",
											"Ação",
										]}
										tableData={businessUnitData.map(
											(item, index) => [
												index + 1,
												item.title,
												item.description,
												<>
													{/* <Button
														justIcon
														size="sm"
														value={item.id}
														color="primary"
														onClick={this.handleView.bind(
															this,
															item.id
														)}
													>
														<Eyes
															className={
																classes.icons
															}
														/>
													</Button> */}
													{this.props.user.admin && (
														<>
															<Button
																justIcon
																size="sm"
																value={item.id}
																color="primary"
																onClick={this.handleEdit.bind(
																	this,
																	item.id
																)}
															>
																<Edit
																	className={
																		classes.icons
																	}
																/>
															</Button>
															{/* <Button
																justIcon
																size="sm"
																value={item.id}
																color="danger"
																onClick={this.askDelete.bind(
																	this,
																	item.id
																)}
															>
																<Delete
																	className={
																		classes.icons
																	}
																/>
															</Button> */}
														</>
													)}
												</>,
											]
										)}
										customCellClasses={[
											classes.center,
											classes.right,
											classes.right,
										]}
										customClassesForCells={[0, 4, 5]}
										customHeadCellClasses={[
											classes.center,
											classes.right,
											classes.right,
										]}
										customHeadClassesForCells={[0, 4, 5]}
									/>
								) : (
									<h5>Nenhum registro encontrado</h5>
								)}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

BusinessUnit.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object,
	lsBusinessUnit: PropTypes.object,
	businessUnitsFailed: PropTypes.object,
	businessUnitItem: PropTypes.object,
	businessUnitItemFailed: PropTypes.object,
	businessUnitUpdate: PropTypes.object,
	businessUnitUpdateFailed: PropTypes.object,
	insertBusinessUnit: PropTypes.object,
	insertBusinessUnitFailed: PropTypes.object,
	SweetAlert: PropTypes.func,
	HideAlert: PropTypes.func,
	ShowLoader: PropTypes.func,
	HideLoader: PropTypes.func,
	history: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,

		lsBusinessUnit: state.businessUnit.lsBusinessUnit,
		businessUnitsFailed: state.businessUnit.businessUnitsFailed,

		businessUnitItem: state.businessUnit.businessUnitItem,
		businessUnitItemFailed: state.businessUnit.businessUnitItemFailed,

		businessUnitUpdate: state.businessUnit.businessUnitUpdate,
		businessUnitUpdateFailed: state.businessUnit.businessUnitUpdateFailed,

		insertBusinessUnit: state.businessUnit.insertBusinessUnit,
		insertBusinessUnitFailed: state.businessUnit.insertBusinessUnitFailed,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetBusinessUnit,
			GetBusinessUnitById,
			PutBusinessUnit,
			PostBusinessUnitData,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(extendedTablesStyle),
	withStyles(sweetAlertStyle)
)(BusinessUnit);
