import React, { Fragment } from "react";
import DocumentTitle from "react-document-title";

import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

import * as _ from "lodash";
import compose from "utils/compose";

import { withStyles } from "@material-ui/core/styles";

import { Tooltip } from "@material-ui/core";

// @material-ui/icons
import Description from "@material-ui/icons/Description";

// core components
import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import Section from "components/Section/Section.js";

import HDI_img from "assets/img/insurer/HDI.jpg";
import Sompo_img from "assets/img/insurer/Sompo.jpg";
import LibertySeguros_img from "assets/img/insurer/LibertySeguros.png";
import Chubb_img from "assets/img/insurer/CHUBB_Logo_Black_RBG.png";
import Axa_img from "assets/img/insurer/axa-logo.jpg";
// import Mitsui from "assets/img/insurer/Mitsui.jpg";

// REDUX INIT
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	GetInsurers,
	GetAttachmentsById,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from "redux/actions";

const styles = {
	cardTitle,
	pageSubcategoriesTitle: {
		color: "#3C4858",
		textDecoration: "none",
		textAlign: "center",
	},
	cardCategory: {
		margin: "0",
		color: "#999999",
	},
};

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filesObj: [],

			richTxtHDI: "",
			richTxtSompo: "",
			richTxtLiberty: "",
			// richTxtMitsui: "",
			richTxtChubb: "",
			richTxtAxa: "",

			filesHDI: "",
			filesSompo: "",
			filesLiberty: "",
			// filesMitsui: "",
			filesChubb: "",
			filesAxa: "",
		};
	}

	componentDidMount() {
		this.props.GetInsurers();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lsInsurers !== this.props.lsInsurers) {
			console.log(
				"lsInsurers: ",
				_.orderBy(this.props.lsInsurers, ["title", "asc"])
			);
			_.forEach(this.props.lsInsurers, (item) => {
				if (item.title == "HDI") {
					this.setState({ richTxtHDI: item.deal });
					this.props.GetAttachmentsById(item.id);
				}
				if (item.title == "Sompo") {
					this.setState({ richTxtSompo: item.deal });
					this.props.GetAttachmentsById(item.id);
				}
				if (item.title == "Liberty") {
					this.setState({ richTxtLiberty: item.deal });
					this.props.GetAttachmentsById(item.id);
				}
				// if (item.title == "Mitsui Sumitomo Seguros") {
				//     this.setState({ richTxtMitsui: item.deal });
				//     this.props.GetAttachmentsById(item.id);
				// }
				if (item.title == "Chubb Brasil Home") {
					this.setState({ richTxtChubb: item.deal });
					this.props.GetAttachmentsById(item.id);
				}
				if (item.title == "AXA Brasil") {
					this.setState({ richTxtAxa: item.deal });
					this.props.GetAttachmentsById(item.id);
				}
				// Chubb Brasil Home
				// AXA Brasil
			});
		}

		if (prevProps.attachmentItem !== this.props.attachmentItem) {
			if (this.props.attachmentItem.length > 0) {
				let item = this.props.attachmentItem[0].idSource;
				//
				if (item === "1c4e42d5-d3d6-417a-8664-1844c494e1ee") {
					this.setState({ filesHDI: this.props.attachmentItem });
				}

				if (item === "5f4459ba-6021-4bf5-a22a-d4fb9d500112") {
					this.setState({ filesSompo: this.props.attachmentItem });
				}

				if (item === "b205e534-fa4e-48c5-82f3-2b116e8fec0f") {
					this.setState({ filesLiberty: this.props.attachmentItem });
				}

				if (item === "1c4e42d5-4d5f-4bf5-a22a-2b116e8fec0f") {
					this.setState({ filesChubb: this.props.attachmentItem });
				}

				if (item === "1c4e42d5-6021-48c5-8c16-d4fb9d500112") {
					this.setState({ filesAxa: this.props.attachmentItem });
				}
			}
		}

		if (prevState.filesHDI !== this.state.filesHDI) {
			console.log("files HDI: ", this.state.filesHDI);
		}

		if (prevState.filesSompo !== this.state.filesSompo) {
			console.log("files Sompo: ", this.state.filesSompo);
		}
		if (prevState.filesLiberty !== this.state.filesLiberty) {
			console.log("files Liberty: ", this.state.filesLiberty);
		}

		if (prevProps.insurersFailed !== this.props.insurersFailed) {
			console.log("insurersFailed: ", this.props.insurersFailed);
		}
	}

	handleManage = (id) => {
		this.props.history.push(`/manageInsurer`);
	};

	// QUILL TEXT EDITOR
	modules = {
		toolbar: [
			["bold", "italic", "underline", "strike"], // toggled buttons
			["blockquote", "code-block"], // blocks
			[{ header: 1 }, { header: 2 }], // custom button values
			[{ list: "ordered" }, { list: "bullet" }], // lists
			[{ script: "sub" }, { script: "super" }], // superscript/subscript
			[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
			["link", "image"], // link image
			[{ direction: "rtl" }], // text direction
			[{ size: ["small", false, "large", "huge"] }], // custom dropdown
			[{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
			[{ color: [] }, { background: [] }], // dropdown with defaults
			[{ font: [] }], // font family
			[{ align: [] }], // text align
			["clean"], // remove formatting
		],
		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	};

	disabledModules = {
		toolbar: [],
		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	};

	formats = [
		"header",
		"font",
		"background",
		"color",
		"code",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"script",
		"align",
		"direction",
		"link",
		"image",
		"code-block",
		"formula",
		"video",
	];

	handleChangeTab = (e) => {
		console.log(e);
	};

	render() {
		const { classes } = this.props;
		const {
			richTxtHDI,
			richTxtSompo,
			richTxtLiberty,
			richTxtChubb,
			richTxtAxa,
			richTxtMitsui,

			filesHDI,
			filesMitsui,
			filesSompo,
			filesLiberty,
			filesChubb,
			filesAxa,
		} = this.state;

		return (
			<Fragment>
				<DocumentTitle title="PRx Consultoria - Seguradoras Parceiras" />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<CardHeader
								style={{
									padding: "28px",
									paddingBottom: "10px",
								}}
							>
								<GridContainer>
									<GridItem xs={12} sm={8} md={7}>
										<Section title="Seguradoras Parceiras" />
									</GridItem>
									{this.props.user.admin && (
										<GridItem
											xs={12}
											sm={3}
											md={5}
											style={{ textAlign: "right" }}
										>
											<Button
												color="primary"
												size="sm"
												className={classes.marginRight}
												onClick={() =>
													this.handleManage()
												}
											>
												Gerenciar
											</Button>
										</GridItem>
									)}
								</GridContainer>
							</CardHeader>
							<CardBody style={{ padding: "28px" }}>
								<GridContainer>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											padding: "0 30px",
										}}
									>
										{/* AXA BRASIL */}
										<div>
											<a
												href="https://www.axa.com.br/?keyword=axa%20seguros&creative=520388348289"
												target="_blank"
												title="AXA Brasil - Ir para o site"
											>
												<img
													src={Axa_img}
													width="150px"
													alt="AXA Brasil"
												/>
											</a>
										</div>

										{/* Chubb Brasil */}
										<div>
											<a
												href="https://www.chubb.com/br-pt/"
												target="_blank"
												title="Chubb Brasil - Ir para o site"
											>
												<img
													src={Chubb_img}
													width="150px"
													alt="Chubb Brasil"
												/>
											</a>
										</div>

										{/* HDI */}
										<div>
											<a
												href="https://www.hdiseguros.com.br/pt"
												target="_blank"
												title="HDI - Ir para o site"
											>
												<img
													src={HDI_img}
													alt="HDI"
													width="100px"
												/>
											</a>
										</div>

										{/* LIBERTY */}
										<div>
											<a
												href="https://www.libertyseguros.com.br/Pages/Home.aspx"
												target="_blank"
												title="LIBERTY SEGUROS - Ir para o site"
											>
												<img
													src={LibertySeguros_img}
													alt="LIBERTY SEGUROS"
												/>
											</a>
										</div>

										{/* SOMPO */}
										<div style={{ textAlign: "center" }}>
											<a
												href="https://sompo.com.br/"
												target="_blank"
												title="SOMPO - Ir para o site"
											>
												<img
													src={Sompo_img}
													alt="SOMPO"
													width="200px"
												/>
											</a>
										</div>
									</div>
								</GridContainer>
								<hr />
								<GridContainer>
									<NavPills
										color="primary"
										alignCenter
										tabs={[
											{
												tabButton: "AXA Brasil",
												tabIcon: Description,
												tabContent: (
													<Card>
														<CardHeader>
															<Section title="ACORDO" />
														</CardHeader>
														<CardBody
															style={{
																padding: 0,
															}}
														>
															<ReactQuill
																theme={"bubble"}
																value={
																	richTxtAxa
																}
																modules={
																	this
																		.disabledModules
																}
																formats={
																	this.formats
																}
																readOnly={true}
															/>
															{/* FILES */}
															<GridItem>
																<hr />
																<b>
																	Arquivos:{" "}
																</b>
																{filesAxa &&
																filesAxa.length >
																	0
																	? filesAxa.map(
																			(
																				item
																			) => [
																				<Fragment
																					key={
																						item.id
																					}
																				>
																					<Tooltip
																						arrow
																						id="tooltip-top"
																						title={
																							item.originalName
																						}
																						placement="top"
																						classes={{
																							tooltip:
																								classes.tooltip,
																						}}
																					>
																						<span
																							className={
																								styles.attachItem
																							}
																						>
																							<a
																								style={{
																									padding:
																										"0 15px",
																								}}
																								href={
																									item.fileUrl
																								}
																								target="_blank"
																							>
																								{
																									item.originalName
																								}
																							</a>
																						</span>
																					</Tooltip>
																					{
																						" - "
																					}
																				</Fragment>,
																			]
																	  )
																	: "Nenhum resultado encontrado."}
															</GridItem>
														</CardBody>
													</Card>
												),
											},
											{
												tabButton: "Chubb Brasil",
												tabIcon: Description,
												tabContent: (
													<Card>
														<CardHeader>
															<Section title="ACORDO" />
														</CardHeader>
														<CardBody
															style={{
																padding: 0,
															}}
														>
															<ReactQuill
																theme={"bubble"}
																value={
																	richTxtChubb
																}
																modules={
																	this
																		.disabledModules
																}
																formats={
																	this.formats
																}
																readOnly={true}
															/>
															{/* FILES */}
															<GridItem>
																<hr />
																<b>
																	Arquivos:{" "}
																</b>
																{filesChubb &&
																filesChubb.length >
																	0
																	? filesChubb.map(
																			(
																				item
																			) => [
																				<Fragment
																					key={
																						item.id
																					}
																				>
																					<Tooltip
																						arrow
																						id="tooltip-top"
																						title={
																							item.originalName
																						}
																						placement="top"
																						classes={{
																							tooltip:
																								classes.tooltip,
																						}}
																					>
																						<span
																							className={
																								styles.attachItem
																							}
																						>
																							<a
																								style={{
																									padding:
																										"0 15px",
																								}}
																								href={
																									item.fileUrl
																								}
																								target="_blank"
																							>
																								{
																									item.originalName
																								}
																							</a>
																						</span>
																					</Tooltip>
																					{
																						" - "
																					}
																				</Fragment>,
																			]
																	  )
																	: "Nenhum resultado encontrado."}
															</GridItem>
														</CardBody>
													</Card>
												),
											},
											{
												tabButton: "HDI SEGUROS",
												tabIcon: Description,
												tabContent: (
													<Card>
														<CardHeader>
															<Section title="ACORDO" />
														</CardHeader>
														<CardBody
															style={{
																padding: 0,
															}}
														>
															<ReactQuill
																theme={"bubble"}
																value={
																	richTxtHDI
																}
																modules={
																	this
																		.disabledModules
																}
																formats={
																	this.formats
																}
																readOnly={true}
															/>
															{/* FILES */}
															<GridItem>
																<hr />
																<b>
																	Arquivos:{" "}
																</b>
																{filesHDI &&
																filesHDI.length >
																	0
																	? filesHDI.map(
																			(
																				item
																			) => [
																				<Fragment
																					key={
																						item.id
																					}
																				>
																					<Tooltip
																						arrow
																						id="tooltip-top"
																						title={
																							item.originalName
																						}
																						placement="top"
																						classes={{
																							tooltip:
																								classes.tooltip,
																						}}
																					>
																						<span
																							className={
																								styles.attachItem
																							}
																						>
																							<a
																								style={{
																									padding:
																										"0 15px",
																								}}
																								href={
																									item.fileUrl
																								}
																								target="_blank"
																							>
																								{
																									item.originalName
																								}
																							</a>
																						</span>
																					</Tooltip>
																					{
																						" - "
																					}
																				</Fragment>,
																			]
																	  )
																	: "Nenhum resultado encontrado."}
															</GridItem>
														</CardBody>
													</Card>
												),
											},
											{
												tabButton: "LIBERTY SEGUROS",
												tabIcon: Description,
												tabContent: (
													<Card>
														<CardHeader>
															<Section title="ACORDO" />
														</CardHeader>
														<CardBody
															style={{
																padding: 0,
															}}
														>
															<ReactQuill
																theme={"bubble"}
																value={
																	richTxtLiberty
																}
																modules={
																	this
																		.disabledModules
																}
																formats={
																	this.formats
																}
																readOnly={true}
															/>
															{/* FILES */}
															<GridItem>
																<hr />
																<b>
																	Arquivos:{" "}
																</b>
																{filesLiberty &&
																filesLiberty.length >
																	0
																	? filesLiberty.map(
																			(
																				item
																			) => [
																				<Fragment
																					key={
																						item.id
																					}
																				>
																					<Tooltip
																						arrow
																						id="tooltip-top"
																						title={
																							item.originalName
																						}
																						placement="top"
																						classes={{
																							tooltip:
																								classes.tooltip,
																						}}
																					>
																						<span
																							className={
																								styles.attachItem
																							}
																						>
																							<a
																								style={{
																									padding:
																										"0 15px",
																								}}
																								href={
																									item.fileUrl
																								}
																								target="_blank"
																							>
																								{
																									item.originalName
																								}
																							</a>
																						</span>
																					</Tooltip>
																					{
																						" - "
																					}
																				</Fragment>,
																			]
																	  )
																	: "Nenhum resultado encontrado."}
															</GridItem>
														</CardBody>
													</Card>
												),
											},
											{
												tabButton: "SOMPO SEGUROS",
												tabIcon: Description,
												tabContent: (
													<Card>
														<CardHeader>
															<Section title="ACORDO" />
														</CardHeader>
														<CardBody
															style={{
																padding: 0,
															}}
														>
															<ReactQuill
																theme={"bubble"}
																value={
																	richTxtSompo
																}
																modules={
																	this
																		.disabledModules
																}
																formats={
																	this.formats
																}
																readOnly={true}
															/>
															{/* FILES */}
															<GridItem>
																<hr />
																<b>
																	Arquivos:{" "}
																</b>
																{filesSompo &&
																filesSompo.length >
																	0
																	? filesSompo.map(
																			(
																				item
																			) => [
																				<Fragment
																					key={
																						item.id
																					}
																				>
																					<Tooltip
																						arrow
																						id="tooltip-top"
																						title={
																							item.originalName
																						}
																						placement="top"
																						classes={{
																							tooltip:
																								classes.tooltip,
																						}}
																					>
																						<span
																							className={
																								styles.attachItem
																							}
																						>
																							<a
																								style={{
																									padding:
																										"0 15px",
																								}}
																								href={
																									item.fileUrl
																								}
																								target="_blank"
																							>
																								{
																									item.originalName
																								}
																							</a>
																						</span>
																					</Tooltip>
																					{
																						" - "
																					}
																				</Fragment>,
																			]
																	  )
																	: "Nenhum resultado encontrado."}
															</GridItem>
														</CardBody>
													</Card>
												),
											},
										]}
									/>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,

		lsInsurers: state.insurer.lsInsurers,
		insurersFailed: state.insurer.insurersFailed,

		filesObj: state.filesUpload.filesObj,
		attachmentItem: state.attachments.attachmentItem,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetInsurers,
			GetAttachmentsById,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles)
)(Home);
