import React, { Fragment } from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from "react-document-title";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";

import Loader from "components/Loader/Loader.js";

import compose from "utils/compose";

import {
	ImageUpload,
	GetBusinessUnit,
	GetBrokers,
	GetBrokerById,
	PutBroker,
	PostBrokerData,
	DeleteBroker,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from "redux/actions";

import Form from "./Component/Profile.js";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const validationSchema = Yup.object({
	name: Yup.string()
		.max(150, " máximo 150 caracteres")
		.required(" obrigatório."),
	// email: Yup.string().max(50, " máximo 50 caracteres").email(" formato inválido.").required(" obrigatório."),
	phone: Yup.string().required(" obrigatório"),
	mobilePhone: Yup.string().required(" obrigatório"),
	partners: Yup.string().required(" obrigatório."),
	businessUnit: Yup.string().required(" obrigatório."),
	// description: Yup.string().required(" obrigatório."),
});

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.formik = React.createRef();
		this.state = {
			sub: "",
			id: "",
			name: "",
			phone: "",
			mobilePhone: "",
			partners: "",
			email: "",
			logo: "",
			description: "",
			chancellors: false,
			admin: false,
			isActive: true,
			businessUnit: "",
			businessUnitList: {},
			chancellorSpecialty: "",
			created: null,
			newImage: null,

			brokersData: [],
			brokerItem: [],
			disabledForm: true,
			editMode: false,
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		const user =
			localStorage.getItem("user") &&
			JSON.parse(localStorage.getItem("user"));
		if (!user) {
			this.props.history.push(`/auth/login`);
		}
		this.setState({ id: user.id });
		//
		if (_.isEmpty(this.props.brokerItem)) {
			this.props.GetBrokerById(user.id);
		}
		//
		if (_.isEmpty(this.props.lsBusinessUnit)) {
			this.props.GetBusinessUnit();
		} else {
			this.setState({
				businessUnitList: _.orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
			this.setState({ ...this.props.brokerItem });
			this.props.HideLoader();
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.brokersFailed !== this.props.brokersFailed) {
			console.log("brokersFailed: ", this.props.brokersFailed);
		}

		if (prevProps.lsBrokers !== this.props.lsBrokers) {
			let user = _.find(this.props.lsBrokers, ["id", this.state.id]);
			this.setState({ ...user });
			this.props.HideLoader();
		}

		if (prevProps.user !== this.props.user) {
			// console.log("user: ", this.props.user);
		}

		if (prevProps.lsBusinessUnit !== this.props.lsBusinessUnit) {
			this.setState({
				businessUnitList: _.orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
			// if (_.isEmpty(this.props.brokerItem)) {
			//     this.props.GetBrokerById(this.state.id);
			// }
		}

		if (prevProps.brokerItem !== this.props.brokerItem) {
			// console.log("brokerItem: ", this.props.brokerItem);
			this.setState({ ...this.props.brokerItem });
			this.props.HideLoader();
		}
		if (prevProps.brokerUpdate !== this.props.brokerUpdate) {
			this.props.HideLoader();
			console.log("brokerUpdate: ", this.props.brokerUpdate);
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.props.HideAlert();
						this.props.HideLoader();
						this.props.GetBrokerById(this.state.id);
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Operação realizada com Sucesso!
				</Alert>
			);
		}
		if (prevProps.insertBroker !== this.props.insertBroker) {
			console.log("insertBroker: ", this.props.insertBroker);
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Operação realizada com Sucesso!
				</Alert>
			);
			setTimeout(() => this.handleBack(), 3000);
		}

		if (prevProps.brokersFailed !== this.props.brokersFailed) {
			console.log("brokersFailed: ", this.props.brokersFailed);
		}
		if (prevProps.brokerItemFailed !== this.props.brokerItemFailed) {
			console.log("brokerItemFailed: ", this.props.brokerItemFailed);
		}
		if (prevProps.brokerUpdateFailed !== this.props.brokerUpdateFailed) {
			console.log("brokerUpdateFailed: ", this.props.brokerUpdateFailed);
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: "block" }}
					title={"Error"}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Erro ao atualizar informação.
				</Alert>
			);
		}
		if (prevProps.insertBrokerFailed !== this.props.insertBrokerFailed) {
			console.log("insertBrokerFailed: ", this.props.insertBrokerFailed);
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: "block" }}
					title={"Error"}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Erro ao inserir informação.
				</Alert>
			);
		}
	};

	handleStateChange = (state, newValue) => {
		this.setState({ [state]: newValue, cityId: "" });
	};

	handleSave = (values) => {
		this.props.ShowLoader(<Loader />);
		const ID = this.state.id;
		if (this.props.imageFile) {
			this.props.ImageUpload(this.props.imageFile).then((rest) => {
				if (rest) {
					values.logo = rest.location;
				}
				this.props
					.PutBroker(ID, values)
					.then(() =>
						this.setState({ disabledForm: true, editMode: false })
					);
			});
		} else {
			this.props
				.PutBroker(ID, values)
				.then(() =>
					this.setState({ disabledForm: true, editMode: false })
				);
		}
	};

	handleEdit() {
		this.setState({ disabledForm: false, editMode: true });
	}

	handleBack() {
		this.props.HideAlert();
		this.props.history.push(`/home`);
		this.setState({ disabledForm: true, editMode: false });
	}

	handleCancel() {
		this.props.HideAlert();
		// this.props.history.goBack();
		this.setState({ disabledForm: true, editMode: false });
	}

	callbackFunction = (e) => {
		this.setState({ newImage: e });
	};

	render() {
		const {
			id,
			name,
			phone,
			mobilePhone,
			partners,
			email,
			logo,
			description,
			chancellors,
			admin,
			isActive,
			businessUnit,
			chancellorSpecialty,
			businessUnitList,
			disabledForm,
			editMode,
		} = this.state;
		const values = {
			name,
			phone,
			mobilePhone,
			partners,
			email,
			logo,
			description,
			chancellors,
			admin,
			isActive,
			businessUnit,
			chancellorSpecialty,
		};
		return (
			<Fragment>
				<DocumentTitle title={"PRx Consultoria - Meu Perfil"} />

				<Formik
					ref={this.formik}
					render={(props) => (
						<Form
							{...props}
							handleCancel={this.handleCancel.bind(this)}
							handleBack={this.handleBack.bind(this)}
							handleEdit={this.handleEdit.bind(this)}
							businessUnitList={businessUnitList}
							disabledForm={disabledForm}
							editMode={editMode}
						/>
					)}
					// validationSchema={validationSchema}
					initialValues={values}
					onSubmit={this.handleSave.bind(this)}
					enableReinitialize={true}
				/>

				{/* {!disabledForm &&
                    <AvatarEditor img={this.state.picture} parentCallback={this.callbackFunction} />
                } */}
			</Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,

		lsBrokers: state.brokers.lsBrokers,
		brokersFailed: state.brokers.brokersFailed,

		lsBrokers: state.brokers.lsBrokers,
		brokerItem: state.brokers.brokerItem,
		brokerUpdate: state.brokers.brokerUpdate,
		insertBroker: state.brokers.insertBroker,

		brokersFailed: state.brokers.brokersFailed,
		brokerItemFailed: state.brokers.brokerItemFailed,
		brokerUpdateFailed: state.brokers.brokerUpdateFailed,
		insertBrokerFailed: state.brokers.insertBrokerFailed,

		lsBusinessUnit: state.businessUnit.lsBusinessUnit,
		businessUnitsFailed: state.businessUnit.businessUnitsFailed,

		imageFile: state.images.imageFile,
		imageObj: state.images.imageObj,
		getImage: state.images.getImage,
		imageFailed: state.images.imageFailed,
		getImageFailed: state.images.getImageFailed,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			ImageUpload,
			GetBusinessUnit,
			GetBrokers,
			GetBrokerById,
			PutBroker,
			PostBrokerData,
			DeleteBroker,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(extendedFormsStyle),
	withStyles(sweetAlertStyle)
)(Profile);
