import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import NumberFormat from 'react-number-format';

class DoubleFormatCustom extends React.Component {

	render() {
		const { inputRef, onChange, ...other } = this.props;
		return (
			<NumberFormat
				{...other}
				getInputRef={inputRef}
				isNumericString={true}
				decimalSeparator={','}
				decimalScale={2}
				thousandSeparator={'.'}
				onValueChange={values => {
					onChange({
						target: {
							id: other.id,
							value: values.value,
						},
					});
				}}
			/>
		);
	}
}
DoubleFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default DoubleFormatCustom