import React from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from "react-document-title";
import PropTypes from "prop-types";
import compose from "utils/compose";
import { Formik } from "formik";

import { withStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DoubleFormatCustom from "components/NumberFormatCustom/DoubleFormatCustom.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loader from "components/Loader/Loader.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import Section from "components/Section/Section.js";

// REDUX INIT
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	GetBusinessUnitById,
	PutBusinessUnit,
	PostBusinessUnitData,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from "redux/actions";

const validationSchema = Yup.object({
	title: Yup.string().required(" obrigatório."),
	description: Yup.string().required(" obrigatório."),
});

const Form = (props) => {
	const {
		classes,
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleCancel,
		handleEdit,
		handleBack,
		disabledForm,
		setFieldTouched,
		setFieldValue,
		editMode,
	} = props;

	const change = (name, e) => {
		e.persist();
		handleChange(e);
		// setFieldValue(name, e.target.value);
		setFieldTouched(name, true, false);
	};

	return (
		<>
			<form>
				<GridContainer>
					{/* FORM CARD */}
					<GridItem xs={12} sm={4} md={12}>
						<Card>
							<CardHeader style={{ padding: "28px" }}>
								<GridContainer>
									<GridItem xs={12} sm={8} md={7}>
										<Section title="Unidade de negócio" />
									</GridItem>
									{/* <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className={classes.marginRight}
                                        onClick={() => this.handleNew()}>Novo</Button>
                                </GridItem> */}
									<GridItem
										xs={12}
										sm={3}
										md={5}
										style={{ textAlign: "right" }}
									>
										{editMode && (
											<Button
												color="primary"
												size="sm"
												className={classes.marginRight}
												onClick={handleSubmit}
											>
												Salvar
											</Button>
										)}
										{editMode && (
											<Button
												color="info"
												size="sm"
												onClick={handleCancel}
											>
												Cancelar
											</Button>
										)}
										{disabledForm && (
											<>
												<Button
													color="primary"
													size="sm"
													className={
														classes.marginRight
													}
													onClick={handleEdit}
												>
													Editar
												</Button>
												<Button
													color="info"
													size="sm"
													className={
														classes.marginRight
													}
													onClick={handleBack}
												>
													Voltar
												</Button>
											</>
										)}
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody style={{ padding: "0 28px" }}>
								{/* L1 */}
								<GridContainer>
									<GridItem xs={6}>
										<CustomInput
											error={
												touched.title &&
												Boolean(errors.title)
											}
											labelText="Nome"
											id="title"
											name="title"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"title"
												),
												disabled: disabledForm,
												value: values.title,
											}}
										/>
									</GridItem>
									{/* DESCRIPTION */}
									<GridItem xs={6}>
										<CustomInput
											error={
												touched.description &&
												Boolean(errors.description)
											}
											labelText="Categoria"
											id="description"
											name="description"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"description"
												),
												disabled: disabledForm,
												value: values.description,
											}}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</form>
		</>
	);
};

class BusinessUnitForm extends React.Component {
	constructor(props) {
		super(props);
		let formik = React.createRef();
		this.state = {
			id: "",
			title: "",
			description: "",
			created: "",
			updated: "",
			editMode: false,
			disabledForm: false,
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		const _new = _.includes(this.props.match.path, "new");
		const id = this.props.match && this.props.match.params.id;
		let _edit = _.includes(this.props.match.path, "edit");
		if (_new) {
			this.setState({ editMode: true, disabledForm: false });
			this.props.HideLoader();
		} else if (_edit) {
			this.setState({ editMode: true, disabledForm: false });
			this.props.GetBusinessUnitById(id);
		} else {
			this.setState({ editMode: false, disabledForm: true });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.businessUnitItem !== this.props.businessUnitItem) {
			this.setState({
				id: this.props.businessUnitItem.id,
				title: this.props.businessUnitItem.title,
				description: this.props.businessUnitItem.description,
			});
			this.props.HideLoader();
		}

		if (
			prevProps.businessUnitItemFailed !==
			this.props.businessUnitItemFailed
		) {
			console.log(
				"Business Units Failed: ",
				this.props.businessUnitItemFailed
			);
			this.handleRequestFail("Erro ao consultar unidades de negócios");
		}

		if (prevProps.businessUnitUpdate !== this.props.businessUnitUpdate) {
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Unidade de negócio atualizada com sucesso!
				</Alert>
			);
			setTimeout(() => this.handleBack(), 3000);
		}

		if (
			prevProps.businessUnitUpdateFailed !==
			this.props.businessUnitUpdateFailed
		) {
			console.log(
				"businessUnitUpdateFailed: ",
				this.props.businessUnitUpdateFailed
			);
			this.handleRequestFail("Erro ao atualizar a unidade de negócio");
		}

		if (prevProps.insertBusinessUnit !== this.props.insertBusinessUnit) {
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Unidade de negócio inserida com sucesso!
				</Alert>
			);
			setTimeout(() => this.handleBack(), 3000);
		}

		if (
			prevProps.insertBusinessUnitFailed !==
			this.props.insertBusinessUnitFailed
		) {
			console.log(
				"insertBusinessUnitFailed: ",
				this.props.insertBusinessUnitFailed
			);
			this.handleRequestFail("Erro ao inserir a unidade de negócio");
		}
	}

	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => {
					this.props.HideAlert();
				}}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		);
	};

	handleSave = (values) => {
		this.props.ShowLoader(<Loader />);
		this.setState({ editMode: false, disabledForm: true });
		let obj = {
			title: values.title,
			description: values.description,
		};
		//
		if (!this.state.id) {
			this.props.PostBusinessUnitData(obj);
		} else {
			this.props.PutBusinessUnit(this.state.id, obj);
		}
	};

	handleEdit() {
		this.props.history.push(`/businessUnit/edit/${this.state.id}`);
	}

	handleCancel() {
		this.props.HideLoader();
		this.props.HideAlert();
		this.props.history.push(`/businessUnit`);
	}

	handleBack() {
		this.props.HideLoader();
		this.props.HideAlert();
		this.props.history.push(`/businessUnit`);
	}

	handleChange = (event) => {
		const id = event.target.id || event.target.name;
		let value = event.target.value;
		//
		this.setState({ [id]: value });
	};

	render() {
		const { classes } = this.props;
		const { title, description, editMode, disabledForm } = this.state;
		const values = { title, description };

		return (
			<>
				<DocumentTitle title={"PRx Consultoria - Unidade de negócio"} />
				<Formik
					ref={this.formik}
					render={(props) => (
						<Form
							{...props}
							classes={classes}
							handleCancel={this.handleCancel.bind(this)}
							handleBack={this.handleBack.bind(this)}
							handleEdit={this.handleEdit.bind(this)}
							disabledForm={disabledForm}
							editMode={editMode}
						/>
					)}
					validationSchema={validationSchema}
					onSubmit={this.handleSave.bind(this)}
					initialValues={values}
					enableReinitialize={true}
				/>
			</>
		);
	}
}

BusinessUnitForm.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object,
	lsBusinessUnit: PropTypes.object,
	businessUnitsFailed: PropTypes.object,
	businessUnitItem: PropTypes.object,
	businessUnitItemFailed: PropTypes.object,
	businessUnitUpdate: PropTypes.object,
	businessUnitUpdateFailed: PropTypes.object,
	insertBusinessUnit: PropTypes.object,
	insertBusinessUnitFailed: PropTypes.object,
	SweetAlert: PropTypes.func,
	HideAlert: PropTypes.func,
	ShowLoader: PropTypes.func,
	HideLoader: PropTypes.func,
	history: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,

		lsBusinessUnit: state.businessUnit.lsBusinessUnit,
		businessUnitsFailed: state.businessUnit.businessUnitsFailed,

		businessUnitItem: state.businessUnit.businessUnitItem,
		businessUnitItemFailed: state.businessUnit.businessUnitItemFailed,

		businessUnitUpdate: state.businessUnit.businessUnitUpdate,
		businessUnitUpdateFailed: state.businessUnit.businessUnitUpdateFailed,

		insertBusinessUnit: state.businessUnit.insertBusinessUnit,
		insertBusinessUnitFailed: state.businessUnit.insertBusinessUnitFailed,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetBusinessUnitById,
			PutBusinessUnit,
			PostBusinessUnitData,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(sweetAlertStyle)
)(BusinessUnitForm);
