import React, { Fragment } from "react";
import * as _ from "lodash";
import Skeleton from "react-loading-skeleton";
import compose from "utils/compose";
import DocumentTitle from 'react-document-title';
import Alert from "react-bootstrap-sweetalert";
import Section from "components/Section/Section.js";

import { withStyles } from "@material-ui/core/styles";

import Close from "@material-ui/icons/Close";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import AttachmentList from "components/AttachmentList/AttachmentList.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

import styles from './home.module.scss';

// REDUX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GetBrokers, GetCommunications, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';
function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.modal = React.createRef();
        this.state = {
            open: false,
            brokersList: false,
            communicationsData: []
        }
    }

    componentDidMount() {
        if (_.isEmpty(this.props.lsBrokers)) {
            this.props.GetBrokers().then(() => {
                this.props.GetCommunications();
            });
        } else {
            this.props.GetCommunications();
            this.setState({ brokersList: this.props.lsBrokers });
        }

        if (!this.props.user) {
            this.props.history.push(`/auth/login`);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lsCommunications !== this.props.lsCommunications) {
            let comm = _.filter(this.props.lsCommunications, ['approved', true])
            let items = _.map(comm, item => {
                let obj = {
                    id: item.id,
                    title: item.title,
                    description: item.description,
                    author: item.author,
                    approved: item.approved,
                    created: item.created,
                    updated: item.updated,
                    approvedDate: item.approvedDate !== "0000-00-00 00:00:00" ? item.approvedDate : false
                }

                return obj;
            });
            // console.log("communicationsData: ", items);
            this.setState({ communicationsData: items });
        }

        if (prevProps.lsBrokers !== this.props.lsBrokers) {
            //  console.log("lsBrokers ", this.props.lsBrokers);
            this.setState({ brokersList: this.props.lsBrokers });
        }

        if (prevProps.brokersFailed !== this.props.brokersFailed) {
            console.log('Brokers Failed: ', this.props.brokersFailed);
            this.handleRequestFail("Erro ao consultar as corretoras");
        }

        if (prevProps.communicationsFailed !== this.props.communicationsFailed) {
            console.log('Communications Failed: ', this.props.communicationsFailed);
            this.handleRequestFail("Erro ao consultar os comunicados");
        }
    }

    handleRequestFail = (msg) => {
        this.props.HideLoader();
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={"Atenção!"}
                onConfirm={() => { this.props.HideAlert(); }}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                confirmBtnText={"OK"}
            >
                {msg}
            </Alert>
        )
    }

    handleView = (item) => {
        this.setState({ ...item });

        this.setState({ open: true }, () => {
            if (this.modal.current) {
                this.modal.current.innerHTML = item.description;
            }
        });
    }

    handleNew = () => {
        this.props.history.push(`/manageCommunications/new`);
    }
    //
    handleManage = (id) => {
        this.props.history.push(`/manageCommunications`);
    }
    handleClose = () => {
        this.setState({ open: false });
    }

    disabledModules = {
        toolbar: [
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    render() {
        const { t, classes } = this.props;
        const { communicationsData, brokersList, open } = this.state;

        return (
            <Fragment>
                <DocumentTitle title="PRx Consultoria - Comunicados" />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader style={{ padding: '28px', paddingBottom: '10px' }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={7}>
                                        <Section title="Comunicados" />
                                    </GridItem>
                                    {this.props.user.admin ?
                                        <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className={classes.marginRight}
                                                onClick={() => this.handleManage()}>
                                                Gerenciar
                                            </Button>
                                        </GridItem>
                                        :
                                        <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className={classes.marginRight}
                                                onClick={() => this.handleNew()}>
                                                Novo Comunicado
                                            </Button>
                                        </GridItem>
                                    }
                                </GridContainer>
                            </CardHeader>
                            <CardBody style={{ padding: '28px' }}>
                                {communicationsData && communicationsData.length > 0 ?
                                    communicationsData.map((item, index) => ([
                                        <div style={{ cursor: "pointer" }} title="Clique para ler" onClick={this.handleView.bind(this, item)} key={item.id}>
                                            <div className={styles.comunicadosData}>
                                                <span className={styles.comunicadosMes}>
                                                    {item.approvedDate ? moment(item.approvedDate).format("DD") : "-"}
                                                </span>
                                                <span className={styles.comunicadosDia}>
                                                    {item.approvedDate ? _.toUpper(moment(item.approvedDate).format('MMM')) : "-"}
                                                </span>
                                                <span className={styles.comunicadosAno}>
                                                    {item.approvedDate ? moment(item.approvedDate).get('year') : "-"}
                                                </span>
                                            </div>
                                            <div className={styles.body}>
                                                <div className={styles.titulo}>
                                                    <h4>{item.title}</h4>
                                                </div>
                                                <div className={styles.TextoCorpo}>
                                                    {
                                                        _.replace(
                                                            _.truncate(item.description, {
                                                                'length': 200,
                                                                'separator': /,? +/
                                                            }),
                                                            /<[^>]*>/g, ' ')
                                                    }
                                                </div>
                                                <div className={styles.post}><b>Postado por</b>: {_.find(brokersList, ['id', item.author]).name}</div>
                                            </div>
                                            <hr />
                                        </div>

                                    ]))
                                    : <h5>Nenhum registro encontrado</h5>
                                }
                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer >

                <Dialog
                    className={{
                        root: classes.center + " " + classes.modalRoot,
                        paper: classes.modal
                    }}
                    fullWidth={true}
                    maxWidth='md'
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose.bind(this)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-communication"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <Button
                            justIcon
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="transparent"
                            onClick={this.handleClose.bind(this)}
                        >
                            <Close className={classes.modalClose} />
                        </Button>
                        <h4 className={classes.modalTitle} >{this.state.title}</h4>
                    </DialogTitle>
                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classes.modalBody}
                    >
                        <div ref={this.modal}></div>
                    </DialogContent>
                    <DialogActions>
                        {this.state.id && <AttachmentList objId={this.state.id} editMode={false} />}
                        <div style={{ float: 'right', paddingTop: '20px', marginRight: "20px" }}>
                            {this.state.approvedDate !== "0000-00-00 00:00:00" ? moment(this.state.approvedDate).format("DD/MM/YYYY") : "-"}
                        </div>
                    </DialogActions>
                </Dialog>
            </Fragment >
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        lsCommunications: state.communications.lsCommunications,
        lsBrokers: state.brokers.lsBrokers,

        brokersFailed: state.brokers.brokersFailed,
        communicationsFailed: state.communications.communicationsFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetBrokers, GetCommunications, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedTablesStyle),
    withStyles(sweetAlertStyle),
    withStyles(modalStyle),
)(Home); 