import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
    lsProductions: {},
    productionItem: {},
    productionUpdate: {},
    insertProduction: {},
    productionDelete: {},
    productionsFailed: false,
    productionItemFailed: false,
    productionUpdateFailed: false,
    insertProductionFailed: false,
    productionDeleteFailed: false,
};

// get all
const getProductionData = (state, action) => {
    return updateObject(state, {
        lsProductions: action.lsProductions
    });
};

// get by id
const getProductionById = (state, action) => {
    return updateObject(state, {
        productionItem: action.productionItem
    });
};

// update
const updateProduction = (state, action) => {
    return updateObject(state, {
        productionUpdate: action.productionUpdate
    });
};

// update
const postProduction = (state, action) => {
    return updateObject(state, {
        insertProduction: action.insertProduction
    });
};

// delete
const deleteProduction = (state, action) => {
    return updateObject(state, {
        productionDelete: action.productionDelete
    });
};

// ++++++++++++++++ DATA FAIL ++++++++++++++++++++++++
const getProductionFailed = (state, action) => {
    return updateObject(state, {
        productionsFailed: action.productionsFailed ? action.productionsFailed.response.data : action.productionsFailed
    });
};

// get by id
const getProductionByIdFailed = (state, action) => {
    return updateObject(state, {
        productionItemFailed: action.productionItemFailed.response.data
    });
};

const updateProductionFailed = (state, action) => {
    return updateObject(state, {
        productionUpdateFailed: action.productionUpdateFailed.response.data
    });
};

const postProductionFailed = (state, action) => {
    return updateObject(state, {
        insertProductionFailed: action.insertProductionFailed.response.data
    });
};

const deleteProductionFailed = (state, action) => {
    return updateObject(state, {
        productionDeleteFailed: action.productionDeleteFailed.response.data
    });
};

//
const ProductionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCTIONS_DATA:
            return getProductionData(state, action);
        case actionTypes.GET_PRODUCTIONS_DATA_FAILED:
            return getProductionFailed(state, action);

        case actionTypes.GET_PRODUCTION_BY_ID:
            return getProductionById(state, action);
        case actionTypes.GET_PRODUCTION_BY_ID_FAILED:
            return getProductionByIdFailed(state, action);

        case actionTypes.POST_PRODUCTION_DATA:
            return postProduction(state, action);
        case actionTypes.POST_PRODUCTION_DATA_FAILED:
            return postProductionFailed(state, action);

        case actionTypes.PUT_PRODUCTION_DATA:
            return updateProduction(state, action);
        case actionTypes.PUT_PRODUCTION_FAILED:
            return updateProductionFailed(state, action);

        case actionTypes.DELETE_PRODUCTION_DATA:
            return deleteProduction(state, action);
        case actionTypes.DELETE_PRODUCTION_FAILED:
            return deleteProductionFailed(state, action);

        default:
            return state;
    }
};

export default ProductionReducer;