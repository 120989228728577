import React from "react";
import compose from 'utils/compose';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    sectionTitle: {
        fontSize: '18px',
        display: 'table',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        fontWeight: '600',
        fontFamily: 'Roboto',
        paddingBottom: '15px',
        color: '#3F51B5'
    }
};

const useStyles = makeStyles(styles);

export default function Section(props) {
    const classes = useStyles();
    return (
        <div className={classes.sectionTitle}>{props.title}</div>
    );
};
