import { authentication } from "./authenticate";

import brokers from "./brokers";
import communications from "./communications.js";
import businessUnit from "./businessUnit";
import partners from "./partners";
import productions from "./production";
import insurer from "./insurer";
import attachments from "./attachments";
import document from "./document";
// GERAL
// import preRegister from "./preRegister";
import images from "./images";
import filesUpload from "./filesUpload";
import sweetAlert from "./sweetAlert";
import loader from "./loader";
// import location from "./location";

import { users } from "./user";
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    authentication,
    users,
    brokers,
    communications,
    filesUpload,
    images,
    businessUnit,
    partners,
    attachments,
    document,
    insurer,
    productions,
    sweetAlert,
    loader
});

export default createRootReducer;