import axios from 'axios';

// @Get()
export const GetBusinessUnit = () => {
    return axios.get(`/businessUnit`)
        .then(response => response.data);
}

// @Get(':id') 
export const GetBusinessUnitById = (id) => {
    return axios.get(`/businessUnit/${id}`)
        .then(response => response.data);
}

// @Put(":id")
export const PutBusinessUnit = (id, obj) => {
    return axios.put(`/businessUnit/${id}`, obj)
        .then(response => response.data);
}

// @Post()
export const PostBusinessUnit = (obj) => {
    return axios.post(`/businessUnit/`, obj)
        .then(response => response.data);
}

// // @Delete(":id")
// export const DeleteBusinessUnit = (id) => {
//     console.log("DELETE: ", id);
//     return axios.delete(`/businessUnit/`, id)
//         .then(response => response.data);
// }