import React from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import * as moment from "moment";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from "react-document-title";
import compose from "utils/compose";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DoubleFormatCustom from "components/NumberFormatCustom/DoubleFormatCustom.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loader from "components/Loader/Loader.js";
import Section from "components/Section/Section.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import styles from "./styles.module.scss";

import {
	DeleteProduction,
	GetBusinessUnit,
	GetProductionById,
	GetProductions,
	HideAlert,
	HideLoader,
	PostProductionData,
	PutProduction,
	ShowLoader,
	SweetAlert,
} from "redux/actions";

const optionsMonth = [
	{ value: "1", label: "Jan" },
	{ value: "2", label: "Fev" },
	{ value: "3", label: "Mar" },
	{ value: "4", label: "Abr" },
	{ value: "5", label: "Mai" },
	{ value: "6", label: "Jun" },
	{ value: "7", label: "Jul" },
	{ value: "8", label: "Ago" },
	{ value: "9", label: "Set" },
	{ value: "10", label: "Out" },
	{ value: "11", label: "Nov" },
	{ value: "12", label: "Dez" },
];

const validationSchema = Yup.object({
	businessUnit: Yup.string().required(" obrigatório."),
	month: Yup.string().required(" obrigatório."),
	year: Yup.string().required(" obrigatório."),

	// insurer1: Yup.string().required(" obrigatório."),
	goal1: Yup.string().required(" obrigatório."),
	current1: Yup.string().required(" obrigatório."),

	// insurer2: Yup.string().required(" obrigatório."),
	goal2: Yup.string().required(" obrigatório."),
	current2: Yup.string().required(" obrigatório."),

	// insurer3: Yup.string().required(" obrigatório."),
	goal3: Yup.string().required(" obrigatório."),
	current3: Yup.string().required(" obrigatório."),

	// insurer4: Yup.string().required(" obrigatório."),
	// goal4: Yup.string().required(" obrigatório."),
	// current4: Yup.string().required(" obrigatório."),

	// insurer5: Yup.string().required(" obrigatório."),
	goal5: Yup.string().required(" obrigatório."),
	current5: Yup.string().required(" obrigatório."),

	// insurer6: Yup.string().required(" obrigatório."),
	goal6: Yup.string().required(" obrigatório."),
	current6: Yup.string().required(" obrigatório."),
});

const Form = (props) => {
	const {
		classes,
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleCancel,
		handleEdit,
		handleBack,
		insurer1,
		insurer2,
		insurer3,
		insurer5,
		insurer6,
		disabledForm,
		businessUnitList,
		setFieldTouched,
		setFieldValue,
		editMode,
	} = props;

	const change = (name, e) => {
		e.persist();
		handleChange(e);
		// setFieldValue(name, e.target.value);
		setFieldTouched(name, true, false);
	};

	const changeNumber = (name, e) => {
		if (editMode) {
			setFieldValue(name, e.target.value, true);
			setFieldTouched(name, true, false);
		}
		return;
	};

	return (
		<>
			<form>
				<GridContainer>
					{/* FORM CARD */}
					<GridItem xs={12} sm={4} md={12}>
						<Card>
							<CardHeader style={{ padding: "28px" }}>
								<GridContainer>
									<GridItem xs={12} sm={8} md={7}>
										<Section title="Produção" />
										{insurer5} - {insurer6} - {insurer1} -{" "}
										{insurer2} - {insurer3}
									</GridItem>
									{/* <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className={classes.marginRight}
                                        onClick={() => this.handleNew()}>Novo</Button>
                                </GridItem> */}
									<GridItem
										xs={12}
										sm={3}
										md={5}
										style={{ textAlign: "right" }}
									>
										{editMode && (
											<Button
												color="primary"
												size="sm"
												className={classes.marginRight}
												onClick={handleSubmit}
											>
												Salvar
											</Button>
										)}
										{editMode && (
											<Button
												color="info"
												size="sm"
												className={classes.marginRight}
												onClick={handleCancel}
											>
												Cancelar
											</Button>
										)}
										{disabledForm && (
											<>
												<Button
													color="primary"
													size="sm"
													className={
														classes.marginRight
													}
													onClick={handleEdit}
												>
													Editar
												</Button>
												<Button
													color="info"
													size="sm"
													className={
														classes.marginRight
													}
													onClick={handleBack}
												>
													Voltar
												</Button>
											</>
										)}
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody style={{ padding: "0 28px" }}>
								{/* L1 */}
								<GridContainer>
									{/* NOME */}
									<GridItem
										xs={12}
										sm={6}
										md={6}
										styles={{ marginTop: "15px" }}
									>
										<FormControl
											fullWidth
											className={
												classes.selectFormControl
											}
										>
											<InputLabel
												htmlFor="businessUnit"
												className={
													touched.businessUnit &&
													Boolean(errors.businessUnit)
														? styles.Error
														: classes.selectLabel
												}
											>
												Unidade de negócio
											</InputLabel>
											{!_.isEmpty(businessUnitList) && (
												<Select
													MenuProps={{
														className:
															classes.selectMenu,
													}}
													classes={{
														select: classes.select,
													}}
													id="businessUnit"
													name="businessUnit"
													value={values.businessUnit}
													disabled={disabledForm}
													inputProps={{
														onChange: change.bind(
															null,
															"businessUnit"
														),
													}}
												>
													{businessUnitList.map(
														(item) => (
															<MenuItem
																key={item.id}
																value={item.id}
																classes={{
																	root:
																		classes.selectMenuItem,
																	selected:
																		classes.selectMenuItemSelected,
																}}
															>
																{item.title}
															</MenuItem>
														)
													)}
												</Select>
											)}
										</FormControl>
									</GridItem>
									{/* Month */}
									<GridItem xs={12} sm={3} md={3}>
										<FormControl
											fullWidth
											className={
												classes.selectFormControl
											}
										>
											<InputLabel
												htmlFor="month"
												className={
													touched.month &&
													Boolean(errors.month)
														? styles.Error
														: classes.selectLabel
												}
											>
												Mês
											</InputLabel>
											<Select
												MenuProps={{
													className:
														classes.selectMenu,
												}}
												classes={{
													select: classes.select,
												}}
												id="month"
												name="month"
												value={values.month}
												disabled={disabledForm}
												inputProps={{
													onChange: change.bind(
														null,
														"month"
													),
												}}
											>
												{optionsMonth.map((item) => (
													<MenuItem
														key={item.value}
														value={item.value}
														classes={{
															root:
																classes.selectMenuItem,
															selected:
																classes.selectMenuItemSelected,
														}}
													>
														{item.label}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</GridItem>
									{/* ANO */}
									<GridItem xs={12} sm={3} md={3}>
										<CustomInput
											error={
												touched.year &&
												Boolean(errors.year)
											}
											labelText="Ano"
											id="year"
											name="year"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"year"
												),
												disabled: disabledForm,
												value: values.year,
												type: "number",
											}}
										/>
									</GridItem>
								</GridContainer>
								<hr />

								{/* AXA BRASIL */}
								<GridContainer>
									<GridItem xs={12} sm={4} md={6}>
										<CustomInput
											// error={touched.insurer5 && Boolean(errors.insurer5)}
											labelText="Seguradora"
											id="insurer5"
											name="insurer5"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												disabled: true,
												value: insurer5,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.current5 &&
												Boolean(errors.current5)
											}
											labelText="Realizado"
											id="current5"
											name="current5"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"current5"
												),
												disabled: disabledForm,
												value: values.current5,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.goal5 &&
												Boolean(errors.goal5)
											}
											labelText="Meta"
											id="goal5"
											name="goal5"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"goal5"
												),
												disabled: disabledForm,
												value: values.goal5,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
								</GridContainer>

								{/* CHUBB BRASIL */}
								<GridContainer>
									<GridItem xs={12} sm={4} md={6}>
										<CustomInput
											// error={touched.insurer6 && Boolean(errors.insurer6)}
											labelText="Seguradora"
											id="insurer6"
											name="insurer6"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												disabled: true,
												value: insurer6,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.current6 &&
												Boolean(errors.current6)
											}
											labelText="Realizado"
											id="current6"
											name="current6"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"current6"
												),
												disabled: disabledForm,
												value: values.current6,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.goal6 &&
												Boolean(errors.goal6)
											}
											labelText="Meta"
											id="goal6"
											name="goal6"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"goal6"
												),
												disabled: disabledForm,
												value: values.goal6,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
								</GridContainer>

								{/* L2 */}
								<GridContainer>
									{/* Seguradora */}
									<GridItem xs={12} sm={4} md={6}>
										<CustomInput
											// error={touched.insurer1 && Boolean(errors.insurer1)}
											labelText="Seguradora"
											id="insurer1"
											name="insurer1"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												disabled: true,
												value: insurer1,
											}}
										/>
									</GridItem>

									{/* Current */}
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.current1 &&
												Boolean(errors.current1)
											}
											labelText="Realizado"
											id="current1"
											name="current1"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"current1"
												),
												disabled: disabledForm,
												value: values.current1,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
									{/* GOAL */}
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.goal1 &&
												Boolean(errors.goal1)
											}
											labelText="Meta"
											id="goal1"
											name="goal1"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"goal1"
												),
												disabled: disabledForm,
												value: values.goal1,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
								</GridContainer>

								{/* L3 */}
								<GridContainer>
									{/* Seguradora */}
									<GridItem xs={12} sm={4} md={6}>
										<CustomInput
											// error={touched.insurer2 && Boolean(errors.insurer2)}
											labelText="Seguradora"
											id="insurer2"
											name="insurer2"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												disabled: true,
												value: insurer2,
											}}
										/>
									</GridItem>
									{/* Current */}
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.current2 &&
												Boolean(errors.current2)
											}
											labelText="Realizado"
											id="current2"
											name="current2"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"current2"
												),
												disabled: disabledForm,
												value: values.current2,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
									{/* GOAL */}
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.goal2 &&
												Boolean(errors.goal2)
											}
											labelText="Meta"
											id="goal2"
											name="goal2"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"goal2"
												),
												disabled: disabledForm,
												value: values.goal2,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
								</GridContainer>

								{/* L4 */}
								<GridContainer>
									{/* Seguradora */}
									<GridItem xs={12} sm={4} md={6}>
										<CustomInput
											// error={touched.insurer3 && Boolean(errors.insurer3)}
											labelText="Seguradora"
											id="insurer3"
											name="insurer3"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												disabled: true,
												value: insurer3,
											}}
										/>
									</GridItem>
									{/* Current */}
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.current3 &&
												Boolean(errors.current3)
											}
											labelText="Realizado"
											id="current3"
											name="current3"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"current3"
												),
												disabled: disabledForm,
												value: values.current3,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
									{/* GOAL */}
									<GridItem xs={12} sm={6} md={3}>
										<CustomInput
											error={
												touched.goal3 &&
												Boolean(errors.goal3)
											}
											labelText="Meta"
											id="goal3"
											name="goal3"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: changeNumber.bind(
													null,
													"goal3"
												),
												disabled: disabledForm,
												value: values.goal3,
												inputComponent: DoubleFormatCustom,
												startAdornment: (
													<InputAdornment position="start">
														R${" "}
													</InputAdornment>
												),
											}}
										/>
									</GridItem>
								</GridContainer>

								{/* MITSUI
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={6}>
                                        <CustomInput
                                            // error={touched.insurer4 && Boolean(errors.insurer4)}
                                            labelText="Seguradora"
                                            id="insurer4"
                                            name="insurer4"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: insurer4
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <CustomInput
                                            error={touched.current4 && Boolean(errors.current4)}
                                            labelText="Realizado"
                                            id="current4"
                                            name="current4"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: changeNumber.bind(null, "current4"),
                                                disabled: disabledForm,
                                                value: values.current4,
                                                inputComponent: DoubleFormatCustom,
                                                startAdornment: <InputAdornment position="start">R$ </InputAdornment>
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <CustomInput
                                            error={touched.goal4 && Boolean(errors.goal4)}
                                            labelText="Meta"
                                            id="goal4"
                                            name="goal4"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: changeNumber.bind(null, "goal4"),
                                                disabled: disabledForm,
                                                value: values.goal4,
                                                inputComponent: DoubleFormatCustom,
                                                startAdornment: <InputAdornment position="start">R$ </InputAdornment>
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                 */}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</form>
		</>
	);
};

class ProductionForm extends React.Component {
	constructor(props) {
		super(props);
		let formik = React.createRef();
		this.state = {
			id: "",
			businessUnit: "",
			month: "",
			year: "",
			insurer1: "HDI",
			goal1: "",
			current1: "",
			insurer2: "Liberty",
			goal2: "",
			current2: "",
			insurer3: "Sompo",
			goal3: "",
			current3: "",
			// insurer4: "Mitsui",
			// goal4: "",
			// current4: "",
			insurer5: "AXA Brasil",
			goal5: "",
			current5: "",
			insurer6: "Chubb Brasil",
			goal6: "",
			current6: "",

			created: "",
			updated: "",

			businessUnitList: [],

			editMode: false,
			disabledForm: false,
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		if (_.isEmpty(this.props.lsBusinessUnit)) {
			this.props.GetBusinessUnit();
		} else {
			this.setState({
				businessUnitList: _.orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
		}
		//
		const _new = _.includes(this.props.match.path, "new");
		const id = this.props.match && this.props.match.params.id;
		let _edit = _.includes(this.props.match.path, "edit");
		if (_new) {
			this.setState({ editMode: true, disabledForm: false });
			this.props.HideLoader();
		} else if (_edit) {
			this.setState({ editMode: true, disabledForm: false });
		} else {
			this.setState({ editMode: false, disabledForm: true });
		}
		//
		let _productionData = null;
		if (!_new) {
			if (!_.isEmpty(this.props.lsProductions) && id) {
				_productionData =
					this.props.lsProductions &&
					this.props.lsProductions.find((e) => e.id === id);
				//
				if (_productionData) {
					this.setState({
						businessUnit: _productionData.businessUnit,
						created: _productionData.created,
						current1: _productionData.current1,
						current2: _productionData.current2,
						current3: _productionData.current3,
						current4: _productionData.current4,
						current5: _productionData.current5,
						current6: _productionData.current6,
						date: _productionData.date,
						goal1: _productionData.goal1,
						goal2: _productionData.goal2,
						goal3: _productionData.goal3,
						goal4: _productionData.goal4,
						goal5: _productionData.goal5,
						goal6: _productionData.goal6,
						id: _productionData.id,
						month: _productionData.month,
						updated: _productionData.updated,
						year: _productionData.year,
					});
					this.props.HideLoader();
				}
			} else {
				this.props.GetProductionById(id);
			}
		}

		console.log("Insurer 5 -> ", this.state.insurer5);
		console.log("Insurer 6 -> ", this.state.insurer6);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.insurer5 !== this.state.insurer5) {
			console.log("insurer5 -> ", this.state.insurer5);
		}

		if (prevState.insurer6 !== this.state.insurer6) {
			console.log("insurer6 -> ", this.state.insurer6);
		}

		if (prevProps.productionItem !== this.props.productionItem) {
			// this.setState({ ...this.props.productionItem });
			this.setState({
				businessUnit: this.props.productionItem.businessUnit,
				created: this.props.productionItem.created,
				current1: this.props.productionItem.current1,
				current2: this.props.productionItem.current2,
				current3: this.props.productionItem.current3,
				current4: this.props.productionItem.current4,
				current5: this.props.productionItem.current5,
				current6: this.props.productionItem.current6,
				date: this.props.productionItem.date,
				goal1: this.props.productionItem.goal1,
				goal2: this.props.productionItem.goal2,
				goal3: this.props.productionItem.goal3,
				goal4: this.props.productionItem.goal4,
				goal5: this.props.productionItem.goal5,
				goal6: this.props.productionItem.goal6,
				id: this.props.productionItem.id,
				month: this.props.productionItem.month,
				updated: this.props.productionItem.updated,
				year: this.props.productionItem.year,
			});
			this.props.HideLoader();
		}

		if (prevProps.lsBusinessUnit !== this.props.lsBusinessUnit) {
			this.setState({
				businessUnitList: _.orderBy(
					this.props.lsBusinessUnit,
					["title"],
					["asc"]
				),
			});
			this.props.HideLoader();
		}

		if (prevProps.productionUpdate !== this.props.productionUpdate) {
			// console.log("productionUpdate: ", this.props.productionUpdate);
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Operação realizada com Sucesso!
				</Alert>
			);
			setTimeout(() => this.handleBack(), 3000);
		}
		if (prevProps.insertProduction !== this.props.insertProduction) {
			console.log("insert Production: ", this.props.insertProduction);
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Operação realizada com Sucesso!
				</Alert>
			);
			setTimeout(() => this.handleBack(), 3000);
		}
		// if (prevProps.productionDelete !== this.props.productionDelete) {
		//     console.log("production Delete: ", this.props.productionDelete);
		// }
		if (prevProps.businessUnitsFailed !== this.props.businessUnitsFailed) {
			console.log(
				"Business Units Failed: ",
				this.props.businessUnitsFailed
			);
			this.handleRequestFail("Erro ao consultar unidades de negócios");
		}

		if (prevProps.productionsFailed !== this.props.productionsFailed) {
			console.log("Productions Failed: ", this.props.productionsFailed);
			this.handleRequestFail("Erro ao consultar dados");
		}
		if (
			prevProps.productionItemFailed !== this.props.productionItemFailed
		) {
			console.log(
				"Production Item Failed: ",
				this.props.productionItemFailed
			);
			this.handleRequestFail("Erro ao consultar a informação");
		}
		if (
			prevProps.productionUpdateFailed !==
			this.props.productionUpdateFailed
		) {
			console.log(
				"Production Update Failed: ",
				this.props.productionUpdateFailed
			);
			this.handleRequestFail("Erro ao atualizar informação");
		}
		if (
			prevProps.insertProductionFailed !==
			this.props.insertProductionFailed
		) {
			console.log(
				"Insert Production Failed: ",
				this.props.insertProductionFailed
			);
			this.handleRequestFail("Erro ao inserir informação");
		}
		if (
			prevProps.productionDeleteFailed !==
			this.props.productionDeleteFailed
		) {
			console.log(
				"Production Delete Failed: ",
				this.props.productionDeleteFailed
			);
			this.handleRequestFail("Erro ao deletar informação");
		}
	}

	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => {
					this.props.HideAlert();
				}}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		);
	};

	handleSave = (values) => {
		this.props.ShowLoader(<Loader />);
		this.setState({ editMode: false, disabledForm: true });
		const _date =
			values.year +
			"-" +
			(values.month.toString().length == 1
				? "0" + values.month
				: values.month) +
			"-01";
		//
		const obj = {
			businessUnit: values.businessUnit,
			month: values.month,
			year: values.year,

			insurer1: this.state.insurer1,
			goal1: values.goal1,
			current1: values.current1,

			insurer2: this.state.insurer2,
			goal2: values.goal2,
			current2: values.current2,

			insurer3: this.state.insurer3,
			goal3: values.goal3,
			current3: values.current3,

			// insurer4: this.state.insurer4,
			// goal4: values.goal4,
			// current4: values.current4,

			insurer5: this.state.insurer5,
			goal5: values.goal5,
			current5: values.current5,

			insurer6: this.state.insurer6,
			goal6: values.goal6,
			current6: values.current6,

			date: moment(_date, "YYYY-MM-DD"),
		};
		//
		if (!this.state.id) {
			this.props.PostProductionData(obj);
		} else {
			// console.log("===> ", obj);
			this.props.PutProduction(this.state.id, obj);
		}
	};

	handleEdit() {
		this.props.history.push(`/production/edit/${this.state.id}`);
	}

	handleCancel() {
		this.props.HideLoader();
		this.props.HideAlert();
		this.props.history.push(`/productions`);
	}

	handleBack() {
		this.props.HideLoader();
		this.props.HideAlert();
		this.props.history.push(`/productions`);
	}

	handleChange = (event) => {
		const id = event.target.id || event.target.name;
		let value = event.target.value;
		//
		this.setState({ [id]: value });
	};

	render() {
		const { classes } = this.props;
		const {
			month,
			year,
			businessUnit,
			businessUnitList,
			editMode,
			disabledForm,
			insurer1,
			goal1,
			current1,
			insurer2,
			goal2,
			current2,
			insurer3,
			goal3,
			current3,
			insurer5,
			goal5,
			current5,
			insurer6,
			goal6,
			current6,
		} = this.state;

		const values = {
			businessUnit,
			month,
			year,
			goal1,
			current1,
			goal2,
			current2,
			goal3,
			current3,
			goal5,
			current5,
			goal6,
			current6,
		};

		console.log(insurer1, insurer2, insurer3, insurer5, insurer6);

		return (
			<>
				<DocumentTitle title={"PRx Consultoria - Produção"} />
				<Formik
					ref={this.formik}
					render={(props) => (
						<Form
							{...props}
							classes={classes}
							handleCancel={this.handleCancel.bind(this)}
							handleBack={this.handleBack.bind(this)}
							handleEdit={this.handleEdit.bind(this)}
							insurer1={insurer1}
							insurer2={insurer2}
							insurer3={insurer3}
							// insurer4={insurer4}
							insurer5={insurer5}
							insurer6={insurer6}
							businessUnitList={businessUnitList}
							disabledForm={disabledForm}
							editMode={editMode}
						/>
					)}
					validationSchema={validationSchema}
					onSubmit={this.handleSave.bind(this)}
					initialValues={values}
					enableReinitialize={true}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		lsProductions: state.productions.lsProductions,
		productionItem: state.productions.productionItem,
		productionUpdate: state.productions.productionUpdate,
		insertProduction: state.productions.insertProduction,
		productionDelete: state.productions.productionDelete,
		productionsFailed: state.productions.productionsFailed,
		productionItemFailed: state.productions.productionItemFailed,
		productionUpdateFailed: state.productions.productionUpdateFailed,
		insertProductionFailed: state.productions.insertProductionFailed,
		productionDeleteFailed: state.productions.productionDeleteFailed,

		lsBusinessUnit: state.businessUnit.lsBusinessUnit,
		businessUnitsFailed: state.businessUnit.businessUnitsFailed,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetBusinessUnit,
			GetProductions,
			GetProductionById,
			PutProduction,
			PostProductionData,
			DeleteProduction,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(extendedFormsStyle),
	withStyles(sweetAlertStyle)
)(ProductionForm);
