import React, { Fragment } from "react";
import * as _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { Formik } from "formik";
import compose from "utils/compose";
import DocumentTitle from 'react-document-title';
import Alert from "react-bootstrap-sweetalert";

// ReactQuill
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import CustomRequiredField from "components/CustomRequiredField/CustomRequiredField.jsx";
import Loader from "components/Loader/Loader";
import AttachmentList from "components/AttachmentList/AttachmentList.js";
import Section from "components/Section/Section.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { withStyles } from "@material-ui/core/styles";

// REDUX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    GetDocuments, GetDocumentById, PutDocument, PostDocumentData, DeleteDocument,
    PostAttachmentsData, PutAttachments, GetAttachmentsById, FilesUploadPost,
    SweetAlert, HideAlert, ShowLoader, HideLoader
} from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';

import styles from "./docForm.module.scss";

class DocumentForm extends React.Component {

    constructor(props) {
        super(props);
        let formik = React.createRef();
        this.state = {
            id: "",
            title: "",
            description: "",
            richTextValue: "",
            created: "",
            updated: "",
            author: "",

            editMode: false,
            disabledForm: false,

            filesCollection: [],
        }
        this.onChangeRTE = this.onChangeRTE.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);
        //
        if (!this.props.user) {
            this.props.history.push(`/auth/login`);
        }
        //
        let _new = _.includes(this.props.match.path, "new");
        let _edit = _.includes(this.props.match.path, "edit");
        if (_new) {
            this.setState({ editMode: _new, disabledForm: !_new });
            this.props.HideLoader();
        } else if (_edit) {
            this.setState({ editMode: _edit, disabledForm: !_edit });
        } else {
            this.setState({ editMode: false, disabledForm: true });
        }
        //
        const id = this.props.match && this.props.match.params.id;
        //-
        let _documentData = null;
        if (!_.isEmpty(this.props.lsDocuments) && id) {
            _documentData = this.props.lsDocuments && this.props.lsDocuments.find(e => e.id === id);
            if (_documentData) {
                // this.setState({ ..._documentData });
                this.setState({
                    ..._documentData,
                    richTextValue: _documentData.description
                });
                this.props.HideLoader();
            }
        } else {
            if (id) {
                this.setState({ id });
                this.props.GetDocumentById(id);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.documentItem !== this.props.documentItem) {
            this.setState({
                ...this.props.documentItem,
                created: moment(this.props.documentItem.created).format("DD/MM/YYYY"),
                richTextValue: this.props.documentItem.description
            });
            this.props.HideLoader();
        }
        if (prevProps.documentUpdate !== this.props.documentUpdate) {
            console.log("Document Update: ", this.props.documentUpdate);
            if (this.state.filesCollection.length > 0) {
                this.handleUploadFile();
            } else {
                this.props.SweetAlert(
                    <Alert
                        success
                        style={{ display: "block" }}
                        title={""}
                        onConfirm={() => { this.handleBack(); }}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText={"OK"}
                    >
                        Dados carregados com sucesso
                </Alert >
                )
                setTimeout(() => this.handleBack(), 3000);
            }
        }
        if (prevProps.insertDocument !== this.props.insertDocument) {
            if (this.state.filesCollection.length > 0) {
                this.setState({ ...this.props.insertDocument }, () => {
                    this.handleUploadFile();
                });
            } else {
                this.props.SweetAlert(
                    <Alert
                        success
                        style={{ display: "block" }}
                        title={""}
                        onConfirm={() => { this.handleBack(); }}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText={"OK"}
                    >
                        Comunicado inserido com sucesso!
                </Alert>
                )
                setTimeout(() => this.handleBack(), 3000);
            }
        }

        if (prevProps.insertAttachment !== this.props.insertAttachment) {
            console.log(">>> insertAttachment: ", this.props.insertAttachment);
            if (this.props.insertAttachment.length > 0) {
                this.props.history.push(`/documents`);
            }
        }
        //
        // FAILED
        if (prevProps.documentsFailed !== this.props.documentsFailed) {
            console.log("documentsFailed: ", this.props.documentsFailed);
            this.handleRequestFail("Erro ao consultar os itens");
        }
        if (prevProps.documentItemFailed !== this.props.documentItemFailed) {
            console.log("documentItemFailed: ", this.props.documentItemFailed);
            this.handleRequestFail("Erro ao consultar o item");
        }
        if (prevProps.documentUpdateFailed !== this.props.documentUpdateFailed) {
            console.log("documentUpdateFailed: ", this.props.documentUpdateFailed);
            this.handleRequestFail("Erro ao atualizar o item");
        }
        if (prevProps.insertDocumentFailed !== this.props.insertDocumentFailed) {
            console.log("insertDocumentFailed: ", this.props.insertDocumentFailed);
            this.handleRequestFail("Erro ao inserir o item");
        }
    }

    handleRequestFail = (msg) => {
        this.props.HideLoader();
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={"Atenção!"}
                onConfirm={() => { this.props.HideAlert(); }}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                confirmBtnText={"OK"}
            >
                {msg}
            </Alert>
        )
    }

    handleSave = () => {
        const { title, description } = this.state;
        //
        if (_.isEmpty(this.state.id)) {
            if (this.state.filesCollection.length == 0 || title.length == 0) {
                return false
            }
        }

        if (this.state.description.replace("<p><br></p>", "") !== "") {
            this.setState({ editMode: false, disabledForm: true });
            this.props.ShowLoader(<Loader />);
            //  
            if (!_.isEmpty(this.state.id)) {
                // EDIT
                let obj = {
                    title: title,
                    description: description,
                }
                this.props.PutDocument(this.state.id, obj);
            } else {
                // NEW
                let obj = {
                    title: title,
                    description: description,
                    author: this.props.user.id
                }
                this.props.PostDocumentData(obj);
            }
        }
    }

    handleUploadFile = () => {
        this.props.ShowLoader(<Loader />);
        this.props.FilesUploadPost(this.state.id, this.state.filesCollection).then(rest => {
            if (rest.status == 201) {
                this.props.PostAttachmentsData(this.props.filesObj);
            }
        });
    }

    onFileChange(e) {
        this.setState({ filesCollection: e.target.files })
    }

    onChangeRTE = (richTextValue) => {
        this.setState({ richTextValue, description: richTextValue.toString('html') });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChangeRTE(
                richTextValue.toString('html')
            );
        }
    };

    handleChange = (event) => {
        const id = event.target.id || event.target.name;
        let value = event.target.value;;
        //
        this.setState({ [id]: value });
    }

    handleBack = () => {
        this.props.HideAlert();
        // if (this.props.user.admin) {
        this.props.history.push(`/documents`);
        // }
    }

    handleCancel = () => {
        // if (this.props.user.admin) {
        this.props.history.push(`/documents`);
        // } else {
        //     this.props.history.push(`/communications`);
        // }
    }

    handleEdit = () => {
        this.props.history.push(`/document/edit/${this.state.id}`);
    }

    CancelForm = () => {
        this.setState({
            editMode: false, disabledForm: true,
            richTextValue: this.props.regulationData.regulation
        });
        this.props.HideLoader();
        this.props.HideAlert();
        this.props.history.goBack();
    }

    // QUILL TEXT EDITOR
    modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],       // toggled buttons
            ['blockquote', 'code-block'],                    // blocks
            [{ 'header': 1 }, { 'header': 2 }],              // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],    // lists
            [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
            ['link'],								// link
            [{ 'direction': 'rtl' }],                        // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],       // header dropdown
            [{ 'color': [] }, { 'background': [] }],         // dropdown with defaults
            [{ 'font': [] }],                                // font family
            [{ 'align': [] }],                               // text align
            ['clean'],                                       // remove formatting
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    disabledModules = {
        toolbar: [
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    formats = [
        'header', 'font', 'background', 'color', 'code', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'script', 'align', 'direction',
        'link', 'code-block', 'formula', 'video'
    ]

    render() {
        const { classes } = this.props;
        const { editMode, disabledForm, id, title, description, richTextValue } = this.state;
        return (
            <Fragment>
                <DocumentTitle title={"PRx Consultoria - Editar Documento"} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody style={{ padding: "28px" }}>
                                <CardHeader style={{ padding: '0', paddingBottom: '10px' }}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={8} md={7}>
                                            <Section title={editMode ? "Novo documento" : "Editar documento"} />
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                            {editMode &&
                                                <Button
                                                    size="sm"
                                                    variant="contained"
                                                    color="primary"
                                                    // disabled={this.state.filesCollection.length == 0 || title.length == 0}
                                                    onClick={this.handleSave}>
                                                    Salvar
                                                </Button>}
                                            {" "}
                                            {editMode &&
                                                <Button
                                                    color="info"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={this.handleBack}
                                                >Cancelar</Button>
                                            }
                                            {disabledForm && this.props.user.admin &&
                                                <Fragment>
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className={classes.marginRight}
                                                        onClick={this.handleEdit.bind(this)}
                                                    >Editar
                                                    </Button>
                                                    <Button
                                                        color="info"
                                                        size="sm"
                                                        className={classes.marginRight}
                                                        onClick={this.handleBack.bind(this)}
                                                    >Voltar
                                                    </Button>
                                                </Fragment>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>


                                <CardBody style={{ padding: '0px' }}>
                                    <GridContainer>
                                        {/* TITLE */}
                                        <GridItem xs={12} md={12} lg={12}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelText={<CustomRequiredField value={"Titulo"} />}
                                                id="title"
                                                name="title"
                                                inputProps={{
                                                    value: title,
                                                    onChange: this.handleChange,
                                                    type: "text",
                                                    disabled: disabledForm
                                                }}
                                            />
                                        </GridItem>
                                        {/* DESCRIPTION */}
                                        <GridItem xs={12}>
                                            <ReactQuill
                                                theme={disabledForm ? "bubble" : "snow"}
                                                value={richTextValue}
                                                modules={disabledForm ? this.disabledModules : this.modules}
                                                formats={this.formats}
                                                onChange={value => this.onChangeRTE(value)}
                                                readOnly={disabledForm}
                                            />
                                        </GridItem>
                                        <hr />
                                        {editMode && <GridItem xs={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                            {<CustomRequiredField value="Anexar documentos" />}<br />
                                            <input
                                                style={{ paddingTop: "10px" }}
                                                type="file"
                                                name="filesCollection"
                                                onChange={this.onFileChange}
                                                multiple
                                                accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf" />
                                        </GridItem>}
                                        {/*  */}
                                        {id && <AttachmentList objId={id} editMode={editMode} />}
                                    </GridContainer>
                                </CardBody>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer >
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        filesObj: state.filesUpload.filesObj,

        lsDocuments: state.document.lsDocuments,
        documentsFailed: state.document.documentsFailed,
        documentItem: state.document.documentItem,
        documentItemFailed: state.document.documentItemFailed,
        documentUpdate: state.document.documentUpdate,
        documentUpdateFailed: state.document.documentUpdateFailed,
        insertDocument: state.document.insertDocument,
        insertDocumentFailed: state.document.insertDocumentFailed,
        documentDelete: state.document.documentDelete,
        documentDeleteFailed: state.document.documentDeleteFailed,

        insertAttachment: state.attachments.insertAttachment,
        insertAttachmentFailed: state.attachments.insertAttachmentFailed,
        // attachmentDelete: state.attachments.attachmentDelete,
        // attachmentDeleteFailed: state.attachments.attachmentDeleteFailed
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        GetDocuments, GetDocumentById, PutDocument, PostDocumentData, DeleteDocument,
        PostAttachmentsData, PutAttachments, GetAttachmentsById, FilesUploadPost,
        SweetAlert, HideAlert, ShowLoader, HideLoader
    }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(regularFormsStyle),
    withStyles(sweetAlertStyle),
)(DocumentForm); 