import React from "react";
import { isEmpty, orderBy, filter, find } from "lodash";
import compose from "utils/compose";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from "react-document-title";
import Section from "components/Section/Section.js";
import Loader from "components/Loader/Loader.js";
import cx from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Eyes from "@material-ui/icons/RemoveRedEye";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import VpnKey from "@material-ui/icons/VpnKey";

//
// core components
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// REDUX INIT
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	GetBusinessUnit,
	GetBrokers,
	ResetPassword,
	GetBrokerById,
	PutBroker,
	PostBrokerData,
	DeleteBroker,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from "redux/actions";

class Brokers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			brokersData: false,
			brokerItem: [],
			businessUnitList: [],
			moduleTitle: "",
			chancellor: false,
			businessUnit: "",
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		let _moduleTitle = "";
		if (this.props.match.path.indexOf("brokers") > -1) {
			_moduleTitle = "Corretoras";
		} else {
			_moduleTitle = "Corretoras chanceladas";
			this.setState({ chancellor: true });
		}
		this.setState({ moduleTitle: _moduleTitle });

		if (isEmpty(this.props.lsBusinessUnit)) {
			this.props.GetBusinessUnit();
		} else {
			this.setState({
				businessUnitList: orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
			this.props.GetBrokers();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lsBrokers !== this.props.lsBrokers) {
			this.props.HideLoader();
			const brokerByName = orderBy(
				this.props.lsBrokers,
				["name"],
				["asc"]
			);
			//
			if (this.state.chancellor) {
				this.setState({
					brokersData: filter(brokerByName, ["chancellors", true]),
				});
			} else {
				this.setState({
					brokersData: filter(brokerByName, ["chancellors", false]),
				});
			}
		}

		if (prevProps.lsBusinessUnit !== this.props.lsBusinessUnit) {
			this.setState({
				businessUnitList: orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
			this.props.HideLoader();
			this.props.GetBrokers();
		}

		if (prevProps.brokerItem !== this.props.brokerItem) {
			// console.log("brokerItem: ", this.props.brokerItem);
		}
		if (prevProps.brokerUpdate !== this.props.brokerUpdate) {
			//console.log("brokerUpdate: ", this.props.brokerUpdate);
		}
		if (prevProps.insertBroker !== this.props.insertBroker) {
			//console.log("insertBroker: ", this.props.insertBroker);
		}
		if (prevProps.brokerDelete !== this.props.brokerDelete) {
			//console.log("brokerDelete: ", this.props.brokerDelete);
		}

		if (prevProps.resetPassword !== this.props.resetPassword) {
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.props.HideAlert();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Senha resetada com sucesso.
				</Alert>
			);
		}
		//
		// FAILED
		if (prevProps.resetPasswordFailed !== this.props.resetPasswordFailed) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: "block" }}
					title={"Atenção!"}
					onConfirm={() => {
						this.props.HideAlert();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Erro ao resetar senha.
				</Alert>
			);
		}

		if (prevProps.brokersFailed !== this.props.brokersFailed) {
			console.log("Brokers Failed: ", this.props.brokersFailed);
			this.handleRequestFail("Erro ao consultar as corretoras");
		}
		if (prevProps.businessUnitsFailed !== this.props.businessUnitsFailed) {
			console.log(
				"BusinessUnit Failed: ",
				this.props.businessUnitsFailed
			);
			this.handleRequestFail("Erro ao consultar as unidades de negócios");
		}
		if (prevProps.brokerItemFailed !== this.props.brokerItemFailed) {
			console.log("brokerItemFailed: ", this.props.brokerItemFailed);
			this.handleRequestFail("Erro ao consultar a corretora");
		}
		if (prevProps.brokerUpdateFailed !== this.props.brokerUpdateFailed) {
			console.log("brokerUpdateFailed: ", this.props.brokerUpdateFailed);
			this.handleRequestFail("Erro ao atualziar a corretora");
		}
		if (prevProps.insertBrokerFailed !== this.props.insertBrokerFailed) {
			console.log("insertBrokerFailed: ", this.props.insertBrokerFailed);
			this.handleRequestFail("Erro ao inserir a corretora");
		}
		if (prevProps.brokerDeleteFailed !== this.props.brokerDeleteFailed) {
			console.log("brokerDeleteFailed: ", this.props.brokerDeleteFailed);
			this.handleRequestFail("Erro ao deletar a corretora");
		}
	}

	// handleTypeOfPoints = (newValue) => {
	// 	this.setState({
	// 	  searchTypeOfPoints: newValue.value,
	// 	  selectedTypeOfPoints: newValue,
	// 	});
	//   };
	handleChange = (event) => {
		const id = event.target.id || event.target.name;
		let value = event.target.value;
		this.setState({ [id]: value });
	};

	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => {
					this.props.HideAlert();
				}}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		);
	};

	handleSearch = () => {
		console.log("-> ", this.state.businessUnit);
		//
		if (!isEmpty(this.state.businessUnit)) {
			const data = filter(this.state.brokersData, [
				"businessUnit",
				this.state.businessUnit,
			]);
			this.setState({ brokersData: data });
		}
	};
	//
	handleClear = () => {
		this.setState({ businessUnit: "" });
		this.setState({ brokersData: this.props.lsBrokers });
	};
	//
	handleNew = () => {
		if (this.state.chancellor) {
			this.props.history.push(`/chancellor/new`);
		} else {
			this.props.history.push(`/broker/new`);
		}
	};
	//
	handleView = (id) => {
		if (this.state.chancellor) {
			this.props.history.push(`/chancellor/${id}`);
		} else {
			this.props.history.push(`/broker/${id}`);
		}
	};
	//
	handleEdit = (id) => {
		if (this.state.chancellor) {
			this.props.history.push(`/chancellor/edit/${id}`);
		} else {
			this.props.history.push(`/broker/edit/${id}`);
		}
	};
	//
	handleResetPWD = (id) => {
		if (id) {
			this.props.ResetPassword(id);
		}
	};
	//
	askReset = (id) => {
		this.setState({ deleteItemId: id });
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={""}
				onConfirm={this.handleResetPWD.bind(this, id)}
				onCancel={() => this.props.HideAlert()}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				cancelBtnCssClass={
					this.props.classes.button + " " + this.props.classes.info
				}
				confirmBtnText="Sim"
				cancelBtnText="Não"
				showCancel
			>
				Você tem certeza que deseja resetar a senha deste usuário?
			</Alert>
		);
	};
	//
	render() {
		const { classes } = this.props;
		const { brokersData, businessUnitList, moduleTitle } = this.state;

		return (
			<>
				<DocumentTitle title={`PRx Consultoria - ${moduleTitle}`} />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<CardHeader
								style={{
									padding: "28px",
									paddingBottom: "10px",
								}}
							>
								<GridContainer>
									<GridItem xs={12} sm={8} md={7}>
										<Section
											title={`Lista de ${moduleTitle}`}
										/>
									</GridItem>
									<GridItem
										xs={12}
										sm={3}
										md={5}
										style={{ textAlign: "right" }}
									>
										<Button
											color="primary"
											size="sm"
											className={classes.marginRight}
											onClick={() => this.handleNew()}
										>
											Novo
										</Button>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody style={{ padding: "28px" }}>
								<>
									<GridItem
										md={12}
										style={{ marginTop: "0px" }}
									>
										{businessUnitList.length > 0 && (
											<>
												<div
													style={{
														fontWeight: "bold",
													}}
												>
													Filtrar por unidade de
													negócio
												</div>
												<div
													style={{
														marginBottom: "30px",
													}}
												>
													<FormControl
														fullWidth
														className={cx(
															classes.selectFormControl
														)}
													>
														<div
															style={{
																width: "50%",
																display: "flex",
																flexDirection:
																	"row",
																justifyContent:
																	"space-between",
															}}
														>
															<Select
																style={{
																	marginRight:
																		"8px",
																	flexGrow:
																		"2",
																}}
																MenuProps={{
																	className:
																		classes.selectMenu,
																}}
																classes={{
																	select:
																		classes.select,
																}}
																id="businessUnit"
																name="businessUnit"
																inputProps={{
																	name:
																		"businessUnit",
																	id:
																		"businessUnit",
																	onChange: this
																		.handleChange,
																	value: this
																		.state
																		.businessUnit,
																}}
															>
																{businessUnitList.map(
																	(item) => (
																		<MenuItem
																			key={
																				item.id
																			}
																			value={
																				item.id
																			}
																			classes={{
																				root:
																					classes.selectMenuItem,
																				selected:
																					classes.selectMenuItemSelected,
																			}}
																		>
																			{
																				item.title
																			}
																		</MenuItem>
																	)
																)}
															</Select>
															<Button
																justIcon
																size="sm"
																title="Filtrar"
																color="primary"
																onClick={
																	this
																		.handleSearch
																}
																style={{
																	marginRight:
																		"8px",
																}}
															>
																<Search
																	className={
																		classes.icons
																	}
																/>
															</Button>
															<Button
																justIcon
																size="sm"
																title="Limpar"
																color="primary"
																onClick={
																	this
																		.handleClear
																}
															>
																<Clear
																	className={
																		classes.icons
																	}
																/>
															</Button>
														</div>
													</FormControl>
												</div>
											</>
										)}
									</GridItem>
									{brokersData && brokersData.length > 0 ? (
										<>
											{/*  */}
											<Table
												striped
												tableHead={[
													"Nome",
													"E-mail",
													"Unidade de negócio",
												]}
												tableData={brokersData.map(
													(item) => [
														item.name,
														item.email,
														find(businessUnitList, [
															"id",
															item.businessUnit,
														]).title,
														<>
															<Button
																justIcon
																size="sm"
																value={item.id}
																title="Visualizar"
																color="primary"
																onClick={this.handleView.bind(
																	this,
																	item.id
																)}
															>
																<Eyes
																	className={
																		classes.icons
																	}
																/>
															</Button>
															<Button
																justIcon
																size="sm"
																value={item.id}
																title="Editar"
																color="primary"
																onClick={this.handleEdit.bind(
																	this,
																	item.id
																)}
															>
																<Edit
																	className={
																		classes.icons
																	}
																/>
															</Button>
															<Button
																justIcon
																size="sm"
																value={item.id}
																title="Resetar Senha"
																color="info"
																onClick={this.askReset.bind(
																	this,
																	item.id
																)}
															>
																<VpnKey
																	className={
																		classes.icons
																	}
																/>
															</Button>
														</>,
													]
												)}
												customCellClasses={[
													classes.center,
													classes.right,
													classes.right,
												]}
												customClassesForCells={[
													0,
													4,
													5,
												]}
												customHeadCellClasses={[
													classes.center,
													classes.right,
													classes.right,
												]}
												customHeadClassesForCells={[
													0,
													4,
													5,
												]}
											/>
										</>
									) : (
										<h5>Nenhum registro encontrado</h5>
									)}
								</>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,

		lsBrokers: state.brokers.lsBrokers,
		brokerItem: state.brokers.brokerItem,
		brokerUpdate: state.brokers.brokerUpdate,
		insertBroker: state.brokers.insertBroker,
		brokerDelete: state.brokers.brokerDelete,
		resetPassword: state.brokers.resetPassword,

		resetPasswordFailed: state.brokers.resetPasswordFailed,
		brokersFailed: state.brokers.brokersFailed,
		brokerItemFailed: state.brokers.brokerItemFailed,
		brokerUpdateFailed: state.brokers.brokerUpdateFailed,
		insertBrokerFailed: state.brokers.insertBrokerFailed,
		brokerDeleteFailed: state.brokers.brokerDeleteFailed,

		lsBusinessUnit: state.businessUnit.lsBusinessUnit,
		businessUnitsFailed: state.businessUnit.businessUnitsFailed,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetBusinessUnit,
			GetBrokers,
			ResetPassword,
			GetBrokerById,
			PutBroker,
			PostBrokerData,
			DeleteBroker,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(extendedTablesStyle),
	withStyles(sweetAlertStyle)
	//withTranslation(),
)(Brokers);
