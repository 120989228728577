import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
    lsDocuments: {},
    documentItem: {},
    documentUpdate: {},
    insertDocument: {},
    documentDelete: {},
    documentsFailed: false,
    documentItemFailed: false,
    documentUpdateFailed: false,
    insertDocumentFailed: false,
    documentDeleteFailed: false,
};

// get all
const getDocumentData = (state, action) => {
    return updateObject(state, {
        lsDocuments: action.lsDocuments
    });
};

// get by id
const getDocumentById = (state, action) => {
    return updateObject(state, {
        documentItem: action.documentItem
    });
};

// update
const updateDocument = (state, action) => {
    return updateObject(state, {
        documentUpdate: action.documentUpdate
    });
};

// update
const postDocument = (state, action) => {
    return updateObject(state, {
        insertDocument: action.insertDocument
    });
};

// delete
const deleteDocument = (state, action) => {
    return updateObject(state, {
        documentDelete: action.documentDelete
    });
};

// ++++++++++++++++ DATA FAIL ++++++++++++++++++++++++
const getDocumentFailed = (state, action) => {
    return updateObject(state, {
        documentsFailed: action.documentsFailed
    });
};

// get by id
const getDocumentByIdFailed = (state, action) => {
    return updateObject(state, {
        documentItemFailed: action.documentItemFailed
    });
};

const updateDocumentFailed = (state, action) => {
    return updateObject(state, {
        documentUpdateFailed: action.documentUpdateFailed
    });
};

const postDocumentFailed = (state, action) => {
    return updateObject(state, {
        insertDocumentFailed: action.insertDocumentFailed
    });
};

const deleteDocumentFailed = (state, action) => {
    return updateObject(state, {
        documentDeleteFailed: action.documentDeleteFailed
    });
};

//
const DocumentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DOCUMENTS_DATA:
            return getDocumentData(state, action);
        case actionTypes.GET_DOCUMENTS_DATA_FAILED:
            return getDocumentFailed(state, action);

        case actionTypes.GET_DOCUMENT_BY_ID:
            return getDocumentById(state, action);
        case actionTypes.GET_DOCUMENT_BY_ID_FAILED:
            return getDocumentByIdFailed(state, action);

        case actionTypes.POST_DOCUMENT_DATA:
            return postDocument(state, action);
        case actionTypes.POST_DOCUMENT_DATA_FAILED:
            return postDocumentFailed(state, action);

        case actionTypes.PUT_DOCUMENT_DATA:
            return updateDocument(state, action);
        case actionTypes.PUT_DOCUMENT_FAILED:
            return updateDocumentFailed(state, action);

        case actionTypes.DELETE_DOCUMENT_DATA:
            return deleteDocument(state, action);
        case actionTypes.DELETE_DOCUMENT_FAILED:
            return deleteDocumentFailed(state, action);

        default:
            return state;
    }
};

export default DocumentReducer;