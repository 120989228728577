import axios from 'axios';

// POST IMAGE
export const FilesUpload = (obj) => {
    let formData = new FormData();
    for (const key of Object.keys(obj)) {
        let file = obj[key];
        let newFileName = Date.now().toString() + "_" + file.name;
        formData.append('file', file, newFileName);
    }
    return axios.post(`/fileUpload/`, formData)
        .then(response => response.status);
    // axios.post(`/fileUpload`, formData).then(function (res) {
    //     console.log('FILES UPLOAD - SUCCESS! -> ', res);
    //     return res;
    // }).catch(function (error) {
    //     console.log('FILES UPLOAD - FAILURE! -> ', error);
    //     return error;
    // });
}

// GET IMAGE
export const GetFiles = (id) =>
    axios.get(`/fileUpload/${id}`)
        .then(response => response.data);
        
// DELETE IMAGE
export const DeleteObject = (name) =>
    axios.delete(`/fileUpload/${name}`)
        .then(response => response.data);