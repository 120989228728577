// import config from 'config';
import axios from "axios";
import * as _ from "lodash";

// import {
//     authHeader
// } from "../../utils";
import jwt_decode from "jwt-decode";

export const userService = {
    login,
    logout,
    // getAll,
    // changePassword,
    GetUserById,
    ListUsers,
    CreateNewUser,
    UpdateUser,
    UpdateUserProfile
};

function login(email, password) {
    const authData = {
        email,
        password,
        // client_id: "ro.client.partner",
        client_secret: "bBT850DxfpzrIW",
        // grant_type: "password",
        // scope: "germini-api openid profile"
    };

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams(authData)
    };
    return fetch(
        `https://prxconsultoriabackend.herokuapp.com/login`,
        requestOptions
    ).then(handleResponse).then(obj => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (!obj.user.isActive) {
            alert(`Este usuário "${obj.user.name}" está desativado. Para maiores informações contacte a PRx Consultoria.`);
            logout();
            return false;
        }
        localStorage.setItem("token", obj.token);
        localStorage.setItem("user", JSON.stringify(obj.user));

        axios.defaults.headers.common["Authorization"] = "Bearer " + obj.token;
        return obj.user;
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("i18next_pt-BR-common");
    localStorage.removeItem("i18next_es-common");
    localStorage.removeItem("i18next_en-common");
}

// function changePassword(id, obj) {
//     const requestOptions = {
//         method: "PUT",
//         headers: { "Content-Type": "application/json-patch+json" },
//         Accept: "application/json",
//         body: `{ "oldPassword": '${obj.oldPassword}',
//             "password": '${obj.password}'}`
//     };
//     return fetch(
//         `${process.env.REACT_APP_PRX_API_URL}changePassword/${id}`,
//         requestOptions
//     ).then(response => {
//         if (response.status === 200)
//             return response.text().then(text => {
//                 const data = text && JSON.parse(text);
//                 return data;
//             });
//         else
//             return response.text().then(text => {
//                 const error = text && JSON.parse(text);
//                 return Promise.reject(error);
//             });
//     });
// }

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        const user = {
            user: jwt_decode(data.token),
            token: data.token
        };
        return user;
    });
}

function GetUserById(id) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json-patch+json" },
        Accept: "application/json"
    };

    return fetch(
        `${process.env.REACT_APP_PRX_API_URL}/api/Account/GetUserById/${id}`,
        requestOptions
    ).then(response => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            return data;
        });
    });
}

function ListUsers(name, email, isActive, cpf, page) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json-patch+json" },
        Accept: "application/json"
    };

    return fetch(
        `${process.env.REACT_APP_PRX_API_URL}/api/Account/ListUsers?name=${name}&email=${email}&isActive=${isActive}&CPF=${cpf}&page=${page}`,
        requestOptions
    ).then(response => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            return data;
        });
    });
}

function CreateNewUser(id, obj) {
    var strGroups = "";

    if (obj.userGroups) {
        let strArray = [];
        obj.userGroups.map(item => {
            strArray.push(`{ userId: '', groupId: '${item}' }`);
        });
        strGroups = "userGroups: [" + strArray.join(", ") + "],";
    }

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json-patch+json" },
        Accept: "application/json",
        body: `{ userName: '${obj.userName}', ${strGroups} name: '${obj.name}', email: '${obj.email}',
            isActive: '${obj.isActive}'}`
    };

    return fetch(
        `${process.env.REACT_APP_PRX_API_URL}/Partner/registernewuser/${id}`,
        requestOptions
    ).then(response => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                const error = data;
                return Promise.reject(error);
            }

            return data;
        });
    });
}

function UpdateUser(id, obj) {
    var strGroups = "";
    if (obj.userGroups) {
        let strArray = [];
        strGroups = "userGroups: [";
        obj.userGroups.map(item => {
            strArray.push(`{ userId: '', groupId: '${item}' }`);
        });
        strGroups = strGroups + strArray.join(", ") + "],";
    }

    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json-patch+json" },
        Accept: "application/json",
        body: `{ userName: '${obj.userName}', cpf: '${obj.cpf}', ${strGroups} name: '${obj.name}', email: '${obj.email}',
            isActive: '${obj.isActive}'}`
    };

    return fetch(
        `${process.env.REACT_APP_PRX_API_URL}/api/Account/UpdateUser/${id}`,
        requestOptions
    ).then(response => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                const error = (data ? data : response.statusText);
                return Promise.reject(error);
            }

            return data;
        });
    });
}

function UpdateUserProfile(id, obj) {
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json-patch+json" },
        Accept: "application/json",
        body: `{ name: '${obj.given_name}', email: '${obj.email}',
            image: '${obj.picture}'}`
    };

    return fetch(
        `${process.env.REACT_APP_PRX_API_URL}/api/Account/${id}`,
        requestOptions
    ).then(response => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            const user =
                localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user"));

            if (!response.ok) {
                if (response.status === 401 || !user) {
                    // auto logout if 401 response returned from api
                    logout();
                    // this.location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            //console.log(user)
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('token', user.token);
            user.given_name = data.name;
            user.email = data.email;
            user.picture = data.image;

            localStorage.setItem("user", JSON.stringify(user));

            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            return data;
        });
    });
}
