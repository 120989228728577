import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
    lsAttachments: [],
    attachmentItem: [],
    attachmentUpdate: [],
    insertAttachment: [],
    attachmentDelete: [],
    attachmentsPage: [],

    attachmentsFailed: false,
    attachmentItemFailed: false,
    attachmentUpdateFailed: false,
    insertAttachmentFailed: false,
    attachmentDeleteFailed: false,
    attachmentsPageFailed: false,
};

// get all
const getAttachmentsData = (state, action) => {
    return updateObject(state, {
        lsAttachments: action.lsAttachments
    });
};

// get by id
const getAttachmentById = (state, action) => {
    return updateObject(state, {
        attachmentItem: action.attachmentItem
    });
};

// get by page
const getAttachmentByPage = (state, action) => {
    return updateObject(state, {
        attachmentsPage: action.attachmentsPage
    });
};

// update
const updateAttachment = (state, action) => {
    return updateObject(state, {
        attachmentUpdate: action.attachmentUpdate
    });
};

// update
const postAttachment = (state, action) => {
    return updateObject(state, {
        insertAttachment: action.insertAttachment
    });
};

// delete
const deleteAttachment = (state, action) => {
    return updateObject(state, {
        attachmentDelete: action.attachmentDelete
    });
};

// ++++++++++++++++ DATA FAIL ++++++++++++++++++++++++
const getAttachmentsFailed = (state, action) => {
    return updateObject(state, {
        attachmentsFailed: action.attachmentsFailed
    });
};

// get by id
const getAttachmentByIdFailed = (state, action) => {
    return updateObject(state, {
        attachmentItemFailed: action.attachmentItemFailed
    });
};

// get by page
const getAttachmentByPageFailed = (state, action) => {
    return updateObject(state, {
        attachmentsPageFailed: action.attachmentsPageFailed
    });
};

const updateAttachmentFailed = (state, action) => {
    return updateObject(state, {
        attachmentUpdateFailed: action.attachmentUpdateFailed
    });
};

const postAttachmentFailed = (state, action) => {
    return updateObject(state, {
        insertAttachmentFailed: action.insertAttachmentFailed
    });
};

const deleteAttachmentFailed = (state, action) => {
    return updateObject(state, {
        attachmentDeleteFailed: action.attachmentDeleteFailed
    });
};

//
const AttachmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ATTACHMENTS_DATA:
            return getAttachmentsData(state, action);
        case actionTypes.GET_ATTACHMENTS_DATA_FAILED:
            return getAttachmentsFailed(state, action);

        case actionTypes.GET_ATTACHMENT_BY_ID:
            return getAttachmentById(state, action);
        case actionTypes.GET_ATTACHMENT_BY_ID_FAILED:
            return getAttachmentByIdFailed(state, action);

        case actionTypes.GET_ATTACHMENT_BY_ID:
            return getAttachmentById(state, action);
        case actionTypes.GET_ATTACHMENT_BY_ID_FAILED:
            return getAttachmentByIdFailed(state, action);

        case actionTypes.GET_ATTACHMENT_BY_PAGE:
            return getAttachmentByPage(state, action);
        case actionTypes.GET_ATTACHMENT_BY_PAGE_FAILED:
            return getAttachmentByPageFailed(state, action);

        case actionTypes.POST_ATTACHMENT_DATA:
            return postAttachment(state, action);
        case actionTypes.POST_ATTACHMENT_DATA_FAILED:
            return postAttachmentFailed(state, action);

        case actionTypes.PUT_ATTACHMENT_DATA:
            return updateAttachment(state, action);
        case actionTypes.PUT_ATTACHMENT_FAILED:
            return updateAttachmentFailed(state, action);

        case actionTypes.DELETE_ATTACHMENT_DATA:
            return deleteAttachment(state, action);
        case actionTypes.DELETE_ATTACHMENT_DATA_FAILED:
            return deleteAttachmentFailed(state, action);

        default:
            return state;
    }
};

export default AttachmentReducer;