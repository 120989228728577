import React, { Fragment } from "react";
import * as _ from "lodash";
import Skeleton from "react-loading-skeleton";
import MaskedInput from 'react-text-mask';
import compose from "utils/compose";
import DocumentTitle from 'react-document-title';
import Alert from "react-bootstrap-sweetalert";

// ReactQuill
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import AttachmentList from "components/AttachmentList/AttachmentList.js";
import Loader from "components/Loader/Loader";
import Section from "components/Section/Section.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { withStyles } from "@material-ui/core/styles";

// REDUX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PostAttachmentsData, DeleteAttachments, FilesUploadPost, GetFiles, GetInsurers, GetInsurerById, PutInsurer, PostInsurerData, DeleteInsurer, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions';

const toolbarConfig = {
	// Optionally specify the groups to display (displayed in the order listed).
	display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
	INLINE_STYLE_BUTTONS: [
		{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
		{ label: 'Italic', style: 'ITALIC' },
		{ label: 'Underline', style: 'UNDERLINE' }
	],
	BLOCK_TYPE_DROPDOWN: [
		{ label: 'Normal', style: 'unstyled' },
		{ label: 'Heading Large', style: 'header-one' },
		{ label: 'Heading Medium', style: 'header-two' },
		{ label: 'Heading Small', style: 'header-three' }
	],
	BLOCK_TYPE_BUTTONS: [
		{ label: 'UL', style: 'unordered-list-item' },
		{ label: 'OL', style: 'ordered-list-item' }
	]
};

function MobilePhoneMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	);
}

function PhoneMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	);
}

class InsurerForm extends React.Component {

	constructor(props) {
		super(props);
		let formik = React.createRef();
		this.state = {
			id: "",
			title: "",
			deal: "",
			image: "",
			description: "",
			phone: "",
			mobilePhone: "",
			cnpj: "",
			website: "",
			email: "",

			filesCollection: [],
			lsAttachments: [],

			richTextValue: "",

			editMode: false,
			disabledForm: false,
		}
		this.onChangeRTE = this.onChangeRTE.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		const id = this.props.match && this.props.match.params.id;
		//
		if (!this.props.user) {
			this.props.history.push(`/auth/login`);
		}
		//
		let _new = _.includes(this.props.match.path, "new");
		let _edit = _.includes(this.props.match.path, "edit");
		if (_new) {
			this.setState({ editMode: true, disabledForm: false });
			this.props.HideLoader();
		} else if (_edit) {
			this.setState({ editMode: true, disabledForm: false });
		} else {
			this.setState({ editMode: false, disabledForm: true });
		}
		//
		let _insurerData = null;
		if (!_.isEmpty(this.props.lsInsurers) && id) {
			_insurerData = this.props.lsInsurers && this.props.lsInsurers.find(e => e.id === id);
			if (_insurerData) {
				// this.setState({ ..._insurerData });
				this.setState({
					..._insurerData,
					richTextValue: _insurerData.deal
				});
				this.props.HideLoader();
			}
		} else {
			this.setState({ id: id });
			this.props.GetInsurerById(id);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.insurerItem !== this.props.insurerItem) {
			// this.props.GetAttachmentsById(this.props.insurerItem.id);
			this.setState({
				...this.props.insurerItem,
				richTextValue: this.props.insurerItem.deal
			});
			this.props.HideLoader();
		}
		// UPDATE
		if (prevProps.insurerUpdate !== this.props.insurerUpdate) {
			if (this.state.filesCollection.length > 0) {
				this.handleUploadFile();
			} else {
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: "block" }}
						title={""}
						onConfirm={() => { this.handleBack(); }}
						confirmBtnCssClass={
							this.props.classes.button + " " + this.props.classes.primary
						}
						confirmBtnText={"OK"}
					>
						Seguradora atualizada com sucesso
                </Alert >
				)
				setTimeout(() => this.handleBack(), 3000);
			}
		}
		// POST
		if (prevProps.insertInsurer !== this.props.insertInsurer) {
			if (this.state.filesCollection.length > 0) {
				this.handleUploadFile();
			} else {
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: "block" }}
						title={""}
						onConfirm={() => { this.handleBack(); }}
						confirmBtnCssClass={
							this.props.classes.button + " " + this.props.classes.primary
						}
						confirmBtnText={"OK"}
					>
						Seguradora inserida com sucesso!
                </Alert>
				)
				setTimeout(() => this.handleBack(), 3000);
			}
		}
		//
		if (prevProps.insertAttachment !== this.props.insertAttachment) {
			console.log(">>> insertAttachment: ", this.props.insertAttachment);
			if (this.props.insertAttachment.length > 0) {
				this.setState({ id: "" }, () => {
					this.componentDidMount();
				});
			}
		}

		if (prevProps.lsAttachments !== this.props.lsAttachments) {
			console.log(">>> lsAttachments: ", this.props.lsAttachments);
		}
		//
		if (prevProps.filesPostObj !== this.props.filesPostObj) {
			console.log("filesPostObj: ", this.props.filesPostObj);
			this.props.HideLoader();
			this.componentDidMount();
		}
		if (prevProps.filesObj !== this.props.filesObj) {
			console.log("filesObj: ", this.props.filesObj);
			this.props.HideLoader();
		}
		if (prevProps.getFiles !== this.props.getFiles) {
			console.log("getFiles: ", this.props.getFiles);
		}

		if (prevProps.getFilesFailed !== this.props.getFilesFailed) {
			console.log("getFilesFailed: ", this.props.getFilesFailed);
		}
		if (prevProps.filesFailed !== this.props.filesFailed) {
			console.log("filesFailed: ", this.props.filesFailed);
		}
		//
		if (prevState.id !== this.state.id) {
			console.log("ID: ", this.state.id);
		}
		//
		// FAILED
		if (prevProps.insurersFailed !== this.props.insurersFailed) {
			console.log("insurersFailed: ", this.props.insurersFailed);
			this.handleRequestFail("Erro ao consultar as seguradoras");
		}
		if (prevProps.insurerItemFailed !== this.props.insurerItemFailed) {
			console.log("insurerItemFailed: ", this.props.insurerItemFailed);
			this.handleRequestFail("Erro ao consultar a seguradora");
		}
		if (prevProps.insurerUpdateFailed !== this.props.insurerUpdateFailed) {
			console.log("insurerUpdateFailed: ", this.props.insurerUpdateFailed);
			this.handleRequestFail("Erro ao atualizar a seguradora");
		}
		if (prevProps.insertInsurerFailed !== this.props.insertInsurerFailed) {
			console.log("insertInsurerFailed: ", this.props.insertInsurerFailed);
			this.handleRequestFail("Erro ao inserir a seguradora");
		}
	}

	handleUploadFile = () => {
		this.props.ShowLoader(<Loader />);
		this.props.FilesUploadPost(this.state.id, this.state.filesCollection).then(rest => {
			if (rest.status == 201) {
				this.props.PostAttachmentsData(this.props.filesObj);
			}
		});
	}

	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => { this.props.HideAlert(); }}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		)
	}

	handleSave = () => {
		const { id, title, deal, description, phone, mobilePhone, website, email, filesCollection } = this.state;
		//
		if (deal.replace("<p><br></p>", "") !== "") {
			this.setState({ editMode: false, disabledForm: true });
			this.props.ShowLoader(<Loader />);
			//  
			if (!_.isEmpty(id)) {
				// EDIT
				let obj = {
					title: title,
					deal: deal,
					description: description,
					phone: phone,
					mobilePhone: mobilePhone,
					website: website,
					email: email,
				}
				this.props.PutInsurer(id, obj);
			} else {
				// NEW
				let obj = {
					title: title,
					deal: deal,
					description: description,
					phone: phone,
					mobilePhone: mobilePhone,
					website: website,
					email: email
				}
				this.props.PostInsurerData(obj);
			}
		}
	}

	onChangeRTE = (richTextValue) => {
		this.setState({ richTextValue, deal: richTextValue.toString('html') });
		if (this.props.onChange) {
			// Send the changes up to the parent component as an HTML string.
			// This is here to demonstrate using `.toString()` but in a real app it
			// would be better to avoid generating a string on each change.
			this.props.onChangeRTE(
				richTextValue.toString('html')
			);
		}
	};

	handleChange = (event) => {
		const id = event.target.id || event.target.name;
		let value = event.target.value;;
		//
		this.setState({ [id]: value });
	}

	handleBack = () => {
		this.props.HideAlert();
		if (this.props.user.admin) {
			this.props.history.push(`/manageInsurer`);
		} else {
			this.props.history.push(`/insurers`);
		}
	}

	handleCancel = () => {
		if (this.props.user.admin) {
			this.props.history.push(`/manageInsurer`);
		} else {
			this.props.history.push(`/insurers`);
		}
	}

	handleEdit = () => {
		this.props.history.push(`/manageInsurer/edit/${this.state.id}`);
	}

	CancelForm = () => {
		this.setState({
			editMode: false, disabledForm: true,
			richTextValue: this.props.regulationData.regulation
		});
		this.props.HideLoader();
		this.props.HideAlert();
		this.props.history.goBack();
	}

	onFileChange(e) {
		this.setState({ filesCollection: e.target.files })
	}

    // QUILL TEXT EDITOR
    modules = {
        toolbar: [
            // [{ 'font': [] }],
            // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            // [{ 'list': 'ordered' }, { 'list': 'bullet' },
            // { 'indent': '-1' }, { 'indent': '+1' }],
            // ['link', 'image'],
            // ['clean']
            ['bold', 'italic', 'underline', 'strike'],       // toggled buttons
            ['blockquote', 'code-block'],                    // blocks
            [{ 'header': 1 }, { 'header': 2 }],              // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],    // lists
            [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
            ['link', 'image'],								// link image
            [{ 'direction': 'rtl' }],                        // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],       // header dropdown
            [{ 'color': [] }, { 'background': [] }],         // dropdown with defaults
            [{ 'font': [] }],                                // font family
            [{ 'align': [] }],                               // text align
            ['clean'],                                       // remove formatting
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    disabledModules = {
        toolbar: [
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    formats = [
        // 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote',
        // 'list', 'bullet', 'indent', 'link', 'image', 'video'
        'header', 'font', 'background', 'color', 'code', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'script', 'align', 'direction',
        'link', 'image', 'code-block', 'formula', 'video'
    ]

	render() {
		const { classes } = this.props;
		const { editMode, disabledForm, id, title, deal, image, description, phone, mobilePhone, cnpj, website, email, richTextValue } = this.state;
		return (
			<Fragment>
				<DocumentTitle title={"PRx Consultoria - Editar Comunicado"} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardBody style={{ padding: "28px" }}>
								<CardHeader style={{ padding: '0', paddingBottom: '10px' }}>
									<GridContainer>
										<GridItem xs={12} sm={8} md={7}>
											<Section title={editMode ? "Nova seguradora" : "Editar seguradora"} />
										</GridItem>
										<GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
											{editMode &&
												<Button
													size="sm"
													variant="contained"
													color="primary"
													onClick={this.handleSave.bind(this)}>
													Salvar
                                                </Button>}
											{" "}
											{editMode &&
												<Button
													color="info"
													size="sm"
													className={classes.marginRight}
													onClick={this.handleBack}
												>Cancelar</Button>
											}
											{disabledForm &&
												<Fragment>
													<Button
														color="primary"
														size="sm"
														className={classes.marginRight}
														onClick={this.handleEdit.bind(this)}
													>Editar
                                                </Button>
													<Button
														color="info"
														size="sm"
														className={classes.marginRight}
														onClick={this.handleBack.bind(this)}
													>Voltar
                                                </Button>
												</Fragment>
											}
										</GridItem>
									</GridContainer>
								</CardHeader>


								<CardBody style={{ padding: '0px' }}>
									<GridContainer>
										{/* TITLE */}
										<GridItem xs={12} md={4} lg={4}>
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												labelText="Seguradora"
												id="title"
												name="title"
												inputProps={{
													value: title,
													onChange: this.handleChange,
													type: "text",
													disabled: disabledForm
												}}
											/>
										</GridItem>
										{/* EMAIL */}
										<GridItem xs={12} md={4} lg={4}>
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												labelText="E-mail"
												id="email"
												name="email"
												inputProps={{
													value: email,
													onChange: this.handleChange,
													type: "text",
													disabled: disabledForm
												}}
											/>
										</GridItem>
										{/* WEBSITE */}
										<GridItem xs={12} md={4} lg={4}>
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												labelText="Site"
												id="website"
												name="website"
												inputProps={{
													value: website,
													onChange: this.handleChange,
													type: "text",
													disabled: disabledForm
												}}
											/>
										</GridItem>

										{/* PHONE */}
										<GridItem xs={12} md={6} lg={6}>
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												labelText="Telefone"
												id="phone"
												name="phone"
												inputProps={{
													value: phone,
													onChange: this.handleChange,
													type: "text",
													disabled: disabledForm,
													inputComponent: PhoneMaskCustom
												}}
											/>
										</GridItem>
										{/* MOBILE PHONE */}
										<GridItem xs={12} md={6} lg={6}>
											<CustomInput
												formControlProps={{
													fullWidth: true
												}}
												labelText="Celular"
												id="mobilePhone"
												name="mobilePhone"
												inputProps={{
													value: mobilePhone,
													onChange: this.handleChange,
													type: "text",
													disabled: disabledForm,
													inputComponent: MobilePhoneMaskCustom
												}}
											/>
										</GridItem>

										{editMode && <GridItem xs={12}>
											<input
												type="file"
												name="filesCollection"
												onChange={this.onFileChange}
												multiple
												accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf" />
										</GridItem>}
									</GridContainer>

									<GridContainer style={{ paddingTop: "20px" }}>
										{id && <AttachmentList objId={id} editMode={editMode} />}
									</GridContainer>
									<hr />
									<GridContainer style={{ paddingTop: "20px" }}>
										{/* DEAL */}
										<GridItem xs={12} >
											<ReactQuill
												theme={disabledForm ? "bubble" : "snow"}
												value={richTextValue}
												modules={disabledForm ? this.disabledModules : this.modules}
												formats={this.formats}
												onChange={value => this.onChangeRTE(value)}
												readOnly={disabledForm}
											/>
										</GridItem>

									</GridContainer>
								</CardBody>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer >
			</Fragment >
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.authentication.user,

		lsInsurers: state.insurer.lsInsurers,
		insurerItem: state.insurer.insurerItem,
		insertInsurer: state.insurer.insertInsurer,
		insurerUpdate: state.insurer.insurerUpdate,
		insurerDelete: state.insurer.insurerDelete,

		insurersFailed: state.insurer.insurersFailed,
		insurerUpdateFailed: state.insurer.insurerUpdateFailed,
		insurerItemFailed: state.insurer.insurerItemFailed,
		insertInsurerFailed: state.insurer.insertInsurerFailed,
		insurerDeleteFailed: state.insurer.insurerDeleteFailed,

		insertAttachment: state.attachments.insertAttachment,
		insertAttachmentFailed: state.attachments.insertAttachmentFailed,

		lsAttachments: state.attachments.lsAttachments,
		attachmentsFailed: state.attachments.attachmentsFailed,

		filesPostObj: state.filesUpload.filesPostObj,
		filesObj: state.filesUpload.filesObj,
		getFiles: state.filesUpload.getFiles,
		getFilesFailed: state.filesUpload.getFilesFailed,
		filesFailed: state.filesUpload.filesFailed,
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ PostAttachmentsData, DeleteAttachments, FilesUploadPost, GetFiles, GetInsurers, GetInsurerById, PutInsurer, PostInsurerData, DeleteInsurer, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(regularFormsStyle),
	withStyles(sweetAlertStyle),
)(InsurerForm); 