/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import 'dotenv/config';

import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import 'moment/locale/pt-br';

import AuthLayout from "pages/Auth";
import AdminLayout from "pages/Admin";

import { PrivateRoute } from "components/PrivateRoute.js";
import { Provider } from 'react-redux';
import { history } from './utils';
import { ConnectedRouter } from 'connected-react-router';
import store from './redux/store';
import axios from 'axios';

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import * as serviceWorker from './utils/serviceWorker';

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import 'utils/i18n.js';
//import 'assets/icons/style.css';

import NetworkService from './utils/network-service';
NetworkService.setupInterceptors(store);

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.defaults.baseURL = process.env.REACT_APP_PRX_API_URL;

// const hist = createBrowserHistory();

/******************
 * MOMENT CONFIG
 ******************/
// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
Moment.globalLocale = "pt-BR";

// Set the output format for every react-moment instance.
Moment.globalFormat = 'DD MM YYYY';

// Set the timezone for every instance.
Moment.globalTimezone = 'America/Sao_Paulo';

// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span';

// Upper case all rendered dates.
Moment.globalFilter = (d) => {
	return d.toUpperCase();
};

/* It needs to find a better way as soon as possible */

window.onbeforeunload = function () {
	if (localStorage.getItem("i18next_pt-BR-common") !== null) {
		localStorage.removeItem('i18next_pt-BR-common');
	}
	if (localStorage.getItem("i18next_es-common") !== null) {
		localStorage.removeItem('i18next_es-common');
	}
	if (localStorage.getItem("i18next_en-common") !== null) {
		localStorage.removeItem('i18next_en-common');
	}
}

const App = () =>
	<Provider store={store}>
		<Suspense fallback={<div />}>
			<ConnectedRouter history={history}>
				<Switch>
					<Route path="/auth" component={AuthLayout} />
					<PrivateRoute path="/" component={AdminLayout} />
					<Redirect from="/" to="home" />
				</Switch>
			</ConnectedRouter>
		</Suspense>
	</Provider>

ReactDOM.render(
	<App />, document.getElementById('root'));

serviceWorker.unregister();