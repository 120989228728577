import LoginPage from "pages/Auth/LoginPage.js";

import Home from "pages/Home/Home.js";

import Production from "pages/Production/Productions.js";
import ProductionForm from "pages/Production/management/ProductionForm.js";

import Partner from "pages/Partner/Partner.js";
import PartnerForm from "pages/Partner/management/PartnerForm.js";

import BusinessUnit from "pages/BusinessUnit/BusinessUnit.js";
import BusinessUnitForm from "pages/BusinessUnit/management/BusinessUnitForm.js";

import InsurerHome from "pages/Insurer/Home.js";
import Insurer from "pages/Insurer/InsurerTable.js";
import InsurerForm from "pages/Insurer/management/InsurerForm.js";

import Document from "pages/Document/Document.js";
import DocumentForm from "pages/Document/management/DocumentForm.js";

import Profile from "pages/User/Profile/Profile.js";
import ChangePassword from "pages/User/ChangePassword/ChangePassword.js";

// import CommunicationHome from "pages/Communication/Home.js";
import CommunicationsHome from "pages/Communication/Home.js";
import Communication from "pages/Communication/Communication.js";
import CommunicationForm from "pages/Communication/management/CommForm.js";

import HomeBroker from "pages/Broker/Home.js";
import BrokersTable from "pages/Broker/BrokersTable.js";
import BrokerForm from "pages/Broker/management/BrokerForm.js";
//
// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Announcement, Assessment, Image, SupervisorAccount, Place, Assignment } from "@material-ui/icons";

var dashRoutes = [
	{
		path: "/home",
		name: "Home",
		icon: HomeIcon,
		component: Home,
		layout: "/admin",
		sidebar: true,
	},



	{
		path: "/manageCommunications/edit/:id",
		name: "Editar Comunicado",
		icon: DashboardIcon,
		component: CommunicationForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/manageCommunications/new",
		name: "Novo Comunicado",
		icon: DashboardIcon,
		component: CommunicationForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/manageCommunications/:id",
		name: "Visualizar Comunicado",
		icon: DashboardIcon,
		component: CommunicationForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/manageCommunications",
		name: "Gerenciar Comunicados",
		icon: Announcement,
		component: Communication,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/communications",
		name: "Comunicado",
		icon: Announcement,
		component: CommunicationsHome,
		layout: "/admin",
		sidebar: true,
	},



	{
		path: "/production/edit/:id",
		name: "Editar Produção",
		icon: DashboardIcon,
		component: ProductionForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/production/new",
		name: "Nova Produção",
		icon: DashboardIcon,
		component: ProductionForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/production/:id",
		name: "Visualizar Produção",
		icon: DashboardIcon,
		component: ProductionForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/productions",
		name: "Produções",
		icon: Assessment,
		component: Production,
		layout: "/admin",
		sidebar: true,
	},



	{
		collapse: true,
		name: "Corretoras",
		icon: Image,
		state: "pageCollapse",
		sidebar: true,
		views: [
			{
				path: "/brokers",
				name: "Corretoras",
				mini: "Co",
				component: HomeBroker,
				layout: "/admin",
				sidebar: true,
			},
			{
				path: "/chancellors",
				name: "Chanceladas",
				mini: "CH",
				component: HomeBroker,
				layout: "/admin",
				sidebar: true,
			},
		]
	},
	{
		path: "/brokersManagement",
		name: "Corretoras",
		icon: DashboardIcon,
		component: BrokersTable,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/chancellorsManagement",
		name: "Corretoras Chanceladas",
		icon: DashboardIcon,
		component: BrokersTable,
		layout: "/admin",
		sidebar: false,
	},



	{
		path: "/partner/edit/:id",
		name: "Editar Parceiro",
		icon: SupervisorAccount,
		component: PartnerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/partner/new",
		name: "Novo Parceiro",
		icon: SupervisorAccount,
		component: PartnerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/partner/:id",
		name: "Visualizar Parceiro",
		icon: SupervisorAccount,
		component: PartnerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/partners",
		name: "Parceiros",
		icon: SupervisorAccount,
		component: Partner,
		layout: "/admin",
		sidebar: true,
	},



	{
		path: "/businessUnit/edit/:id",
		name: "Editar Unidade de negócio",
		icon: Place,
		component: BusinessUnitForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/businessUnit/new",
		name: "Nova Unidade de negócio",
		icon: Place,
		component: BusinessUnitForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/businessUnit/:id",
		name: "Visualizar Unidade de negócio",
		icon: Place,
		component: BusinessUnitForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/businessUnit",
		name: "Unidade de negócio",
		icon: Place,
		component: BusinessUnit,
		layout: "/admin",
		sidebar: true,
	},



	{
		path: "/manageInsurer/edit/:id",
		name: "Editar Seguradoras",
		icon: DashboardIcon,
		component: InsurerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/manageInsurer/new",
		name: "Novo Seguradoras",
		icon: DashboardIcon,
		component: InsurerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/manageInsurer/:id",
		name: "Visualizar Seguradoras",
		icon: DashboardIcon,
		component: InsurerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/manageInsurer",
		name: "Gerenciar Seguradoras",
		icon: Announcement,
		component: Insurer,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/insurer",
		name: "Seguradoras",
		icon: Announcement,
		component: InsurerHome,
		layout: "/admin",
		sidebar: true,
	},



	{
		path: "/document/edit/:id",
		name: "Editar Documento",
		icon: DashboardIcon,
		component: DocumentForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/document/new",
		name: "Novo Documento",
		icon: DashboardIcon,
		component: DocumentForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/document/:id",
		name: "Visualizar Documento",
		icon: DashboardIcon,
		component: DocumentForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/documents",
		name: "Documentos",
		icon: Assignment,
		component: Document,
		layout: "/admin",
		sidebar: true,
	},
	

	{
		path: "/broker/edit/:id",
		name: "Corretora",
		icon: DashboardIcon,
		component: BrokerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/broker/new",
		name: "Corretora",
		icon: DashboardIcon,
		component: BrokerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/broker/:id",
		name: "Corretora",
		icon: DashboardIcon,
		component: BrokerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/chancellor/edit/:id",
		name: "Corretora Chancelada",
		icon: DashboardIcon,
		component: BrokerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/chancellor/new",
		name: "Corretora Chancelada",
		icon: DashboardIcon,
		component: BrokerForm,
		layout: "/admin",
		sidebar: false,
	},
	{
		path: "/chancellor/:id",
		name: "Corretora Chancelada",
		icon: DashboardIcon,
		component: BrokerForm,
		layout: "/admin",
		sidebar: false,
	},

	{
		path: "/profile",
		name: "Meu Perfil",
		sidebar: false,
		icon: 'portrait',
		layout: '/admin',
		component: Profile
	},
	{
		path: "/changepassword",
		name: "Alterar Senha",
		sidebar: false,
		icon: 'portrait',
		layout: '/admin',
		component: ChangePassword
	}
];
export default dashRoutes;
