import React, { Fragment } from "react";
import * as _ from "lodash";
import { VerifyCNPJ } from "utils/utils";
import DocumentTitle from 'react-document-title';
import compose from "utils/compose";
import { Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from 'react-text-mask';
import Alert from "react-bootstrap-sweetalert";

import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// core components
import AttachmentList from "components/AttachmentList/AttachmentList.js";
import Loader from "components/Loader/Loader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Section from "components/Section/Section.js";

// REDUX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    PostAttachmentsData, FilesUploadPost, GetPartners, GetPartnerById, PutPartner, PostPartnerData, DeletePartner, GetBusinessUnit, SweetAlert, HideAlert, ShowLoader, HideLoader
} from 'redux/actions';

function MobilePhoneMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

function PhoneMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

function CNPJMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

const validationSchema = Yup.object({
    title: Yup.string().required("obrigatório"),
    category: Yup.string().required(" obrigatório."),
    mobilePhone: Yup.string().required("obrigatório"),
    // cnpj: Yup.string().matches(/^([0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2})|([0-9]{14})/, { message: "inválido", excludeEmptyString: true }).required("obrigatório"),
    email: Yup.string().max(50, " máximo 50 caracteres").email(" formato inválido.").required(" obrigatório."),
    // website: Yup.string().required(" obrigatório."),
    // description: Yup.string().required(" obrigatório."),
})
// .test("cnpjInvalido", null, (obj) => {
//     if (obj.cnpj && VerifyCNPJ(obj.cnpj)) {
//         return true;
//     } else {
//         return new Yup.ValidationError("inválido", null, 'cnpj');
//     }
// });

const Form = props => {
    const {
        classes,
        values,
        errors,
        touched,
        idValid,
        handleSubmit,
        handleChange,
        handleCancel,
        handleEdit,
        handleBack,
        onFileChange,
        disabledForm,
        setFieldTouched,
        setFieldValue,
        editMode,
        admin
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    return (
        <Fragment>
            <form>
                <GridContainer>
                    {/* FORM CARD */}
                    <GridItem xs={12} sm={4} md={12}>
                        <Card>
                            <CardHeader style={{ padding: '28px' }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={7}>
                                        <Section title="Parceiro" />
                                    </GridItem>
                                    {/* <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            className={classes.marginRight}
                                            onClick={() => this.handleNew()}>Novo</Button>
                                    </GridItem> */}
                                    <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                        {editMode &&
                                            <Fragment>
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={handleSubmit}
                                                >Salvar
                                            </Button>

                                                <Button
                                                    color="info"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={handleCancel.bind(this)}
                                                >Cancelar</Button>
                                            </Fragment>
                                        }

                                        {disabledForm &&
                                            <Fragment>
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={handleEdit.bind(this)}
                                                >Editar
                                                </Button>
                                                <Button
                                                    color="info"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={handleBack.bind(this)}
                                                >Voltar
                                                </Button>
                                            </Fragment>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody style={{ padding: '0 28px' }}>
                                {/* L1 */}
                                <GridContainer>
                                    {/* NOME */}
                                    <GridItem xs={12} sm={4} md={4}>
                                        <CustomInput
                                            error={touched.title && Boolean(errors.title)}
                                            labelText="Nome"
                                            id="title"
                                            name="title"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: change.bind(null, "title"),
                                                disabled: disabledForm,
                                                value: values.title
                                            }}
                                        />
                                    </GridItem>
                                    {/* CATEGORIA */}
                                    <GridItem xs={12} sm={4} md={4}>
                                        <CustomInput
                                            error={touched.category && Boolean(errors.category)}
                                            labelText="Categoria"
                                            id="category"
                                            name="category"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: change.bind(null, "category"),
                                                disabled: disabledForm,
                                                value: values.category
                                            }}
                                        />
                                    </GridItem>

                                    {/* #4 - cnpj */}
                                    <GridItem xs={12} sm={4} md={4}>
                                        <CustomInput
                                            // error={touched.cnpj && Boolean(errors.cnpj)}
                                            id="cnpj"
                                            name="cnpj"
                                            labelText="CNPJ"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: values.cnpj,
                                                disabled: disabledForm,
                                                onChange: change.bind(null, 'cnpj'),
                                                inputComponent: CNPJMaskCustom
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                {/* L2 */}
                                <GridContainer>
                                    {/* TELEFONE */}
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomInput
                                            error={touched.phone && Boolean(errors.phone)}
                                            labelText="Telefone"
                                            id="phone"
                                            name="phone"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: change.bind(null, "phone"),
                                                disabled: disabledForm,
                                                value: values.phone,
                                                inputComponent: PhoneMaskCustom
                                            }}
                                        />
                                    </GridItem>

                                    {/* CELULAR */}
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomInput
                                            error={touched.mobilePhone && Boolean(errors.mobilePhone)}
                                            labelText="Celular"
                                            id="mobilePhone"
                                            name="mobilePhone"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: change.bind(null, "mobilePhone"),
                                                disabled: disabledForm,
                                                value: values.mobilePhone,
                                                inputComponent: MobilePhoneMaskCustom
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                {/* L3 */}
                                <GridContainer>
                                    {/* EMAIL */}
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomInput
                                            error={touched.email && Boolean(errors.email)}
                                            labelText="Email"
                                            id="email"
                                            name="email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: change.bind(null, "email"),
                                                disabled: disabledForm,
                                                value: values.email
                                            }}
                                        />
                                    </GridItem>
                                    {/* SITE */}
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomInput
                                            // error={touched.website && Boolean(errors.website)}
                                            labelText="Site"
                                            id="website"
                                            name="website"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: change.bind(null, "website"),
                                                disabled: disabledForm,
                                                value: values.website
                                            }}
                                        />
                                    </GridItem>

                                    {editMode && <GridItem xs={12}>
                                        <input
                                            type="file"
                                            name="filesCollection"
                                            onChange={onFileChange}
                                            multiple
                                            accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf" />
                                    </GridItem>}
                                </GridContainer>
                                <hr />
                                <GridContainer style={{ paddingTop: "20px" }}>
                                    {idValid && <AttachmentList objId={idValid} editMode={editMode} />}
                                </GridContainer>
                                <hr />
                                {/* L4 */}
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <InputLabel
                                            htmlFor="Descrição"
                                        // className={(touched.description && Boolean(errors.description) ? styles.Error : classes.selectLabel)}
                                        >Descrição</InputLabel>
                                        <CustomInput
                                            error={touched.name && Boolean(errors.name)}
                                            id="description"
                                            name="description"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                multiline: true,
                                                onChange: change.bind(null, "description"),
                                                disabled: disabledForm,
                                                value: values.description,
                                                rows: 5
                                            }}
                                        />
                                    </GridItem>
                                    <Clearfix />
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form>
        </Fragment>
    )
}

class PartnerForm extends React.Component {

    constructor(props) {
        super(props);
        let formik = React.createRef();
        this.state = {
            id: "",
            title: "",
            category: "",
            phone: "",
            mobilePhone: "",
            cnpj: "",
            website: "",
            email: "",
            description: "",
            date: "",

            editMode: false,
            disabledForm: false,
            admin: false,

            filesCollection: [],
        }
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);
        const id = this.props.match && this.props.match.params.id;
        //
        let _new = _.includes(this.props.match.path, "new");
        let _edit = _.includes(this.props.match.path, "edit");
        if (_new) {
            this.setState({ editMode: true, disabledForm: false });
            this.props.HideLoader();
        } else if (_edit) {
            this.setState({ editMode: true, disabledForm: false });
        } else {
            this.setState({ editMode: false, disabledForm: true });
        }
        //
        let _partnerData = null;
        if (!_.isEmpty(this.props.lsPartners) && id) {
            _partnerData = this.props.lsPartners && this.props.lsPartners.find(e => e.id === id);
            if (_partnerData) {
                this.setState({ ..._partnerData });
                this.props.HideLoader();
            }
        } else {
            this.props.GetPartnerById(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevState.admin !== this.state.admin){
            console.log("Admin: ", this.state.admin);
        }
        if(prevState.editMode !== this.state.editMode){
            console.log("editMode: ", this.state.editMode);
        }
        if(prevState.disabledForm !== this.state.disabledForm){
            console.log("disabledForm: ", this.state.disabledForm);
        }

        if (prevProps.user !== this.props.user) {
            this.setState({ admin: this.props.user.admin });
        }

        if (prevProps.partnerItem !== this.props.partnerItem) {
            // console.log("---> ", this.props.partnerItem);
            this.setState({ ...this.props.partnerItem });
            this.props.HideLoader();
        }

        if (prevProps.partnerUpdate !== this.props.partnerUpdate) {
             console.log("partnerUpdate: ", this.props.partnerUpdate);
            if (this.state.filesCollection.length > 0) {
                this.handleUploadFile();
            } else {
                this.props.SweetAlert(
                    <Alert
                        success
                        style={{ display: "block" }}
                        title={""}
                        onConfirm={() => { this.handleBack() }}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText={"OK"}
                    >
                        Operação realizada com Sucesso!
                </Alert>
                )
                setTimeout(() => this.handleBack(), 3000);
            }
        }

        if (prevProps.insertAttachment !== this.props.insertAttachment) {
			console.log(">>> insertAttachment: ", this.props.insertAttachment);
			if (this.props.insertAttachment.length > 0) {
				this.setState({ id: "" }, () => {
					this.componentDidMount();
				});
			}
		}

        if (prevProps.insertPartner !== this.props.insertPartner) {
            this.props.HideLoader();
            if (this.state.filesCollection.length > 0) {
                this.setState({ ...this.props.insertPartner }, () => {
                    this.handleUploadFile();
                });
            } else {
                // console.log("insertPartner: ", this.props.insertPartner);
                this.props.SweetAlert(
                    <Alert
                        success
                        style={{ display: "block" }}
                        title={""}
                        onConfirm={() => { this.handleBack() }}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText={"OK"}
                    >
                        Parceiro inserido com sucesso!
                </Alert>
                )
                setTimeout(() => this.handleBack(), 3000);
            }
        }
        if (prevProps.partnerDelete !== this.props.partnerDelete) {
            // console.log("partnerDelete: ", this.props.partnerDelete);
        }
        //
        // FAILED
        if (prevProps.partnersFailed !== this.props.partnersFailed) {
            console.log("partnersFailed: ", this.props.partnersFailed);
            this.handleRequestFail("Erro ao consultar os parceiros");
        }
        if (prevProps.partnerItemFailed !== this.props.partnerItemFailed) {
            console.log("partnerItemFailed: ", this.props.partnerItemFailed);
            this.handleRequestFail("Erro ao consultar o parceiro");
        }
        if (prevProps.partnerUpdateFailed !== this.props.partnerUpdateFailed) {
            console.log("partnerUpdateFailed: ", this.props.partnerUpdateFailed);
            this.handleRequestFail("Erro ao atualizar o parceiro");
        }
        if (prevProps.insertPartnerFailed !== this.props.insertPartnerFailed) {
            console.log("insertPartnerFailed: ", this.props.insertPartnerFailed);
            this.handleRequestFail("Erro ao inserir o parceiro");
        }
        if (prevProps.partnerDeleteFailed !== this.props.partnerDeleteFailed) {
            console.log("partnerDeleteFailed: ", this.props.partnerDeleteFailed);
            this.handleRequestFail("Erro ao deletar o parceiro");
        }
    }

    handleRequestFail = (msg) => {
        this.props.HideLoader();
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={"Atenção!"}
                onConfirm={() => { this.props.HideAlert(); }}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                confirmBtnText={"OK"}
            >
                {msg}
            </Alert>
        )
    }

    findBussinessUnitObj = () => {

    }

    handleEdit = () => {
        this.props.history.push(`/partner/edit/${this.state.id}`);
    }

    handleUploadFile = () => {
        this.props.ShowLoader(<Loader />);
        this.props.FilesUploadPost(this.state.id, this.state.filesCollection).then(rest => {
            if (rest.status == 201) {
                this.props.PostAttachmentsData(this.props.filesObj);
            }
        });
    }

    handleSave = (values) => {
        this.props.ShowLoader(<Loader />);
        this.setState({ editMode: false, disabledForm: true });
        //
        let obj = {
            title: values.title,
            category: values.category,
            phone: values.phone,
            mobilePhone: values.mobilePhone,
            cnpj: values.cnpj,
            website: values.website,
            email: values.email,
            description: values.description
        }
        //
        if (!this.state.id) {
            this.props.PostPartnerData(obj);
        } else {
            // console.log("===> ", obj);
            this.props.PutPartner(this.state.id, obj);
        }
    }

    handleCancel = () => {
        this.props.history.push(`/partners`);
    }

    handleBack = () => {
        this.props.HideLoader();
        this.props.HideAlert();
        this.props.history.push(`/partners`);
    }

    onFileChange(e) {
        this.setState({ filesCollection: e.target.files })
    }

    render() {
        const { classes } = this.props;
        const { id, title, category, admin, phone, mobilePhone, cnpj, website, email, description, editMode, disabledForm } = this.state;
        const values = { title, category, phone, mobilePhone, cnpj, website, email, description };
        return (
            <Fragment>
                <DocumentTitle title={`PRx Consultoria - Parceiro`} />
                <Formik
                    ref={this.formik}
                    render={props => <Form {...props}
                        classes={classes}
                        disabledForm={disabledForm}
                        handleCancel={this.handleCancel}
                        handleBack={this.handleBack}
                        handleEdit={this.handleEdit}
                        onFileChange={this.onFileChange}
                        editMode={editMode}
                        admin={admin}
                        idValid={this.state.id}
                    />}
                    validationSchema={validationSchema}
                    onSubmit={this.handleSave.bind(this)}
                    initialValues={values}
                    enableReinitialize={true}
                />

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        filesObj: state.filesUpload.filesObj,
        insertAttachment: state.attachments.insertAttachment,
        insertAttachmentFailed: state.attachments.insertAttachmentFailed,
        
        lsPartners: state.partners.lsPartners,
        partnerItem: state.partners.partnerItem,
        partnerUpdate: state.partners.partnerUpdate,
        insertPartner: state.partners.insertPartner,
        partnerDelete: state.partners.partnerDelete,
        partnersFailed: state.partners.partnersFailed,
        partnerItemFailed: state.partners.partnerItemFailed,
        partnerUpdateFailed: state.partners.partnerUpdateFailed,
        insertPartnerFailed: state.partners.insertPartnerFailed,
        partnerDeleteFailed: state.partners.partnerDeleteFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { PostAttachmentsData, FilesUploadPost, GetPartners, GetPartnerById, PutPartner, PostPartnerData, DeletePartner, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(userProfileStyles),
    withStyles(sweetAlertStyle),
)(PartnerForm); 