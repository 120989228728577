import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    filesPostObj: [],
    filesObj: [],
    getFiles: [],
    fileDelete: [],

    filesFailed: null,
    getFilesFailed: null,
    fileDeleteFailed: null,
};

const setFileData = (state, action) => {
    return updateObject(state, {
        filesObj: action.filesObj
    });
};

const postFilesData = (state, action) => {
    return updateObject(state, {
        filesPostObj: action.filesPostObj
    });
};

const fetchFiles = (state, action) => {
    return updateObject(state, {
        getFiles: action.getFiles
    });
};

const deleteFile = (state, action) => {
    return updateObject(state, {
        fileDelete: action.fileDelete
    });
};

// DATA FAIL
const postFilesFailed = (state, action) => {
    return updateObject(state, {
        filesFailed: true
    });
};

const fetchFilessFailed = (state, action) => {
    return updateObject(state, {
        getFilesFailed: true
    });
};

const deleteFileFailed = (state, action) => {
    return updateObject(state, {
        fileDeleteFailed: true
    });
};


const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILES_DATA:
            return setFileData(state, action);
        case actionTypes.POST_FILES_DATA:
            return postFilesData(state, action);
        case actionTypes.POST_FILES_FAILED:
            return postFilesFailed(state, action);
        case actionTypes.GET_FILES_DATA:
            return fetchFiles(state, action);
        case actionTypes.GET_FILES_FAILED:
            return fetchFilessFailed(state, action);
        case actionTypes.DELETE_FILE_DATA:
            return deleteFile(state, action);
        case actionTypes.DELETE_FILE_DATA_FAILED:
            return deleteFileFailed(state, action);
        default:
            return state;
    }
};

export default filesReducer;