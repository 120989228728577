import * as actionTypes from "./actionsTypes";
import * as API from "../api/insurer.js";
import * as Loader from './loader';

// GET ALL
export const GetInsurers = () => {
    return dispatch => (
        API.GetInsurers().then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_INSURERS_DATA,
            lsInsurers: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_INSURERS_DATA_FAILED,
            insurersFailed: error
        };
    };
}

// GET BY ID
export const GetInsurerById = (id) => {
    return dispatch => (
        API.GetInsurerById(id).then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_INSURER_BY_ID,
            insurerItem: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_INSURER_BY_ID_FAILED,
            insurerItemFailed: error
        };
    };
}

// UPDATE
export const PutInsurer = (id, obj) => {
    return dispatch => (
        API.PutInsurer(id, obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.PUT_INSURER_DATA,
            insurerUpdate: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.PUT_INSURER_FAILED,
            insurerUpdateFailed: error
        };
    };
}

// POST
export const PostInsurerData = (obj) => {
    return dispatch => {
        API.PostInsurer(obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.POST_INSURER_DATA,
            insertInsurer: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.POST_INSURER_DATA_FAILED,
            insertInsurerFailed: error
        };
    };
}

// DELETE
export const DeleteInsurer = (id) => {
    return dispatch => {
        API.DeleteInsurer(id).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.DELETE_INSURER_DATA,
            insurerDelete: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.DELETE_INSURER_FAILED,
            insurerDeleteFailed: error
        };
    };
}