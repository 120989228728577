import React from "react";
import Alert from "react-bootstrap-sweetalert";
import ChartistGraph from "react-chartist";
import DocumentTitle from "react-document-title";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
	chain,
	clone,
	filter,
	find,
	forEach,
	isEmpty,
	map,
	orderBy,
} from "lodash";

import Accordion from "components/Accordion/Accordion.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loader from "components/Loader/Loader.js";
import Section from "components/Section/Section.js";

import { withStyles } from "@material-ui/core/styles";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
// import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import { dashGraph } from "utils/charts";
import compose from "utils/compose";

import {
	DeleteProduction,
	GetBrokers,
	GetBusinessUnit,
	GetProductionById,
	GetProductions,
	HideAlert,
	HideLoader,
	PostProductionData,
	PutProduction,
	ShowLoader,
	SweetAlert,
} from "redux/actions";

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			userBusinessUnit: "",
			lsBusinessUnit: [],
			currentGraphId: "2d4aa9e0-896a-482a-bfb1-fbd1c7cf30e5",
			chartGraphData: [],
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		//
		if (this.props.user.admin) {
			this.props.GetBusinessUnit().then(() => {
				this.props.GetProductions();
			});
		} else {
			// if (isEmpty(this.props.lsBrokers)) {
			this.props.GetBrokers();
			// }
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.currentGraphId !== this.state.currentGraphId) {
			// console.log(this.state.currentGraphId);
		}
		if (prevProps.lsBrokers !== this.props.lsBrokers) {
			let bu = filter(this.props.lsBrokers, ["id", this.props.user.id])[0]
				.businessUnit;
			this.setState({ userBusinessUnit: bu });
			//
			this.props.GetBusinessUnit().then(() => {
				this.props.GetProductions();
			});
		}

		if (
			prevProps.lsProductions !== this.props.lsProductions &&
			this.props.lsBusinessUnit.length > 0
		) {
			this.handleGraphData(this.props.lsProductions, this.props.user);
			this.props.HideLoader();
		}

		if (prevProps.lsBusinessUnit !== this.props.lsBusinessUnit) {
			this.setState({
				lsBusinessUnit: orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
		}

		// FAILED
		if (prevProps.brokersFailed !== this.props.brokersFailed) {
			console.log("Brokers Failed: ", this.props.brokersFailed);
			this.handleRequestFail("Erro ao consultar as corretoras");
		}

		if (prevProps.productionsFailed !== this.props.productionsFailed) {
			console.log("Productions Failed: ", this.props.productionsFailed);
			this.handleRequestFail("Erro ao consultar dados");
		}

		if (prevProps.businessUnitsFailed !== this.props.businessUnitsFailed) {
			console.log(
				"BusinessUnit Failed: ",
				this.props.businessUnitsFailed
			);
			this.handleRequestFail("Erro ao consultar as unidades de negócios");
		}
	}
	//
	// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	handleGraphData = (lsProductions, userData) => {
		const allProds = orderBy(
			lsProductions.map((item) => {
				return {
					...item,
					businessUnitObj: find(this.props.lsBusinessUnit, [
						"id",
						item.businessUnit,
					]),
					businessUnitName: find(this.props.lsBusinessUnit, [
						"id",
						item.businessUnit,
					]).title,
				};
			}),
			["businessUnitName"],
			["asc"]
		);
		// console.log("PRODS: ", prods);
		const dataGrouped = chain(allProds)
			.groupBy("businessUnitName")
			.map((value, key) => ({
				businessUnitId: value[0].businessUnit,
				businessUnitName: key,
				data: orderBy(value, ["date"], ["desc"])
					.slice(0, 12)
					.reverse(),
			}))
			.value();
		// console.log(dataGrouped);
		//
		const chartData = [];
		forEach(dataGrouped, (item) => {
			const data = this.MountGraphData(item.data);
			const obj = {
				businessUnitId: item.businessUnitId,
				businessUnitName: item.businessUnitName,
				data,
			};
			chartData.push(obj);
		});
		//
		let newChartData = clone(chartData);
		if (!userData.admin) {
			this.setState({ currentGraphId: this.state.userBusinessUnit });
			newChartData = [
				find(chartData, [
					"businessUnitId",
					this.state.userBusinessUnit,
				]),
			];
		}
		this.setState({ chartGraphData: newChartData });
		// console.log("CHART DATA: ", newChartData);
	};
	MountGraphData(data) {
		const labels = data.map(
			(x) =>
				this.MonthsTranslate(x.month) +
				"/" +
				x.year.toString().substring(2, 4)
		);
		//
		// AXA BRASIL
		const dc5 = data.map((x) => Number(x.current5));
		const dg5 = data.map((x) => Number(x.goal5));
		const obj5 = {
			labels: labels,
			series: [
				{ name: "Realizado", data: dc5 },
				{ name: "Meta", data: dg5 },
			],
		};
		//
		// CHUBB BRASIL
		const dc6 = data.map((x) => Number(x.current6));
		const dg6 = data.map((x) => Number(x.goal6));
		const obj6 = {
			labels: labels,
			series: [
				{ name: "Realizado", data: dc6 },
				{ name: "Meta", data: dg6 },
			],
		};
		//
		// HDI - insurer1: "HDI"
		const dc1 = data.map((x) => Number(x.current1));
		const dg1 = data.map((x) => Number(x.goal1));
		const obj = {
			labels: labels,
			series: [
				{ name: "Realizado", data: dc1 },
				{ name: "Meta", data: dg1 },
			],
		};
		// this.setState({ graphBH: obj });
		// console.log("HDI OBJ: ", obj);
		//
		// LIBERTY - insurer2: "Liberty"
		const dc2 = data.map((x) => Number(x.current2));
		const dg2 = data.map((x) => Number(x.goal2));
		const obj2 = {
			labels: labels,
			series: [
				{ name: "Realizado", data: dc2 },
				{ name: "Meta", data: dg2 },
			],
		};
		// this.setState({ graphBL: obj2 });
		// console.log("LIB OBJ: ", obj2);
		//
		// SOMPO - insurer3: "Sompo"
		const dc3 = data.slice(0, 12).map((x) => Number(x.current3));
		const dg3 = data.slice(0, 12).map((x) => Number(x.goal3));
		const obj3 = {
			labels: labels,
			series: [
				{ name: "Realizado", data: dc3 },
				{ name: "Meta", data: dg3 },
			],
		};
		// this.setState({ graphBS: obj3 });
		// console.log("SOM OBJ: ", obj3);

		return [
			{ name: "AXA Brasil", data: obj5 },
			{ name: "Chubb Brasil", data: obj6 },
			{ name: "HDI", data: obj },
			{ name: "Liberty", data: obj2 },
			{ name: "Sompo", data: obj3 },
		];
	}
	// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	//
	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => {
					this.props.HideAlert();
				}}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		);
	};

	render() {
		const { lsBusinessUnit, currentGraphId, chartGraphData } = this.state;
		var graphLoaderSkeleton = (
			<div>
				<Skeleton height={200} count={1} />
				<p>
					<Skeleton height={38} width={50} count={2} />
				</p>
			</div>
		);

		return (
			<>
				<DocumentTitle title="PRx Consultoria - Produção" />

				{this.props.user.admin && (
					<GridContainer
						style={{ paddingTop: "30px", paddingLeft: "15px" }}
					>
						{map(lsBusinessUnit, (item, index) => [
							<Button
								color="primary"
								key={index}
								onClick={() =>
									this.setState({ currentGraphId: item.id })
								}
							>
								{item.title}
							</Button>,
						])}
					</GridContainer>
				)}

				<GridContainer>
					{/* 
					<Accordion
						active={0}
						collapses={[
							{
								title: "Collapsible group Item #1",
								content:
									"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
							},
						]}
					/>
					*/}

					{!isEmpty(chartGraphData) &&
						chartGraphData.map((forItem) =>
							forItem.data.map(
								(item) =>
									currentGraphId ===
										forItem.businessUnitId && (
										<GridItem xs={12} sm={12} md={12}>
											<Accordion
												active={0}
												collapses={[
													{
														title: `${forItem.businessUnitName} - ${item.name}`,
														content: (
															<CardBody
																style={{
																	padding:
																		"0 20px",
																}}
																key={
																	item.businessUnitId
																}
															>
																{item.data
																	.series &&
																item.data.series
																	.length ? (
																	<ChartistGraph
																		data={
																			item.data
																		}
																		type="Bar"
																		options={
																			dashGraph.options
																		}
																		listener={
																			dashGraph.animation
																		}
																	/>
																) : (
																	graphLoaderSkeleton
																)}
															</CardBody>
														),
													},
												]}
											/>
										</GridItem>
									)
							)
						)}
				</GridContainer>
			</>
		);
	}

	MonthsTranslate = (m) => {
		switch (m) {
			case 1:
				m = "JAN";
				break;
			case 2:
				m = "FEV";
				break;
			case 3:
				m = "MAR";
				break;
			case 4:
				m = "ABR";
				break;
			case 5:
				m = "MAI";
				break;
			case 6:
				m = "JUN";
				break;
			case 7:
				m = "JUL";
				break;
			case 8:
				m = "AGO";
				break;
			case 9:
				m = "SET";
				break;
			case 10:
				m = "OUT";
				break;
			case 11:
				m = "NOV";
				break;
			case 12:
				m = "DEZ";
				break;
			default:
				break;
		}
		return m;
	};
}

Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,

		lsBrokers: state.brokers.lsBrokers,
		brokersFailed: state.brokers.brokersFailed,

		lsProductions: state.productions.lsProductions,
		productionsFailed: state.productions.productionsFailed,

		lsBusinessUnit: state.businessUnit.lsBusinessUnit,
		businessUnitsFailed: state.businessUnit.businessUnitsFailed,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetBusinessUnit,
			GetBrokers,
			GetProductions,
			GetProductionById,
			PutProduction,
			PostProductionData,
			DeleteProduction,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(sweetAlertStyle)
	// withStyles(extendedFormsStyle),
)(Home);
