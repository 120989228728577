import React, { Fragment } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

// core components
// import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/default-avatar.png";

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { SetImageData } from 'redux/actions';

class AvatarUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount() {
        if (this.props.value && this.props.value !== "") {
            this.setState({ imagePreviewUrl: this.props.value, file: "" });
        }
    }

    handleImageChange = async (e) => {
        e.persist();
        e.preventDefault();
        let reader = new FileReader();
        await this.props.SetImageData(e.target.files[0]);
        reader.onloadend = () => {
            this.setState({
                file: this.props.imageFile,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(this.props.imageFile);
    }

    handleSubmit(e) {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }
    handleClick() {
        this.refs.fileInput.click();
    }
    handleRemove() {
        this.setState({
            file: null,
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
        });
        this.refs.fileInput.value = null;
        if (this.props.onChange) {
            this.props.onChange();
        }
    }
    render() {
        return (
            <Fragment>
                <div className="picture-container" style={{ textAlign: "left", width: '124px' }}>
                    <div className="picture" style={{ margin: "5px 0 0 0" }}>
                        <i className="material-icons"
                            style={{
                                backgroundColor: '#3b5998',
                                position: 'absolute',
                                left: '84px',
                                top: '30px',
                                border: '2px',
                                padding: '8px',
                                borderRadius: '60px'
                            }}
                        >camera_alt</i>
                        <img
                            style={{
                                objectFit: 'cover',
                                height: '100px',
                                width: '100px'
                            }}
                            src={this.state.imagePreviewUrl}
                            className="picture-src"
                            alt="..."
                        />
                        <input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={e => this.handleImageChange(e)} ref="fileInput" />
                    </div>
                </div>
                <button onClick={this.handleClick} style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'inline',
                    margin: '0',
                    padding: '0'
                }}>
                    <h6 className="description">Selecionar Imagem</h6></button>
                {/* {this.state.file != null &&
                    <Fragment> | <button onClick={this.handleRemove} style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'inline',
                        margin: '0',
                        padding: '0'
                    }}><h6 className="description">Remover Foto</h6></button>
                    </Fragment>} */}
            </Fragment>
        );
    }
}

AvatarUpload.propTypes = {
    value: PropTypes.string
};

const mapStateToProps = state => {
    return {
        imageFile: state.images.imageFile
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { SetImageData }
    , dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(AvatarUpload);
