import React from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from "react-document-title";
import MaskedInput from "react-text-mask";
import Skeleton from "react-loading-skeleton";
import compose from "utils/compose";
import cx from "classnames";
import { Formik } from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

// core components
import AvatarUpload from "components/CustomUpload/AvatarUpload.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loader from "components/Loader/Loader.js";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import avatar from "assets/img/image_placeholder.jpg";

import Section from "components/Section/Section.js";

import styles from "./styles.module.scss";

// REDUX INIT
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	SetImageData,
	GetBrokerById,
	GetBrokers,
	GetBusinessUnit,
	HideAlert,
	HideLoader,
	ImageUpload,
	PostBrokerData,
	PutBroker,
	ShowLoader,
	SweetAlert,
} from "redux/actions";

const validationSchema = Yup.object({
	name: Yup.string()
		.max(150, " máximo 150 caracteres")
		.required(" obrigatório."),
	brokerName: Yup.string().required(" obrigatório."),
	// email: Yup.string().max(50, " máximo 50 caracteres").email(" formato inválido.").required(" obrigatório."),
	phone: Yup.string()
		.max(16, " máximo 16 caracteres")
		.required(" obrigatório."),
	// partners: Yup.string().required(" obrigatório."),
	businessUnit: Yup.string().required(" obrigatório."),
	// description: Yup.string().required(" obrigatório."),
});

function MobilePhoneMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				"(",
				/[1-9]/,
				/\d/,
				")",
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			placeholderChar={"\u2000"}
		/>
	);
}

function PhoneMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				"(",
				/[1-9]/,
				/\d/,
				")",
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			placeholderChar={"\u2000"}
		/>
	);
}

const Form = (props) => {
	const {
		classes,
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleCancel,
		handleEdit,
		handleBack,
		businessUnitList,
		setFieldTouched,
		setFieldValue,
		disabledForm,
		editMode,
		newItem,
		chancellorMode,
	} = props;

	const change = (name, e) => {
		e.persist();
		handleChange(e);
		setFieldTouched(name, true, false);
	};

	return (
		<>
			<form>
				<GridContainer>
					{/* FORM CARD */}
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader style={{ padding: "28px" }}>
								<GridContainer>
									<GridItem xs={12} sm={8} md={7}>
										<Section
											title={
												chancellorMode
													? "Corretora chancelada"
													: "Corretora"
											}
										/>
									</GridItem>
									{/* <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            className={classes.marginRight}
                                            onClick={() => this.handleNew()}>Novo</Button>
                                    </GridItem> */}
									<GridItem
										xs={12}
										sm={3}
										md={5}
										style={{ textAlign: "right" }}
									>
										{editMode && (
											<Button
												color="primary"
												size="sm"
												className={classes.marginRight}
												onClick={handleSubmit}
											>
												Salvar
											</Button>
										)}
										{editMode && (
											<Button
												color="info"
												size="sm"
												className={classes.marginRight}
												onClick={handleCancel}
											>
												Cancelar
											</Button>
										)}
										{disabledForm && (
											<>
												<Button
													color="primary"
													size="sm"
													className={
														classes.marginRight
													}
													onClick={handleEdit}
												>
													Editar
												</Button>
												<Button
													color="info"
													size="sm"
													className={
														classes.marginRight
													}
													onClick={handleBack}
												>
													Voltar
												</Button>
											</>
										)}
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody style={{ padding: "0 28px" }}>
								<GridContainer>
									<GridItem xs={6} md={4}>
										<div
											className="picture-container-disabled"
											style={{ marginBottom: "37px" }}
										>
											<div
												className="picture"
												style={{ margin: "5px 0 0 0" }}
											>
												<img
													style={{
														objectFit: "cover",
														height: "100px",
														width: "100px",
													}}
													className="picture-src"
													src={
														values.logo &&
														values.logo !== ""
															? values.logo
															: avatar
													}
													alt="..."
												/>
											</div>
										</div>

										{/* {!disabledForm && <AvatarUpload
                                            avatar
                                            addButtonProps={{
                                                color: "success",
                                                round: true
                                            }}
                                            changeButtonProps={{
                                                color: "success",
                                                round: true
                                            }}
                                            removeButtonProps={{
                                                color: "danger",
                                                round: true,
                                                disabled: disabledForm
                                            }}
                                            value={values.logo}
                                        />} */}
									</GridItem>
								</GridContainer>
								<GridContainer>
									{/* NAME */}
									<GridItem xs={12} sm={4} md={4}>
										<CustomInput
											error={
												touched.name &&
												Boolean(errors.name)
											}
											labelText="Nome"
											id="name"
											name="name"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"name"
												),
												disabled: disabledForm,
												value: values.name,
											}}
										/>
									</GridItem>
									{/* BROKER NAME */}
									<GridItem xs={12} sm={4} md={4}>
										<CustomInput
											error={
												touched.brokerName &&
												Boolean(errors.brokerName)
											}
											labelText="Corretora"
											id="brokerName"
											name="brokerName"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"brokerName"
												),
												disabled: disabledForm,
												value: values.brokerName,
											}}
										/>
									</GridItem>
									{/* EMAIL */}
									<GridItem xs={12} sm={4} md={4}>
										<CustomInput
											error={
												touched.email &&
												Boolean(errors.email)
											}
											labelText={"Email"}
											id="email"
											name="email"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"email"
												),
												disabled: !newItem,
												value: values.email,
											}}
										/>
									</GridItem>
								</GridContainer>
								{/* L2 */}
								<GridContainer>
									{/* TELEFONE */}
									<GridItem xs={12} sm={6} md={6}>
										<CustomInput
											error={
												touched.phone &&
												Boolean(errors.phone)
											}
											labelText="Telefone"
											id="phone"
											name="phone"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"phone"
												),
												disabled: disabledForm,
												value: values.phone,
												inputComponent: PhoneMaskCustom,
											}}
										/>
									</GridItem>

									{/* CELULAR */}
									<GridItem xs={12} sm={6} md={6}>
										<CustomInput
											error={
												touched.mobilePhone &&
												Boolean(errors.mobilePhone)
											}
											labelText="Celular"
											id="mobilePhone"
											name="mobilePhone"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"mobilePhone"
												),
												disabled: disabledForm,
												value: values.mobilePhone,
												inputComponent: MobilePhoneMaskCustom,
											}}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											// error={touched.partners && Boolean(errors.partners)}
											labelText="Sócios"
											id="partners"
											name="partners"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: change.bind(
													null,
													"partners"
												),
												disabled: disabledForm,
												value: values.partners,
											}}
										/>
									</GridItem>
									<GridItem
										xs={12}
										sm={12}
										md={6}
										style={{ marginTop: "0px" }}
									>
										{businessUnitList.length > 0 && (
											<FormControl
												fullWidth
												className={cx(
													classes.selectFormControl,
													{
														[classes.disabled]: disabledForm,
													}
												)}
											>
												<InputLabel
													htmlFor="businessUnit"
													className={
														touched.businessUnit &&
														Boolean(
															errors.businessUnit
														)
															? styles.Error
															: classes.selectLabel
													}
												>
													Unidade de Negócio
												</InputLabel>
												<Select
													disabled={disabledForm}
													MenuProps={{
														className:
															classes.selectMenu,
													}}
													classes={{
														select: classes.select,
													}}
													id="businessUnit"
													name="businessUnit"
													value={values.businessUnit}
													inputProps={{
														onChange: change.bind(
															null,
															"businessUnit"
														),
													}}
												>
													{businessUnitList.map(
														(item) => (
															<MenuItem
																key={item.id}
																value={item.id}
																classes={{
																	root:
																		classes.selectMenuItem,
																	selected:
																		classes.selectMenuItemSelected,
																}}
															>
																{item.title}
															</MenuItem>
														)
													)}
												</Select>
											</FormControl>
										)}
									</GridItem>
								</GridContainer>

								{chancellorMode && (
									<GridContainer>
										<GridItem xs={12} sm={12} md={6}>
											<CustomInput
												error={
													touched.seal &&
													Boolean(errors.seal)
												}
												labelText="Chancela"
												id="seal"
												name="seal"
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													onChange: change.bind(
														null,
														"seal"
													),
													disabled: disabledForm,
													value: values.seal,
												}}
											/>
										</GridItem>
									</GridContainer>
								)}
								<hr />
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										{/* <InputLabel style={{ color: "#AAAAAA" }}>Descrição</InputLabel> */}
										<InputLabel
											htmlFor="Descrição"
											className={
												touched.description &&
												Boolean(errors.description)
													? styles.Error
													: classes.selectLabel
											}
										>
											Descrição
										</InputLabel>
										<CustomInput
											// error={touched.description && Boolean(errors.description)}
											id="description"
											name="description"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												multiline: true,
												onChange: change.bind(
													null,
													"description"
												),
												disabled: disabledForm,
												value: values.description,
												rows: 5,
											}}
										/>
									</GridItem>

									{chancellorMode && (
										<GridItem xs={12} sm={12} md={12}>
											{/* <InputLabel style={{ color: "#AAAAAA" }}>Descrição</InputLabel> */}
											<InputLabel
												htmlFor="Especialidade"
												className={
													touched.chancellorSpecialty &&
													Boolean(
														errors.chancellorSpecialty
													)
														? styles.Error
														: classes.selectLabel
												}
											>
												Especialidade
											</InputLabel>
											<CustomInput
												error={
													touched.chancellorSpecialty &&
													Boolean(
														errors.chancellorSpecialty
													)
												}
												id="chancellorSpecialty"
												name="chancellorSpecialty"
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													multiline: true,
													onChange: change.bind(
														null,
														"chancellorSpecialty"
													),
													disabled: disabledForm,
													value:
														values.chancellorSpecialty,
													rows: 5,
												}}
											/>
										</GridItem>
									)}
									{/* ATIVO */}
									{/* {this.props.user.id} !== {values.id} */}
									{/* {this.props.user.id !== values.id && */}
									<>
										<GridItem xs={12} sm={12} lg={1}>
											<FormLabel
												className={
													classes.labelHorizontal
												}
												style={{
													color: "#000",
													float: "none",
												}}
											>
												Ativo?
											</FormLabel>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<FormControlLabel
												style={{
													paddingTop: "30px",
													paddingLeft: "5px",
												}}
												control={
													<Switch
														name="isActive"
														id="isActive"
														disabled={disabledForm}
														checked={
															values.isActive
														}
														onChange={change.bind(
															null,
															"isActive"
														)}
														value={values.isActive}
														classes={{
															switchBase:
																classes.switchBase,
															checked:
																classes.switchChecked,
															thumb:
																classes.switchIcon,
															track:
																classes.switchBar,
															disabled:
																classes.disabled,
														}}
													/>
												}
												classes={{
													label:
														classes.label +
														" " +
														(disabledForm
															? styles.DisabledCursor
															: ""),
												}}
												label={
													values.isActive
														? "Sim"
														: "Não"
												}
											/>
										</GridItem>
										{/* Chancellor? */}
										<GridItem xs={12} sm={12} lg={1}>
											<FormLabel
												className={
													classes.labelHorizontal
												}
												style={{
													color: "#000",
													float: "none",
												}}
											>
												Chancelado?
											</FormLabel>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<FormControlLabel
												style={{
													paddingTop: "30px",
													paddingLeft: "5px",
												}}
												control={
													<Switch
														name="chancellors"
														id="chancellors"
														disabled={disabledForm}
														checked={
															values.chancellors
														}
														onChange={change.bind(
															null,
															"chancellors"
														)}
														value={
															values.chancellors
														}
														classes={{
															switchBase:
																classes.switchBase,
															checked:
																classes.switchChecked,
															thumb:
																classes.switchIcon,
															track:
																classes.switchBar,
															disabled:
																classes.disabled,
														}}
													/>
												}
												classes={{
													label:
														classes.label +
														" " +
														(disabledForm
															? styles.DisabledCursor
															: ""),
												}}
												label={
													values.chancellors
														? "Sim"
														: "Não"
												}
											/>
										</GridItem>
									</>
									{/* } */}
									<Clearfix />
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</form>
		</>
	);
};

class BrokerForm extends React.Component {
	constructor(props) {
		super(props);
		let formik = React.createRef();
		this.state = {
			id: "",
			name: "",
			brokerName: "",
			phone: "",
			mobilePhone: "",
			partners: "",
			seal: "",
			email: "",
			logo: "",
			description: "",
			newItem: false,
			chancellors: true,
			admin: false,
			password: "",
			businessUnit: "",
			businessUnitList: {},
			chancellorSpecialty: "",
			token: "",
			isActive: true,
			created: null,
			editMode: false,
			disabledForm: false,
			chancellorMode: false,
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		const id = this.props.match && this.props.match.params.id;
		//
		if (this.props.match.url.indexOf("chancellor") > -1) {
			this.setState({ chancellorMode: true });
		}
		//
		if (_.isEmpty(this.props.lsBusinessUnit)) {
			this.props.GetBusinessUnit();
		} else {
			this.setState({
				businessUnitList: _.orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
		}
		//
		let _new = _.includes(this.props.match.path, "new");
		let _edit = _.includes(this.props.match.path, "edit");
		if (_new) {
			this.setState({
				editMode: true,
				disabledForm: false,
				newItem: true,
			});
			this.props.HideLoader();
		} else if (_edit) {
			this.setState({ editMode: true, disabledForm: false });
		} else {
			this.setState({ editMode: false, disabledForm: true });
		}
		//
		let _brokerData = null;

		if (!_.isEmpty(this.props.lsBrokers) && id) {
			_brokerData =
				this.props.lsBrokers &&
				this.props.lsBrokers.find((e) => e.id === id);
			if (_brokerData) {
				this.setState({ ..._brokerData });
				this.props.HideLoader();
			}
		} else {
			if (id) {
				this.props.GetBrokerById(id);
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.user !== this.props.user) {
			// console.log("USER: ", this.props.user);
		}

		if (prevProps.brokerItem !== this.props.brokerItem) {
			this.setState({ ...this.props.brokerItem });
			this.props.HideLoader();
		}

		if (prevProps.lsBusinessUnit !== this.props.lsBusinessUnit) {
			this.setState({
				businessUnitList: _.orderBy(this.props.lsBusinessUnit, [
					"title",
					"asc",
				]),
			});
			this.props.HideLoader();
		}

		if (prevProps.brokerUpdate !== this.props.brokerUpdate) {
			// console.log("brokerUpdate: ", this.props.brokerUpdate);
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Operação realizada com Sucesso!
				</Alert>
			);
			setTimeout(() => this.handleBack(), 3000);
		}
		if (prevProps.insertBroker !== this.props.insertBroker) {
			// console.log("insertBroker: ", this.props.insertBroker);
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: "block" }}
					title={""}
					onConfirm={() => {
						this.handleBack();
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						" " +
						this.props.classes.primary
					}
					confirmBtnText={"OK"}
				>
					Operação realizada com Sucesso!
				</Alert>
			);
			setTimeout(() => this.handleBack(), 3000);
		}

		// FAILED
		if (prevProps.businessUnitsFailed !== this.props.businessUnitsFailed) {
			console.log(
				"BusinessUnit Failed: ",
				this.props.businessUnitsFailed
			);
			this.handleRequestFail("Erro ao consultar as unidades de negócios");
		}
		if (prevProps.brokersFailed !== this.props.brokersFailed) {
			console.log("Brokers Failed: ", this.props.brokersFailed);
			this.handleRequestFail("Erro ao consultar as corretoras");
		}
		if (prevProps.brokerItemFailed !== this.props.brokerItemFailed) {
			console.log("brokerItemFailed: ", this.props.brokerItemFailed);
			this.handleRequestFail("Erro ao consultar a corretora");
		}
		if (prevProps.brokerUpdateFailed !== this.props.brokerUpdateFailed) {
			console.log("brokerUpdateFailed: ", this.props.brokerUpdateFailed);
			this.handleRequestFail("Erro ao atualziar a corretora");
		}
		if (prevProps.insertBrokerFailed !== this.props.insertBrokerFailed) {
			console.log("insertBrokerFailed: ", this.props.insertBrokerFailed);
			this.handleRequestFail("Erro ao inserir a corretora");
		}
		// if (prevProps.brokerDeleteFailed !== this.props.brokerDeleteFailed) {
		//     console.log("brokerDeleteFailed: ", this.props.brokerDeleteFailed);
		//     this.handleRequestFail("Erro ao deletar a corretora");
		// }
	}

	handleRequestFail = (msg) => {
		this.props.HideLoader();
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title={"Atenção!"}
				onConfirm={() => {
					this.props.HideAlert();
				}}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				confirmBtnText={"OK"}
			>
				{msg}
			</Alert>
		);
	};

	handleEdit = () => {
		this.props.history.push(`/chancellor/edit/${this.state.id}`);
	};

	handleSave = (values) => {
		this.props.ShowLoader(<Loader />);
		this.setState({ editMode: false, disabledForm: true });
		//
		if (!values.chancellors) {
			values.chancellorSpecialty = "";
		}
		if (!this.state.id) {
			values.password = "prx@2020";
			this.props.PostBrokerData(values);
		} else {
			this.props.PutBroker(this.state.id, values);
		}
	};

	CancelForm() {
		this.setState({ editMode: false, disabledForm: true });
		this.props.HideLoader();
		this.props.HideAlert();

		if (this.state.chancellorMode) {
			this.props.history.push(`/chancellorsManagement`);
		} else {
			this.props.history.push(`/brokersManagement`);
		}
	}

	handleCancel = () => {
		if (this.state.chancellorMode) {
			this.props.history.push(`/chancellorsManagement`);
		} else {
			this.props.history.push(`/brokersManagement`);
		}
	};

	handleBack = () => {
		this.props.HideAlert();
		this.props.HideLoader();
		if (this.state.chancellorMode) {
			this.props.history.push(`/chancellorsManagement`);
		} else {
			this.props.history.push(`/brokersManagement`);
		}
	};

	handleChange = (event) => {
		const id = event.target.id || event.target.name;
		let value = event.target.value;
		//
		if (id === "isActive") {
			value = event.target.checked;
		}
		this.setState({ [id]: value });
	};

	render() {
		const { t, classes } = this.props;
		const {
			id,
			name,
			brokerName,
			phone,
			mobilePhone,
			seal,
			partners,
			email,
			logo,
			isActive,
			description,
			chancellors,
			admin,
			newItem,
			businessUnit,
			chancellorSpecialty,
			created,
			businessUnitList,
			editMode,
			disabledForm,
			chancellorMode,
		} = this.state;
		const values = {
			id,
			name,
			brokerName,
			phone,
			mobilePhone,
			seal,
			partners,
			email,
			logo,
			isActive,
			description,
			chancellors,
			admin,
			businessUnit,
			chancellorSpecialty,
			created,
		};

		return (
			<>
				<DocumentTitle
					title={
						chancellorMode
							? "PRx Consultoria - Corretora Chancelada"
							: "PRx Consultoria - Corretora"
					}
				/>
				<Formik
					ref={this.formik}
					render={(props) => (
						<Form
							{...props}
							classes={classes}
							handleCancel={this.handleCancel}
							handleBack={this.handleBack}
							handleEdit={this.handleEdit}
							newItem={newItem}
							editMode={editMode}
							disabledForm={disabledForm}
							businessUnitList={businessUnitList}
							idValid={this.state.id !== 0}
							chancellorMode={chancellorMode}
						/>
					)}
					validationSchema={validationSchema}
					onSubmit={this.handleSave.bind(this)}
					initialValues={values}
					enableReinitialize={true}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,

		lsBrokers: state.brokers.lsBrokers,
		brokerItem: state.brokers.brokerItem,
		brokerUpdate: state.brokers.brokerUpdate,
		insertBroker: state.brokers.insertBroker,
		brokersFailed: state.brokers.brokersFailed,
		brokerItemFailed: state.brokers.brokerItemFailed,
		brokerUpdateFailed: state.brokers.brokerUpdateFailed,
		insertBrokerFailed: state.brokers.insertBrokerFailed,

		imageUrl: state.images.imageUrl,
		imageFile: state.images.imageFile,

		lsBusinessUnit: state.businessUnit.lsBusinessUnit,
		businessUnitsFailed: state.businessUnit.businessUnitsFailed,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			SetImageData,
			ImageUpload,
			GetBrokers,
			GetBrokerById,
			PutBroker,
			PostBrokerData,
			GetBusinessUnit,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(userProfileStyles),
	withStyles(sweetAlertStyle),
	withStyles(extendedFormsStyle)
)(BrokerForm);
