import React, { Fragment } from "react";
import * as _ from "lodash";
import Skeleton from "react-loading-skeleton";
import Alert from "react-bootstrap-sweetalert";
import compose from "utils/compose";
import DocumentTitle from 'react-document-title';
import Section from "components/Section/Section.js";

import { withStyles } from "@material-ui/core/styles";

import Eyes from "@material-ui/icons/RemoveRedEye";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import Loader from "components/Loader/Loader.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// REDUX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GetBrokers, GetPartners, GetPartnerById, PutPartner, PostPartnerData, DeletePartner, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions';

class Partner extends React.Component {
    constructor(props) {
        super(props);
        this.modal = React.createRef();
        this.state = {
            partnersData: [],
            deleteItemId: ""
        }
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);
        this.props.GetPartners();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lsPartners !== this.props.lsPartners) {
            // console.log("---> ", this.props.lsPartners);
            this.setState({ partnersData: this.props.lsPartners });
            this.props.HideLoader();
        }

        // 
        if (prevProps.partnersFailed !== this.props.partnersFailed) {
            console.log("Partners Failed: ", this.props.partnersFailed);
            this.handleRequestFail("Erro ao consultar os parceiros");
        }

        //
        if (prevProps.partnerDelete !== this.props.partnerDelete) {
            // console.log("partnerDelete: ", this.props.partnerDelete);
            this.props.GetPartners();
            this.props.SweetAlert(
                <Alert
                    success
                    style={{ display: "block" }}
                    title={""}
                    onConfirm={() => { this.handleBack(); }}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.primary
                    }
                    confirmBtnText={"OK"}
                >
                    Parceiro deletado com sucesso!
                </Alert>
            )
            setTimeout(() => this.handleBack(), 3000);
        }
        if (prevProps.partnerDeleteFailed !== this.props.partnerDeleteFailed) {
            console.log("partnerDeleteFailed: ", this.props.partnerDeleteFailed);
            this.handleRequestFail("Erro ao deletar o comunicado");
        }
    }

    handleBack = () => {
        this.props.HideAlert();
        this.props.HideLoader();
        // this.props.history.push(`/manageCommunications`);
    }

    handleRequestFail = (msg) => {
        this.props.HideLoader();
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={"Atenção!"}
                onConfirm={() => { this.props.HideAlert(); }}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                confirmBtnText={"OK"}
            >
                {msg}
            </Alert>
        )
    }

    handleView = (id) => {
        this.props.history.push(`/partner/${id}`);
    }

    handleNew = () => {
        this.props.history.push(`/partner/new`);
    }

    handleEdit = (id) => {
        this.props.history.push(`/partner/edit/${id}`);
    }
    //
    askDelete = id => {
        this.setState({ deleteItemId: id });
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={""}
                onConfirm={this.handleDelete.bind(this)}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.info
                }
                confirmBtnText="Sim"
                cancelBtnText="Não"
                showCancel
            >
                Você tem certeza que deseja deletar este item?
            </Alert>
        );
    }

    handleDelete = () => {
        this.props.HideAlert();
        this.props.DeletePartner(this.state.deleteItemId);
    }
    //
    render() {
        const { t, classes } = this.props;
        const { partnersData, date } = this.state;

        return (
            <Fragment>
                <DocumentTitle title="PRx Consultoria - Parceiros" />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader style={{ padding: '28px', paddingBottom: '10px' }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={7}>
                                        <Section title="Parceiros" />
                                    </GridItem>
                                    {this.props.user.admin && <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            className={classes.marginRight}
                                            onClick={() => this.handleNew()}>Novo</Button>
                                    </GridItem>
                                    }
                                </GridContainer>
                            </CardHeader>
                            <CardBody style={{ padding: '28px' }}>
                                {partnersData && partnersData.length > 0 ?
                                    <Table
                                        striped
                                        tableHead={[
                                            "Titulo",
                                            "Categoria",
                                            "Telefone",
                                            "E-mail"
                                        ]}
                                        tableData={partnersData.map((item) => ([
                                            item.title, item.category, item.phone, item.email,
                                            <Fragment>
                                                <Button
                                                    justIcon
                                                    size="sm"
                                                    value={item.id}
                                                    color="primary"
                                                    onClick={this.handleView.bind(this, item.id)}
                                                >
                                                    <Eyes className={classes.icons} />
                                                </Button>
                                                {this.props.user.admin &&
                                                    <>
                                                        <Button
                                                            justIcon
                                                            size="sm"
                                                            value={item.id}
                                                            color="primary"
                                                            onClick={this.handleEdit.bind(this, item.id)}
                                                        >
                                                            <Edit className={classes.icons} />
                                                        </Button>
                                                        <Button
                                                            justIcon
                                                            size="sm"
                                                            value={item.id}
                                                            color="danger"
                                                            onClick={this.askDelete.bind(this, item.id)}
                                                        >
                                                            <Delete className={classes.icons} />
                                                        </Button>
                                                    </>
                                                }
                                            </Fragment>
                                        ]))}
                                        customCellClasses={[classes.center, classes.right, classes.right]}
                                        customClassesForCells={[0, 4, 5]}
                                        customHeadCellClasses={[
                                            classes.center,
                                            classes.right,
                                            classes.right
                                        ]}
                                        customHeadClassesForCells={[0, 4, 5]}
                                    />
                                    : <h5>Nenhum registro encontrado</h5>}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer >
            </Fragment >
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        lsPartners: state.partners.lsPartners,
        partnerDelete: state.partners.partnerDelete,
        partnersFailed: state.partners.partnersFailed,
        partnerDeleteFailed: state.partners.partnerDeleteFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetBrokers, GetPartners, GetPartnerById, PutPartner, PostPartnerData, DeletePartner, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedTablesStyle),
    withStyles(sweetAlertStyle),
)(Partner); 