import React, { Fragment } from "react";
import Alert from "react-bootstrap-sweetalert";
import * as _ from "lodash";

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Section from "components/Section/Section.js";

// style
import styles from "../changePassword.module.scss";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

// UTILS
import compose from "utils/compose";

class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			classes,
			values,
			errors,
			touched,
            handleSubmit,
			handleChange,
			setFieldTouched,
		} = this.props;

		const change = (name, e) => {
			e.persist();
			handleChange(e);
			setFieldTouched(name, true, false);
		};

		return (
			<Fragment>
				<form>
					<GridContainer>
						{/* FORM CARD */}
						<GridItem xs={12} sm={12} md={12}>
							<Card>
								<CardHeader style={{ padding: "28px" }}>
									<GridContainer>
										<GridItem xs={12} sm={8} md={7}>
											<Section title="Alterar Senha" />
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody style={{ padding: "0 28px" }}>
									<GridContainer>
										<GridItem xs={12} md={6}>
											<CustomInput
												formControlProps={{
													fullWidth: true,
												}}
												labelText="Senha antiga"
												id="oldPassword"
												name="oldPassword"
												error={
													touched.oldPassword &&
													Boolean(errors.oldPassword)
												}
												inputProps={{
													value: values.oldPassword,
													onChange: change.bind(
														null,
														"oldPassword"
													),
													type: "password",
												}}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem
											xs={12}
											md={6}
											style={{ marginTop: "7px" }}
										>
											<CustomInput
												formControlProps={{
													fullWidth: true,
												}}
												labelText="Nova senha"
												id="newPassword"
												name="newPassword"
												error={
													touched.newPassword &&
													Boolean(errors.newPassword)
												}
												inputProps={{
													value: values.newPassword,
													onChange: change.bind(
														null,
														"newPassword"
													),
													type: "password",
												}}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem
											xs={12}
											md={6}
											style={{ marginTop: "7px" }}
										>
											<CustomInput
												formControlProps={{
													fullWidth: true,
												}}
												labelText="Confirmar senha"
												id="confirmPassword"
												name="confirmPassword"
												error={
													touched.confirmPassword &&
													Boolean(
														errors.confirmPassword
													)
												}
												inputProps={{
													value:
														values.confirmPassword,
													onChange: change.bind(
														null,
														"confirmPassword"
													),
													type: "password",
												}}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer
										style={{ paddingTop: "30px" }}
									>
										<GridItem xs={12}>
											<Button
												size="sm"
												variant="contained"
												color="primary"
												className={styles.Button}
												onClick={handleSubmit}
											>
												Salvar
											</Button>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</form>
			</Fragment>
		);
	}
}

export default compose(withStyles(extendedFormsStyle))(ChangePassword);
