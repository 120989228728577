import React from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { loading, alert } from 'redux/actions';

import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "./routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
	const { ...rest } = props;

	const alert = useSelector(state => state.sweetAlert.alert);
	const loading = useSelector(state => state.loader.loading);
	const users = useSelector(state => state.users.users);
	const user = useSelector(state => state.authentication.user);
	const dispatch = useDispatch();

	// states and functions
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [miniActive, setMiniActive] = React.useState(false);
	const [image, setImage] = React.useState(require("assets/img/sidebar.jpg"));
	const [color, setColor] = React.useState("blue");
	const [bgColor, setBgColor] = React.useState("black");
	// const [hasImage, setHasImage] = React.useState(true);
	const [fixedClasses, setFixedClasses] = React.useState("dropdown");
	const [logo, setLogo] = React.useState(require("assets/img/logoTop.svg"));
	// styles
	const classes = useStyles();
	const mainPanelClasses =
		classes.mainPanel +
		" " +
		cx({
			[classes.mainPanelSidebarMini]: miniActive,
			[classes.mainPanelWithPerfectScrollbar]:
				navigator.platform.indexOf("Win") > -1
		});
	// ref for main panel div
	const mainPanel = React.createRef();
	// effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
	React.useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false
			});
			document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", resizeFunction);
		// VERIFY ROUTE PAGE TO HOME
		if (window.location.pathname === "/") {
			window.location = "/home";
		}

		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf("Win") > -1) {
				ps.destroy();
			}
			window.removeEventListener("resize", resizeFunction);
		};
	});
	// functions for changeing the states from components
	// const handleImageClick = image => {
	// 	setImage(image);
	// };
	// const handleColorClick = color => {
	// 	setColor(color);
	// };
	// const handleBgColorClick = bgColor => {
	// 	switch (bgColor) {
	// 		case "white":
	// 			setLogo(require("assets/img/logo.svg"));
	// 			break;
	// 		default:
	// 			setLogo(require("assets/img/logo-white.svg"));
	// 			break;
	// 	}
	// 	setBgColor(bgColor);
	// };
	// const handleFixedClick = () => {
	// 	if (fixedClasses === "dropdown") {
	// 		setFixedClasses("dropdown show");
	// 	} else {
	// 		setFixedClasses("dropdown");
	// 	}
	// };
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const getRoute = () => {
		return window.location.pathname !== "/admin/full-screen-maps";
	};
	const getActiveRoute = routes => {
		let activeRoute = "?";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				var path = routes[i].path.replace(':id', '');
				// if (window.location.href.indexOf(routes[i].path) !== -1) {
				if (window.location.href.indexOf(path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.layout === "/admin") {
				return (
					<Route
						path={prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	const sidebarMinimize = () => {
		setMiniActive(!miniActive);
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};

	return (
		<div className={classes.wrapper}>
			{alert}
			{loading}
			<Sidebar
				routes={routes}
				logoText={"PRx Consultoria"}
				logo={logo}
				image={image}
				handleDrawerToggle={handleDrawerToggle}
				open={mobileOpen}
				color={color}
				bgColor={bgColor}
				miniActive={miniActive}
				user={user}
				{...rest}
			/>
			<div className={mainPanelClasses} ref={mainPanel}>
				<AdminNavbar
					sidebarMinimize={sidebarMinimize.bind(this)}
					miniActive={miniActive}
					brandText={getActiveRoute(routes)}
					handleDrawerToggle={handleDrawerToggle}
					{...rest}
				/>
				{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
				{getRoute() ? (
					<div className={classes.content}>
						<div className={classes.container}>
							<Switch>
								{getRoutes(routes)}
								<Redirect from="/admin" to="/home" />
							</Switch>
						</div>
					</div>
				) : (
						<div className={classes.map}>
							<Switch>
								{getRoutes(routes)}
								<Redirect from="/admin" to="/home" />
							</Switch>
						</div>
					)}
				{/* {getRoute() ? <Footer fluid /> : null} */}
			</div>
		</div>
	);
}
