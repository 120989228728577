import React from "react";
import DocumentTitle from 'react-document-title';
import * as _ from "lodash";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import compose from "utils/compose";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { login, logout } from 'redux/actions';

class LoginPage extends React.Component {
	constructor(props) {
		super(props);
		this.props.logout();
		this.state = {
			cardAnimaton: "cardHidden",
			login: "",
			loginState: "",
			password: "",
			passwordState: "",
			submitted: false
		}
	}

	componentDidMount() {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		this.timeOutFunction = setTimeout(
			function () {
				this.setState({ cardAnimaton: "" });
			}.bind(this),
			700
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.logginFailed !== this.props.logginFailed) {
			if (this.props.logginFailed) {
				this.setState({
					login: "",
					password: ""
				})
			}
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeOutFunction);
		this.timeOutFunction = null;
	}

	change(event, stateName, type) {
		switch (type) {
			case "login":
				if (this.verifyLength(event.target.value, 1)) {
					this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
				} else {
					this.setState({ [stateName + "State"]: "error", [stateName]: "" });
				}
				break;
			case "password":
				if (this.verifyLength(event.target.value, 1)) {
					this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
				} else {
					this.setState({ [stateName + "State"]: "error", [stateName]: "" });
				}
				break;
			default:
				break;
		}
	}

	// function that verifies if a string has a given length or not
	verifyLength(value, length) {
		if (value.length >= length) {
			return true;
		}
		return false;
	}

	submit = (e) => {
		e.preventDefault();
		this.setState({ submitted: true });
		const { login, password } = this.state;
		if (login && password) {
			this.props.login(login, password);
		}
	}

	render() {
		const { t, classes, loggingIn, loggedIn, logginFailed } = this.props;
		return (
			<div className={classes.container} >
				<DocumentTitle title={'PRx Consultoria - Entrar'} />
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={4}>
						<form name="form" onSubmit={this.submit}>
							<Card login className={classes[this.state.cardAnimaton]}>
								<CardHeader
									className={`${classes.cardHeader} ${classes.textCenter}`}
									color="primary"
								>
									<h4 className={classes.cardTitle}>PRx Consultoria</h4>
									<h5 className={classes.cardTitle}>Log in</h5>
									{/* <div className={classes.socialLine}>
										{[
											"fab fa-facebook-square",
											"fab fa-twitter",
											"fab fa-google-plus"
										].map((prop, key) => {
											return (
												<Button
													color="transparent"
													justIcon
													key={key}
													className={classes.customButtonClass}
												>
													<i className={prop} />
												</Button>
											);
										})}
									</div> */}
								</CardHeader>
								<CardBody>
									{/* <CustomInput
										labelText="First Name.."
										id="firstname"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Face className={classes.inputAdornmentIcon} />
												</InputAdornment>
											)
										}}
									/> */}
									<CustomInput
										id="login"
										name="login"
										labelText="Email"
										success={this.state.loginState === "primary"}
										error={this.state.loginState === "error"}
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Email className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											value: this.state.login,
											onChange: event =>
												this.change(event, "login", "login")
										}}
									/>
									<CustomInput
										id="password"
										name="password"
										labelText="Senha"
										success={this.state.passwordState === "primary"}
										error={this.state.passwordState === "error"}
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Icon className={classes.inputAdornmentIcon}>
														lock_outline
                        							</Icon>
												</InputAdornment>
											),
											value: this.state.password,
											onChange: event =>
												this.change(event, "password", "password"),

											type: "password"
										}}
									/>
									{logginFailed &&
										<div style={{ textAlign: "center" }}>
											<Typography color="error" style={{ fontWeight: 'bold' }}>
												Falha no login
											</Typography>
										</div>
									}

									{loggingIn &&
										<div style={{ textAlign: "center" }}>
											<CircularProgress style={{ color: "#3b5998" }} color="inherit" />
										</div>
									}
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<Button
										color="primary"
										variant="contained"
										type="submit"
										size="sm"
										disabled={loggingIn}
										block>
										Enviar
									</Button>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { loggingIn, loggedIn, logginFailed } = state.authentication;

	return {
		loggingIn,
		loggedIn,
		logginFailed
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ login, logout }, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(loginPageStyle),
)(LoginPage);