import React, { Fragment } from "react";
import compose from "utils/compose";
import { Switch, Route, Redirect } from "react-router-dom";

import LoginPage from './LoginPage.js';

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import authStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import register from "assets/img/register.jpeg";
import login from "assets/img/header-doc.jpeg";
import error from "assets/img/clint-mckoy.jpg";

class Auth extends React.Component {
	// const { ...rest } = props;
	// // ref for the wrapper div
	// const wrapper = React.createRef();
	// // styles
	// const classes = useStyles();
	// React.useEffect(() => {
	// 	document.body.style.overflow = "unset";
	// 	// Specify how to clean up after this effect:
	// 	return function cleanup() { };
	// });
	// const getRoutes = routes => {
	// 	return routes.map((prop, key) => {
	// 		if (prop.collapse) {
	// 			return getRoutes(prop.views);
	// 		}
	// 		if (prop.layout === "/auth") {
	// 			return (
	// 				<Route
	// 					path={prop.layout + prop.path}
	// 					component={prop.component}
	// 					key={key}
	// 				/>
	// 			);
	// 		} else {
	// 			return null;
	// 		}
	// 	});
	// };
	getBgImage = () => {
		if (window.location.pathname.indexOf("/auth/register") !== -1) {
			return register;
		} else if (window.location.pathname.indexOf("/auth/login") !== -1) {
			return login;
		} else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
			return error;
		}
	};
	// const getActiveRoute = routes => {
	// 	let activeRoute = "Default Brand Text";
	// 	for (let i = 0; i < routes.length; i++) {
	// 		if (routes[i].collapse) {
	// 			let collapseActiveRoute = getActiveRoute(routes[i].views);
	// 			if (collapseActiveRoute !== activeRoute) {
	// 				return collapseActiveRoute;
	// 			}
	// 		} else {
	// 			if (
	// 				window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
	// 			) {
	// 				return routes[i].name;
	// 			}
	// 		}
	// 	}
	// 	return activeRoute;
	// };
	render() {
		const { classes } = this.props;
		return (
			<Fragment>

				<div
					className={classes.fullPage}
					style={{ backgroundImage: "url(" + this.getBgImage() + ")" }}
				>
					<Route
						path="/auth/login"
						component={LoginPage}
					/>

					{/* <Footer white /> */}
				</div>

			</Fragment >
		);
	}
}
export default compose(
	withStyles(authStyle)
)(Auth);