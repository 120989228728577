import * as actionTypes from "./actionsTypes";
import * as API from "../api/broker.js";
import * as Loader from "./loader";

// GET ALL
export const GetBrokers = () => {
	return (dispatch) =>
		API.GetBrokers()
			.then((rest) => {
				dispatch(success(rest));
				return rest;
			})
			.catch((err) => {
				console.log(err);
				dispatch(failed(err));
			});

	function success(data) {
		return {
			type: actionTypes.GET_BROKERS_DATA,
			lsBrokers: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.GET_BROKERS_DATA_FAILED,
			brokersFailed: error,
		};
	}
};

// GET BY ID
export const GetBrokerById = (id) => {
	return (dispatch) =>
		API.GetBrokerById(id)
			.then((rest) => {
				dispatch(success(rest));
			})
			.catch((err) => {
				console.log(err);
				dispatch(failed(err));
			});

	function success(data) {
		return {
			type: actionTypes.GET_BROKER_BY_ID,
			brokerItem: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.GET_BROKER_BY_ID_FAILED,
			brokerItemFailed: error,
		};
	}
};

// REGISTER

// UPDATE
export const PutBroker = (id, obj) => {
	return (dispatch) =>
		API.PutBroker(id, obj)
			.then((response) => {
				dispatch(success(response));
			})
			.catch((error) => {
				console.error(error);
				dispatch(failed(error));
				dispatch(Loader.hideLoader());
			});

	function success(data) {
		return {
			type: actionTypes.PUT_BROKER_DATA,
			brokerUpdate: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.PUT_BROKER_FAILED,
			brokerUpdateFailed: error,
		};
	}
};

// POST
export const PostBrokerData = (obj) => {
	return (dispatch) => {
		API.PostBroker(obj)
			.then((response) => {
				dispatch(success(response));
			})
			.catch((error) => {
				console.error(error);
				dispatch(failed());
				dispatch(Loader.hideLoader());
			});
	};

	function success(data) {
		return {
			type: actionTypes.POST_BROKER_DATA,
			insertBroker: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.POST_BROKER_DATA_FAILED,
			insertBrokerFailed: error,
		};
	}
};

// DELETE
export const DeleteBroker = (id) => {
	return (dispatch) => {
		API.DeleteBroker(id)
			.then((response) => {
				dispatch(success(response));
			})
			.catch((error) => {
				console.error(error);
				dispatch(failed());
				dispatch(Loader.hideLoader());
			});
	};

	function success(data) {
		return {
			type: actionTypes.POST_BROKER_DATA,
			brokerDelete: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.POST_BROKER_DATA_FAILED,
			brokerDeleteFailed: error,
		};
	}
};

// ChangePassword
export const ChangePasswordData = (id, obj) => {
	return (dispatch) => {
		API.ChangePassword(id, obj)
			.then((response) => {
				dispatch(success(response));
			})
			.catch((error) => {
				console.error(error);
				dispatch(failed(error));
				dispatch(Loader.hideLoader());
			});
	};

	function success(data) {
		return {
			type: actionTypes.CHANGE_PASSWORD,
			changePassword: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.CHANGE_PASSWORD_FAILURE,
			changePasswordFailed: error,
		};
	}
};

// ResetPassword
export const ResetPassword = (id) => {
	return (dispatch) => {
		API.ResetPassword(id)
			.then((response) => {
				dispatch(success(response));
			})
			.catch((error) => {
				console.error(error);
				dispatch(failed(error));
				dispatch(Loader.hideLoader());
			});
	};

	function success(data) {
		return {
			type: actionTypes.RESET_PASSWORD,
			resetPassword: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.RESET_PASSWORD_FAILED,
			resetPasswordFailed: error,
		};
	}
};
