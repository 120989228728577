import * as actionTypes from "./actionsTypes";
import * as API from "../api/partner.js";
import * as Loader from './loader';

// GET ALL
export const GetPartners = () => {
    return dispatch => (
        API.GetPartners().then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_PARTNERS_DATA,
            lsPartners: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_PARTNERS_DATA_FAILED,
            partnersFailed: error
        };
    };
}

// GET BY ID
export const GetPartnerById = (id) => {
    return dispatch => (
        API.GetPartnerById(id).then(rest => {
            dispatch(success(rest));
        }).catch(err => {
            console.log(err);
            dispatch(failed(err));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_PARTNER_BY_ID,
            partnerItem: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_PARTNER_BY_ID_FAILED,
            partnerItemFailed: error
        };
    };
}

// UPDATE
export const PutPartner = (id, obj) => {
    return dispatch => (
        API.PutPartner(id, obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.PUT_PARTNER_DATA,
            partnerUpdate: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.PUT_PARTNER_FAILED,
            partnerUpdateFailed: error
        };
    };
}

// POST
export const PostPartnerData = (obj) => {
    return dispatch => {
        API.PostPartner(obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.POST_PARTNER_DATA,
            insertPartner: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.POST_PARTNER_DATA_FAILED,
            insertPartnerFailed: error
        };
    };
}

// DELETE
export const DeletePartner = (id) => {
    return dispatch => {
        API.DeletePartner(id).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed());
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.DELETE_PARTNER_DATA,
            partnerDelete: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.DELETE_PARTNER_FAILED,
            partnerDeleteFailed: error
        };
    };
}