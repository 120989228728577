import axios from 'axios';

// @Get()
export const GetPartners = () => {
    return axios.get(`/partner`)
        .then(response => response.data);
}

// @Get(':id') 
export const GetPartnerById = (id) => {
    return axios.get(`/partner/${id}`)
        .then(response => response.data);
}

// @Put(":id")
export const PutPartner = (id, obj) => {
    return axios.put(`/partner/${id}`, obj)
        .then(response => response.data);
}

// @Post()
export const PostPartner = (obj) => {
    return axios.post(`/partner/`, obj)
        .then(response => response.data);
}

// @Delete(":id")
export const DeletePartner = (id) => {
    return axios.delete(`/partner/${id}`)
        .then(response => response.data);
}