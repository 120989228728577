import axios from 'axios';

// POST IMAGE
export const ImageUpload = (obj) => {
    let formData = new FormData();
    formData.append("file", obj);
    return axios.post(`/fileUpload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => response.data);
}

// GET IMAGE
export const GetImage = (name) =>
    axios.get(`/fileUpload/${name}`)
        .then(response => response.data);

/*
        let formData = new FormData();
        for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('file', this.state.imgCollection[key])
        }
        axios.post(`/fileUpload`, formData).then(function (res) {
            console.log('SUCCESS! -> ', res);
        }).catch(function (error) {
            console.log('FAILURE! -> ', error);
        });

export const FilesUpload = (obj) => {
    let formData = new FormData();
    for (var i = 0; i < obj.length; i++) {
        formData.append(`file`, obj[i]);
    }
    return axios.post(`/fileUpload`, formData)
        .then(response => response.data);
}
*/