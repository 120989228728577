import * as actionTypes from "./actionsTypes";
import * as API from "../api/communication.js";
import * as Loader from './loader';

// GET ALL
export const GetCommunications = () => {
    return dispatch => (
        API.GetCommunications().then(rest => {
            dispatch(success(rest));
        }).catch(error => {
            console.log(error);
            dispatch(failed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_COMMUNICATIONS_DATA,
            lsCommunications: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_COMMUNICATIONS_DATA_FAILED,
            communicationsFailed: error
        };
    };
}

// GET BY ID
export const GetCommunicationById = (id) => {
    return dispatch => (
        API.GetCommunicationById(id).then(rest => {
            dispatch(success(rest));
        }).catch(error => {
            dispatch(failed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_COMMUNICATION_BY_ID,
            communicationItem: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_COMMUNICATION_BY_ID_FAILED,
            communicationItemFailed: error
        };
    };
}

// UPDATE
export const PutCommunication = (id, obj) => {
    return dispatch => (
        API.PutCommunication(id, obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.PUT_COMMUNICATION_DATA,
            communicationUpdate: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.PUT_COMMUNICATION_FAILED,
            communicationUpdateFailed: error
        };
    };
}

// POST
export const PostCommunicationData = (obj) => {
    return dispatch => {
        API.PostCommunication(obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.POST_COMMUNICATION_DATA,
            insertCommunication: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.POST_COMMUNICATION_DATA_FAILED,
            insertCommunicationFailed: error
        };
    };
}

// DELETE
export const DeleteCommunication = (id) => {
    return dispatch => {
        API.DeleteCommunication(id).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.DELETE_COMMUNICATION_DATA,
            communicationDelete: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.DELETE_COMMUNICATION_FAILED,
            communicationDeleteFailed: error
        };
    };
}