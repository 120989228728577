import React, { Component, Fragment } from 'react';
import Alert from "react-bootstrap-sweetalert";

import Tooltip from "@material-ui/core/Tooltip";

import Button from "components/CustomButtons/Button.js";
import Loader from "components/Loader/Loader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {DeleteObject, GetAttachments, GetAttachmentsById, PutAttachments, PostAttachmentsData, DeleteAttachments, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions';

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles.module.scss";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

class AttachmentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idSource: props.objId,
            deleteItemId: "",
            originalName: "",
            filesObj: [],
            editMode: props.editMode
        }
    }

    componentDidMount() {
        this.props.GetAttachmentsById(this.state.idSource);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.attachmentItem !== this.props.attachmentItem) {
            this.setState({ filesObj: this.props.attachmentItem });
            console.log(">>> attachmentItem: ", this.props.attachmentItem);
        }

        if (prevProps.insertAttachment !== this.props.insertAttachment) {
            // console.log(">>> insertAttachment: ", this.props.insertAttachment);
        }

        if (prevProps.attachmentDelete !== this.props.attachmentDelete) {
            if (this.props.attachmentDelete.deleted) {
                // console.log(">>> attachmentDelete: ", this.props.attachmentDelete);
                this.props.SweetAlert(
                    <Alert
                        success
                        style={{ display: "block" }}
                        title={""}
                        onConfirm={() => { this.props.HideLoader(); this.props.HideAlert(); this.props.GetAttachmentsById(this.state.idSource); }}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText={"OK"}
                    >
                        Documento deletado com sucesso!
                </Alert>
                )
            }
        }

        if (prevProps.fileDelete !== this.props.fileDelete) {
            console.log("fileDelete: ", this.props.fileDelete);
        }
        if (prevProps.fileDeleteFailed !== this.props.fileDeleteFailed) {
            console.log("fileDeleteFailed: ", this.props.fileDeleteFailed);
        }
    }

    askDelete = (id, fileName, originalName) => {
        this.setState({ deleteItemId: id, originalName: originalName });
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={""}
                onConfirm={this.handleDelete.bind(this)}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.info
                }
                confirmBtnText="Sim"
                cancelBtnText="Não"
                showCancel
            >
                Você tem certeza que deseja deletar o arquivo <b>{originalName}</b>?
            </Alert>
        );
    }

    handleDelete = () => {
        this.props.ShowLoader(<Loader />);
        this.props.DeleteAttachments(this.state.deleteItemId);
        this.props.DeleteObject(this.state.originalName);
    }

    render() {
        const { filesObj, editMode } = this.state;

        return (
            <>
                <GridItem xs={12} sm={1}><b>Anexos: </b></GridItem>
                <GridItem xs={12} sm={11}>
                    {
                        filesObj && filesObj.length > 0 ? filesObj.map(item => [
                            <Fragment key={item.id}>
                                <span className={styles.attachItem}>
                                    <a
                                        className={styles.pr15}
                                        href={item.fileUrl}
                                        target="_blank"
                                        title={item.originalName}>
                                        {item.originalName}
                                    </a>
                                    {editMode &&
                                        <span
                                            className={styles.deleteBt}
                                            title="Deletar"
                                            onClick={this.askDelete.bind(this, item.id, item.fileName, item.originalName)}
                                        > x </span>}
                                </span>
                            </Fragment>
                        ])
                            : "Nenhum resultado encontrado."
                    }
                </GridItem>
            </>

        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        lsInsurers: state.insurer.lsInsurers,
        insurerItem: state.insurer.insurerItem,
        insertInsurer: state.insurer.insertInsurer,
        insurerUpdate: state.insurer.insurerUpdate,
        insurerDelete: state.insurer.insurerDelete,

        insurersFailed: state.insurer.insurersFailed,
        insurerUpdateFailed: state.insurer.insurerUpdateFailed,
        insurerItemFailed: state.insurer.insurerItemFailed,
        insertInsurerFailed: state.insurer.insertInsurerFailed,
        insurerDeleteFailed: state.insurer.insurerDeleteFailed,

        filesPostObj: state.filesUpload.filesPostObj,
        filesObj: state.filesUpload.filesObj,
        getFiles: state.filesUpload.getFiles,
        fileDelete: state.filesUpload.fileDelete,
        fileDeleteFailed: state.filesUpload.fileDeleteFailed,
        getFilesFailed: state.filesUpload.getFilesFailed,
        filesFailed: state.filesUpload.filesFailed,

        lsAttachments: state.attachments.lsAttachments,
        attachmentItem: state.attachments.attachmentItem,
        attachmentUpdate: state.attachments.attachmentUpdate,
        insertAttachment: state.attachments.insertAttachment,
        attachmentDelete: state.attachments.attachmentDelete,

        attachmentsFailed: state.attachments.attachmentsFailed,
        attachmentItemFailed: state.attachments.attachmentItemFailed,
        attachmentUpdateFailed: state.attachments.attachmentUpdateFailed,
        insertAttachmentFailed: state.attachments.insertAttachmentFailed,
        attachmentDeleteFailed: state.attachments.attachmentDeleteFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { DeleteObject, GetAttachments, GetAttachmentsById, PutAttachments, PostAttachmentsData, DeleteAttachments, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sweetAlertStyle),
)(AttachmentList); 