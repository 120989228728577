import * as actionTypes from './actionsTypes';
import * as API from '../api/images';

export const setImageData = (data) => {
    return {
        type: actionTypes.SET_IMAGE_DATA,
        imageFile: data
    };
};

export const postImageData = (data) => {
    return {
        type: actionTypes.POST_IMAGE_DATA,
        imageObj: data
    };
};

export const fetchImage = (data) => {
    return {
        type: actionTypes.GET_IMAGE_DATA,
        getImage: data
    };
};

// FAILED 

export const fetchImagesFailed = (error) => {
    return {
        type: actionTypes.GET_IMAGE_FAILED,
        getImageFailed: error
    };
};

export const postImageFailed = (error) => {
    return {
        type: actionTypes.POST_IMAGE_FAILED,
        imageFailed: error
    };
};

export const ImageUpload = (data) => {
    return dispatch => (
        API.ImageUpload(data).then(response => {
            dispatch(postImageData(response));
            dispatch(setImageData(null));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(postImageFailed());
        })
    );
};
//-------------------------------------------------------

export const SetImageData = (data) => {
    return dispatch => (
        dispatch(setImageData(data))
    );
};

export const GetImage = (name) => {
    return dispatch => (
        API.GetImage(name).then(response => {
            dispatch(fetchImage(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchImagesFailed());
        })
    );
}