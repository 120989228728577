import axios from 'axios';

// @Get()
export const GetCommunications = () => {
    return axios.get(`/communication`)
        .then(response => response.data);
}

// @Get(':id') 
export const GetCommunicationById = (id) => {
    return axios.get(`/communication/${id}`)
        .then(response => response.data);
}

// @Put(":id")
export const PutCommunication = (id, obj) => {
    return axios.put(`/communication/${id}`, obj)
        .then(response => response.data);
}

// @Post()
export const PostCommunication = (obj) => {
    return axios.post(`/communication/`, obj)
        .then(response => response.data);
}

// @Delete(":id")
export const DeleteCommunication = (id) => {
    return axios.delete(`/communication/${id}`)
        .then(response => response.data);
}