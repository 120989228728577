import * as actionTypes from "./actionsTypes";
import * as API from "../api/document.js";
import * as Loader from './loader';

// GET ALL
export const GetDocuments = () => {
    return dispatch => (
        API.GetDocuments().then(rest => {
            dispatch(success(rest));
        }).catch(error => {
            console.log(error);
            dispatch(failed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_DOCUMENTS_DATA,
            lsDocuments: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_DOCUMENTS_DATA_FAILED,
            documentsFailed: error
        };
    };
}

// GET BY ID
export const GetDocumentById = (id) => {
    return dispatch => (
        API.GetDocumentById(id).then(rest => {
            dispatch(success(rest));
        }).catch(error => {
            dispatch(failed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.GET_DOCUMENT_BY_ID,
            documentItem: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.GET_DOCUMENT_BY_ID_FAILED,
            documentItemFailed: error
        };
    };
}

// UPDATE
export const PutDocument = (id, obj) => {
    return dispatch => (
        API.PutDocument(id, obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.PUT_DOCUMENT_DATA,
            documentUpdate: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.PUT_DOCUMENT_FAILED,
            documentUpdateFailed: error
        };
    };
}

// POST
export const PostDocumentData = (obj) => {
    return dispatch => {
        API.PostDocument(obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.POST_DOCUMENT_DATA,
            insertDocument: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.POST_DOCUMENT_DATA_FAILED,
            insertDocumentFailed: error
        };
    };
}

// DELETE
export const DeleteDocument = (id) => {
    return dispatch => {
        API.DeleteDocument(id).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    };

    function success(data) {
        return {
            type: actionTypes.DELETE_DOCUMENT_DATA,
            documentDelete: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.DELETE_DOCUMENT_FAILED,
            documentDeleteFailed: error
        };
    };
}