import React, { Fragment } from 'react';
import Alert from "react-bootstrap-sweetalert";
import * as _ from "lodash";
import DocumentTitle from 'react-document-title';

// UTILS
import compose from 'utils/compose';

import withStyles from "@material-ui/core/styles/withStyles";

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ChangePasswordData, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions';

import { Formik } from "formik";
import * as Yup from "yup";
import Form from "./Component/ChangePassword";

// MOMENT JS
import * as moment from 'moment';

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const validationSchema = Yup.object({
    oldPassword: Yup.string().min(4).required("Senha antiga é obrigatório."),
    newPassword: Yup.string().min(4).required("Senha nova é obrigatório."),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Campos senha nova não batem.').required("Confirme a nova senha.")
});

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.formik = React.createRef();
        this.state = {
            id: "",
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
    }

    componentDidMount() {
        const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
        this.setState({ id: user.id });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userData !== this.props.userData) {
            this.setState({ id: this.props.user.id });
        }

        if (this.props.changePassword && prevProps.changePassword !== this.props.changePassword) {
            this.props.SweetAlert(
                <Alert
                    success
                    style={{ display: "block" }}
                    title={null}
                    onConfirm={() => { this.props.HideAlert(); this.props.HideLoader(); }}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.primary
                    }
                    confirmBtnText={"OK"}
                >
                    Senha altarada com sucesso!
                </Alert>
            );
            setTimeout(() => { this.props.HideAlert(); this.props.HideLoader(); }, 3000);
            this.setState({ oldPassword: "", newPassword: "", confirmPassword: "" })
        }

        if (this.props.changePasswordFailed && prevProps.changePasswordFailed !== this.props.changePasswordFailed) {
            this.props.SweetAlert(
                <Alert
                    warning
                    style={{ display: "block" }}
                    title={"Error"}
                    onConfirm={() => { this.props.HideAlert(); this.props.HideLoader(); }}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.primary
                    }
                    confirmBtnText={"OK"}
                >
                    Erro ao alterar a senha.
                </Alert>
            );
            setTimeout(() => { this.props.HideAlert(); this.props.HideLoader(); }, 3000);
        }
    }
    //
    handleSave = (values) => {
        let obj = {
            "oldPassword": values.oldPassword,
            "password": values.newPassword
        }
        this.props.ChangePasswordData(this.state.id, obj);
    }

    render() {
        const { t } = this.props;
        const { oldPassword, newPassword, confirmPassword } = this.state;
        const values = { oldPassword, newPassword, confirmPassword };
        return (
            <Fragment>
                <DocumentTitle title={'PRx Consultoria - Alterar Senha'} />

                <Formik
                    ref={this.formik}
                    render={(props) => <Form {...props} />}
                    validationSchema={validationSchema}
                    initialValues={values}
                    onSubmit={this.handleSave.bind(this)}
                />
            </Fragment>
        )
    }
}
const mapStateToProps = state => {

    return {
        user: state.authentication.user,
        userData: state.users.userData,
        changePassword: state.brokers.changePassword,
        changePasswordFailed: state.brokers.changePasswordFailed
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { ChangePasswordData, SweetAlert, HideAlert, ShowLoader, HideLoader }
    , dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedFormsStyle),
    withStyles(sweetAlertStyle),
)(ChangePassword);