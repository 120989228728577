import React, { Fragment } from "react";
import * as _ from "lodash";
import Skeleton from "react-loading-skeleton";
import compose from "utils/compose";
import DocumentTitle from 'react-document-title';
import Section from "components/Section/Section.js";
import Alert from "react-bootstrap-sweetalert";
import { withStyles } from "@material-ui/core/styles";

import Assignment from "@material-ui/icons/Assignment";
import Eyes from "@material-ui/icons/RemoveRedEye";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";

import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// core components
import AttachmentList from "components/AttachmentList/AttachmentList.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Loader from "components/Loader/Loader.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// REDUX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GetBrokers, GetCommunications, GetCommunicationById, PutCommunication, PostCommunicationData, DeleteCommunication, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

let modal = React.createRef();

class Communication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            brokersList: [],
            deleteItemId: "",
            communicationsData: []
        }
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);
        if (_.isEmpty(this.props.lsBrokers)) {
            this.props.GetBrokers().then(() => {
                this.props.GetCommunications();
            });
        } else {
            this.props.GetCommunications();
            this.setState({ brokersList: this.props.lsBrokers });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lsCommunications !== this.props.lsCommunications) {
            //let comm = _.filter(this.props.lsCommunications, ['approved', true]);
            this.setState({ communicationsData: this.props.lsCommunications, ...this.props.lsCommunications });
            this.props.HideLoader();
        }

        if (prevProps.lsBrokers !== this.props.lsBrokers) {
            this.setState({ brokersList: this.props.lsBrokers });
        }

        if (prevProps.communicationItem !== this.props.communicationItem) {
            // console.log("communicationItem: ", this.props.communicationItem);
        }
        if (prevProps.communicationUpdate !== this.props.communicationUpdate) {
            // console.log("communicationUpdate: ", this.props.communicationUpdate);
        }
        if (prevProps.insertCommunication !== this.props.insertCommunication) {
            // console.log("insertCommunication: ", this.props.insertCommunication);
        }
        if (prevProps.communicationDelete !== this.props.communicationDelete) {
            // console.log("communicationDelete: ", this.props.communicationDelete);
            this.props.GetCommunications();
            this.props.SweetAlert(
                <Alert
                    success
                    style={{ display: "block" }}
                    title={""}
                    onConfirm={() => { this.handleBack(); }}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.primary
                    }
                    confirmBtnText={"OK"}
                >
                    Comunicado deletado com sucesso!
                </Alert>
            )
            setTimeout(() => this.handleBack(), 3000);
        }
        //
        // FAILED
        if (prevProps.communicationsFailed !== this.props.communicationsFailed) {
            console.log("communicationsFailed: ", this.props.communicationsFailed);
            this.handleRequestFail("Erro ao consultar os comunicados");
        }
        if (prevProps.communicationItemFailed !== this.props.communicationItemFailed) {
            console.log("communicationItemFailed: ", this.props.communicationItemFailed);
            this.handleRequestFail("Erro ao consultar o comunicado");
        }
        if (prevProps.communicationUpdateFailed !== this.props.communicationUpdateFailed) {
            console.log("communicationUpdateFailed: ", this.props.communicationUpdateFailed);
            this.handleRequestFail("Erro ao atualizar o comunicado");
        }
        if (prevProps.insertCommunicationFailed !== this.props.insertCommunicationFailed) {
            console.log("insertCommunicationFailed: ", this.props.insertCommunicationFailed);
            this.handleRequestFail("Erro ao inserir o comunicado");
        }
        if (prevProps.communicationDeleteFailed !== this.props.communicationDeleteFailed) {
            console.log("communicationDeleteFailed: ", this.props.communicationDeleteFailed);
            this.handleRequestFail("Erro ao deletar o comunicado");
        }
    }

    handleBack = () => {
        this.props.HideAlert();
        this.props.HideLoader();
        // this.props.history.push(`/manageCommunications`);
    }

    handleRequestFail = (msg) => {
        this.props.HideLoader();
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={"Atenção!"}
                onConfirm={() => { this.props.HideAlert(); }}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                confirmBtnText={"OK"}
            >
                {msg}
            </Alert>
        )
    }

    handleView = (item) => {
        this.setState({ ...item });
        this.setState({ open: true }, () => {
            if (modal.current) {
                modal.current.innerHTML = item.description;
            }
        });
    }

    handleNew = () => {
        this.props.history.push(`/manageCommunications/new`);
    }
    //
    handleEdit = (id) => {
        this.props.history.push(`/manageCommunications/edit/${id}`);
    }
    //
    askDelete = id => {
        this.setState({ deleteItemId: id });
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={""}
                onConfirm={this.handleDelete.bind(this)}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.info
                }
                confirmBtnText="Sim"
                cancelBtnText="Não"
                showCancel
            >
                Você tem certeza que deseja deletar este item?
            </Alert>
        );
    }

    handleDelete = () => {
        this.props.HideAlert();
        this.props.DeleteCommunication(this.state.deleteItemId);
    }
    //
    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { t, classes } = this.props;
        const { communicationsData, created, open } = this.state;

        return (
            <Fragment>
                <DocumentTitle title="PRx Consultoria - Comunicados" />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader style={{ padding: '28px', paddingBottom: '10px' }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={7}>
                                        <Section title="Comunicados" />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            className={classes.marginRight}
                                            onClick={() => this.handleNew()}>Novo</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody style={{ padding: '28px' }}>
                                {communicationsData && communicationsData.length > 0 ?
                                    <Table
                                        striped
                                        tableHead={[
                                            "Titulo",
                                            "Autor",
                                            "Criado",
                                            "Aprovado?",
                                            "Aprovado em"
                                        ]}
                                        tableData={communicationsData.map((item) => ([
                                            item.title,
                                            _.find(this.state.brokersList, ['id', item.author]).name,
                                            moment(item.created).format("DD/MM/YYYY"),
                                            item.approved ? "Sim" : "Nâo",
                                            item.approvedDate !== "0000-00-00 00:00:00" ? moment(item.approvedDate).format("DD/MM/YYYY") : "-",
                                            <Fragment>
                                                <Button
                                                    justIcon
                                                    size="sm"
                                                    value={item.id}
                                                    color="primary"
                                                    onClick={this.handleView.bind(this, item)}
                                                >
                                                    <Eyes className={classes.icons} />
                                                </Button>
                                                {this.props.user.admin &&
                                                    <>
                                                        <Button
                                                            justIcon
                                                            size="sm"
                                                            value={item.id}
                                                            color="primary"
                                                            onClick={this.handleEdit.bind(this, item.id)}
                                                        >
                                                            <Edit className={classes.icons} />
                                                        </Button>
                                                        <Button
                                                            justIcon
                                                            size="sm"
                                                            value={item.id}
                                                            color="danger"
                                                            onClick={this.askDelete.bind(this, item.id)}
                                                        >
                                                            <Delete className={classes.icons} />
                                                        </Button>
                                                    </>
                                                }
                                            </Fragment>
                                        ]))}
                                        customCellClasses={[classes.center, classes.right, classes.right]}
                                        customClassesForCells={[0, 4, 5]}
                                        customHeadCellClasses={[
                                            classes.center,
                                            classes.right,
                                            classes.right
                                        ]}
                                        customHeadClassesForCells={[0, 4, 5]}
                                    />
                                    : <h5>Nenhum registro encontrado</h5>}
                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer >

                <Dialog
                    className={{
                        root: classes.center + " " + classes.modalRoot,
                        paper: classes.modal
                    }}
                    fullWidth={true}
                    maxWidth='md'
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose.bind(this)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-communication"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <Button
                            justIcon
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="transparent"
                            onClick={this.handleClose.bind(this)}
                        >
                            <Close className={classes.modalClose} />
                        </Button>
                        <h4 className={classes.modalTitle} >{this.state.title}</h4>
                    </DialogTitle>
                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classes.modalBody}
                        style={{paddingTop: "0"}}
                    >
                        <div ref={modal}></div>
                    </DialogContent>

                    <DialogActions>
                        {this.state.id && <AttachmentList objId={this.state.id} editMode={false} />}
                        <div style={{ float: 'right', paddingTop: '20px', marginRight: "20px" }}>
                            {this.state.approvedDate !== "0000-00-00 00:00:00" ? moment(this.state.approvedDate).format("DD/MM/YYYY") : "-"}
                        </div>
                    </DialogActions>

                </Dialog>
            </Fragment >
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        lsCommunications: state.communications.lsCommunications,
        communicationItem: state.communications.communicationItem,
        communicationUpdate: state.communications.communicationUpdate,
        insertCommunication: state.communications.insertCommunication,
        communicationDelete: state.communications.communicationDelete,
        communicationsFailed: state.communications.communicationsFailed,
        communicationItemFailed: state.communications.communicationItemFailed,
        communicationUpdateFailed: state.communications.communicationUpdateFailed,
        insertCommunicationFailed: state.communications.insertCommunicationFailed,
        communicationDeleteFailed: state.communications.communicationDeleteFailed,

        lsBrokers: state.brokers.lsBrokers,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetBrokers, GetCommunications, GetCommunicationById, PutCommunication, PostCommunicationData, DeleteCommunication, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedTablesStyle),
    withStyles(sweetAlertStyle),
    withStyles(modalStyle),
)(Communication); 