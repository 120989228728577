import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
    lsBrokers: {},
    brokerItem: {},
    brokerUpdate: {},
    insertBroker: {},
    brokerDelete: {},
    changePassword: {},
    resetPassword: {},
    brokersFailed: false,
    brokerItemFailed: false,
    brokerUpdateFailed: false,
    insertBrokerFailed: false,
    brokerDeleteFailed: false,
    changePasswordFailed: false,
    resetPasswordFailed: false,
};

// get all
const getBrokersData = (state, action) => {
    return updateObject(state, {
        lsBrokers: action.lsBrokers
    });
};

// get by id
const getBrokerById = (state, action) => {
    return updateObject(state, {
        brokerItem: action.brokerItem
    });
};

// update
const updateBroker = (state, action) => {
    return updateObject(state, {
        brokerUpdate: action.brokerUpdate
    });
};

// update
const postBroker = (state, action) => {
    return updateObject(state, {
        insertBroker: action.insertBroker
    });
};

// delete
const deleteBroker = (state, action) => {
    return updateObject(state, {
        brokerDelete: action.brokerDelete
    });
};

const changePassword = (state, action) => {
    return updateObject(state, {
        changePassword: action.changePassword
    });
};

const resetPassword = (state, action) => {
    return updateObject(state, {
        resetPassword: action.resetPassword
    });
};

// ++++++++++++++++ DATA FAIL ++++++++++++++++++++++++
const getBrokersFailed = (state, action) => {
    return updateObject(state, {
        brokersFailed: action.brokersFailed
    });
};

// get by id
const getBrokerByIdFailed = (state, action) => {
    return updateObject(state, {
        brokerItemFailed: action.brokerItemFailed
    });
};

const updateBrokerFailed = (state, action) => {
    return updateObject(state, {
        brokerUpdateFailed: action.brokerUpdateFailed
    });
};

const postBrokerFailed = (state, action) => {
    return updateObject(state, {
        insertBrokerFailed: action.insertBrokerFailed
    });
};

const deleteBrokerFailed = (state, action) => {
    return updateObject(state, {
        brokerDeleteFailed: action.brokerDeleteFailed
    });
};

const changePasswordFailed = (state, action) => {
    return updateObject(state, {
        changePasswordFailed: action.changePasswordFailed
    });
};

const resetPasswordFailed = (state, action) => {
    return updateObject(state, {
        resetPasswordFailed: action.resetPasswordFailed
    });
};

//
const BrokerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BROKERS_DATA:
            return getBrokersData(state, action);
        case actionTypes.GET_BROKERS_DATA_FAILED:
            return getBrokersFailed(state, action);

        case actionTypes.GET_BROKER_BY_ID:
            return getBrokerById(state, action);
        case actionTypes.GET_BROKER_BY_ID_FAILED:
            return getBrokerByIdFailed(state, action);

        case actionTypes.POST_BROKER_DATA:
            return postBroker(state, action);
        case actionTypes.POST_BROKER_DATA_FAILED:
            return postBrokerFailed(state, action);

        case actionTypes.PUT_BROKER_DATA:
            return updateBroker(state, action);
        case actionTypes.PUT_BROKER_FAILED:
            return updateBrokerFailed(state, action);

        case actionTypes.DELETE_BROKER_DATA:
            return deleteBroker(state, action);
        case actionTypes.DELETE_BROKER_FAILED:
            return deleteBrokerFailed(state, action);

        case actionTypes.CHANGE_PASSWORD:
            return changePassword(state, action);
        case actionTypes.CHANGE_PASSWORD_FAILURE:
            return changePasswordFailed(state, action);

        case actionTypes.RESET_PASSWORD:
            return resetPassword(state, action);
        case actionTypes.RESET_PASSWORD_FAILED:
            return resetPasswordFailed(state, action);

        default:
            return state;
    }
};

export default BrokerReducer;