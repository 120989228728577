import React, { Fragment } from "react";
import * as _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { Formik } from "formik";
import compose from "utils/compose";
import DocumentTitle from 'react-document-title';
import Alert from "react-bootstrap-sweetalert";

// ReactQuill
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import Loader from "components/Loader/Loader";
import AttachmentList from "components/AttachmentList/AttachmentList.js";
import Section from "components/Section/Section.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { withStyles } from "@material-ui/core/styles";

// REDUX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PostAttachmentsData, FilesUploadPost, GetCommunications, GetCommunicationById, PutCommunication, PostCommunicationData, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';

import styles from "../home.module.scss";

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
};

const statusOptions = [
    { value: 'true', label: "Aprovado" },
    { value: 'false', label: "Reprovado" },
];

class CommForm extends React.Component {

    constructor(props) {
        super(props);
        let formik = React.createRef();
        this.state = {
            id: "",
            title: "",
            description: "",
            richTextValue: "",
            created: "",
            updated: "",
            author: "",
            approved: false,

            editMode: false,
            disabledForm: false,

            filesCollection: [],
        }
        this.onChangeRTE = this.onChangeRTE.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);
        //
        if (!this.props.user) {
            this.props.history.push(`/auth/login`);
        }
        //
        let _new = _.includes(this.props.match.path, "new");
        let _edit = _.includes(this.props.match.path, "edit");
        if (_new) {
            this.setState({ editMode: _new, disabledForm: !_new });
            this.props.HideLoader();
        } else if (_edit) {
            this.setState({ editMode: _edit, disabledForm: !_edit });
        } else {
            this.setState({ editMode: false, disabledForm: true });
        }
        //
        const id = this.props.match && this.props.match.params.id;
        if (!_.isEmpty(id) && _.isEmpty(this.props.lsCommunications) && _edit) {
            this.props.GetCommunicationById(id);
        } else {
            if (_edit) {
                let item = _.find(this.props.lsCommunications, ['id', id]);
                this.setState({
                    ...item,
                    created: moment(item.created).format("DD/MM/YYYY"),
                    richTextValue: item.description
                });
                this.props.HideLoader();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.communicationItem !== this.props.communicationItem) {
            this.setState({
                ...this.props.communicationItem,
                created: moment(this.props.communicationItem.created).format("DD/MM/YYYY"),
                richTextValue: this.props.communicationItem.description
            });
            this.props.HideLoader();
        }
        if (prevProps.communicationUpdate !== this.props.communicationUpdate) {
            // console.log("Communication Update: ", this.props.communicationUpdate);
            if (this.state.filesCollection.length > 0) {
                this.handleUploadFile();
            } else {
                this.props.SweetAlert(
                    <Alert
                        success
                        style={{ display: "block" }}
                        title={""}
                        onConfirm={() => { this.handleBack(); }}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText={"OK"}
                    >
                        Dados carregados com sucesso
                </Alert >
                )
                setTimeout(() => this.handleBack(), 3000);
            }
        }
        if (prevProps.insertCommunication !== this.props.insertCommunication) {
            if (this.state.filesCollection.length > 0) {
                this.setState({ ...this.props.insertCommunication }, () => {
                    this.handleUploadFile();
                });
            } else {
                this.props.SweetAlert(
                    <Alert
                        success
                        style={{ display: "block" }}
                        title={""}
                        onConfirm={() => { this.handleBack(); }}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText={"OK"}
                    >
                        Comunicado inserido com sucesso!
                </Alert>
                )
                setTimeout(() => this.handleBack(), 3000);
            }
        }

        if (prevProps.insertAttachment !== this.props.insertAttachment) {
            console.log(">>> insertAttachment: ", this.props.insertAttachment);
            if (this.props.insertAttachment.length > 0) {
                this.props.history.push(`/manageCommunications`);
                // this.setState({ id: "" }, () => {
                //     this.componentDidMount();
                // });
            }
        }
        //
        // FAILED
        if (prevProps.communicationsFailed !== this.props.communicationsFailed) {
            console.log("communicationsFailed: ", this.props.communicationsFailed);
            this.handleRequestFail("Erro ao consultar as corretoras");
        }
        if (prevProps.communicationItemFailed !== this.props.communicationItemFailed) {
            console.log("communicationItemFailed: ", this.props.communicationItemFailed);
            this.handleRequestFail("Erro ao consultar a corretora");
        }
        if (prevProps.communicationUpdateFailed !== this.props.communicationUpdateFailed) {
            console.log("communicationUpdateFailed: ", this.props.communicationUpdateFailed);
            this.handleRequestFail("Erro ao atualizar a corretora");
        }
        if (prevProps.insertCommunicationFailed !== this.props.insertCommunicationFailed) {
            console.log("insertCommunicationFailed: ", this.props.insertCommunicationFailed);
            this.handleRequestFail("Erro ao inserir a corretora");
        }
    }

    handleRequestFail = (msg) => {
        this.props.HideLoader();
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={"Atenção!"}
                onConfirm={() => { this.props.HideAlert(); }}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                confirmBtnText={"OK"}
            >
                {msg}
            </Alert>
        )
    }

    handleSave = () => {
        const { title, description, approved } = this.state;
        //
        if (this.state.description.replace("<p><br></p>", "") !== "") {
            this.setState({ editMode: false, disabledForm: true });
            this.props.ShowLoader(<Loader />);
            //  
            if (!_.isEmpty(this.state.id)) {
                // EDIT
                let obj = {
                    title: title,
                    description: description,
                    approved: Boolean(approved)
                }
                if (Boolean(approved) == true) {
                    obj.approvedDate = moment();
                }
                // console.log(this.state.id, obj);
                this.props.PutCommunication(this.state.id, obj);
            } else {
                // NEW
                let obj = {
                    title: title,
                    description: description,
                    approved: Boolean(approved),
                    author: this.props.user.id
                }
                if (Boolean(approved) == true) {
                    obj.approvedDate = moment();
                }
                // console.log(this.state.id, obj);
                this.props.PostCommunicationData(obj);
            }
        }
    }

    handleUploadFile = () => {
        this.props.ShowLoader(<Loader />);
        this.props.FilesUploadPost(this.state.id, this.state.filesCollection).then(rest => {
            if (rest.status == 201) {
                this.props.PostAttachmentsData(this.props.filesObj);
            }
        });
    }

    onFileChange(e) {
        this.setState({ filesCollection: e.target.files })
    }

    onChangeRTE = (richTextValue) => {
        this.setState({ richTextValue, description: richTextValue.toString('html') });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChangeRTE(
                richTextValue.toString('html')
            );
        }
    };

    handleChange = (event) => {
        const id = event.target.id || event.target.name;
        let value = event.target.value;;
        //
        if (id === "approved") {
            value = event.target.checked;
        }
        this.setState({ [id]: value });
    }

    handleBack = () => {
        this.props.HideAlert();
        if (this.props.user.admin) {
            this.props.history.push(`/manageCommunications`);
        } else {
            this.props.history.push(`/communications`);
        }
    }

    handleCancel = () => {
        if (this.props.user.admin) {
            this.props.history.push(`/manageCommunications`);
        } else {
            this.props.history.push(`/communications`);
        }
    }

    handleEdit = () => {
        this.props.history.push(`/manageCommunications/edit/${this.state.id}`);
    }

    CancelForm = () => {
        this.setState({
            editMode: false, disabledForm: true,
            richTextValue: this.props.regulationData.regulation
        });
        this.props.HideLoader();
        this.props.HideAlert();
        this.props.history.goBack();
    }

    // QUILL TEXT EDITOR
    modules = {
        toolbar: [
            // [{ 'font': [] }],
            // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            // [{ 'list': 'ordered' }, { 'list': 'bullet' },
            // { 'indent': '-1' }, { 'indent': '+1' }],
            // ['link', 'image'],
            // ['clean']
            ['bold', 'italic', 'underline', 'strike'],       // toggled buttons
            ['blockquote', 'code-block'],                    // blocks
            [{ 'header': 1 }, { 'header': 2 }],              // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],    // lists
            [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
            ['link', 'image'],								// link image
            [{ 'direction': 'rtl' }],                        // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],       // header dropdown
            [{ 'color': [] }, { 'background': [] }],         // dropdown with defaults
            [{ 'font': [] }],                                // font family
            [{ 'align': [] }],                               // text align
            ['clean'],                                       // remove formatting
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    disabledModules = {
        toolbar: [
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    formats = [
        // 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote',
        // 'list', 'bullet', 'indent', 'link', 'image', 'video'
        'header', 'font', 'background', 'color', 'code', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'script', 'align', 'direction',
        'link', 'image', 'code-block', 'formula', 'video'
    ]

    render() {
        const { classes } = this.props;
        const { editMode, disabledForm, id, title, description, created, updated, author, approved, richTextValue } = this.state;
        return (
            <Fragment>
                <DocumentTitle title={"PRx Consultoria - Editar Comunicado"} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody style={{ padding: "28px" }}>
                                <CardHeader style={{ padding: '0', paddingBottom: '10px' }}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={8} md={7}>
                                            <Section title={editMode ? "Novo comunicado" : "Editar comunicado"} />
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={5} style={{ textAlign: 'right' }}>
                                            {editMode &&
                                                <Button
                                                    size="sm"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleSave}>
                                                    Salvar
                                    </Button>}
                                            {" "}
                                            {editMode &&
                                                <Button
                                                    color="info"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={this.handleBack}
                                                >Cancelar</Button>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>


                                <CardBody style={{ padding: '0px' }}>
                                    <GridContainer>
                                        {/* TITLE */}
                                        <GridItem xs={12} md={12} lg={12}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelText="Titulo"
                                                id="title"
                                                name="title"
                                                inputProps={{
                                                    value: title,
                                                    onChange: this.handleChange,
                                                    type: "text",
                                                    disabled: disabledForm
                                                }}
                                            />
                                        </GridItem>
                                        {/* DESCRIPTION */}
                                        <GridItem xs={12}>
                                            <ReactQuill
                                                theme={disabledForm ? "bubble" : "snow"}
                                                value={richTextValue}
                                                modules={disabledForm ? this.disabledModules : this.modules}
                                                formats={this.formats}
                                                onChange={value => this.onChangeRTE(value)}
                                                readOnly={disabledForm}
                                            />
                                        </GridItem>
                                        <hr />
                                        {editMode && <GridItem xs={12} style={{ marginTop: "20px" }}>
                                            <input
                                                type="file"
                                                name="filesCollection"
                                                onChange={this.onFileChange}
                                                multiple
                                                accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf" />
                                        </GridItem>}
                                        {/* APPROVED */}
                                        {this.props.user.admin &&
                                            <Fragment>
                                                <GridItem xs={12} sm={12} md={1} >
                                                    <FormLabel className={classes.labelHorizontal} style={{ color: "#000", float: 'none' }}>
                                                        Aprovado?
                                                </FormLabel>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={11}>
                                                    <FormControlLabel
                                                        style={{ paddingTop: '30px', paddingLeft: '5px' }}
                                                        control={
                                                            <Switch
                                                                name="approved"
                                                                id="approved"
                                                                disabled={disabledForm}
                                                                checked={approved}
                                                                onChange={this.handleChange}
                                                                value={approved}
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar,
                                                                    disabled: classes.disabled
                                                                }}
                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label + " " + (disabledForm ? styles.DisabledCursor : "")
                                                        }}
                                                        label={approved ? "Sim" : "Não"}
                                                    />
                                                </GridItem>
                                            </Fragment>
                                        }
                                        {/*  */}
                                        {id && <AttachmentList objId={id} editMode={editMode} />}
                                    </GridContainer>
                                </CardBody>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer >
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user,

        filesObj: state.filesUpload.filesObj,
        insertAttachment: state.attachments.insertAttachment,
        insertAttachmentFailed: state.attachments.insertAttachmentFailed,

        lsCommunications: state.communications.lsCommunications,
        communicationItem: state.communications.communicationItem,
        communicationUpdate: state.communications.communicationUpdate,
        insertCommunication: state.communications.insertCommunication,
        communicationsFailed: state.communications.communicationsFailed,
        communicationItemFailed: state.communications.communicationItemFailed,
        communicationUpdateFailed: state.communications.communicationUpdateFailed,
        insertCommunicationFailed: state.communications.insertCommunicationFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { PostAttachmentsData, FilesUploadPost, GetCommunications, GetCommunicationById, PutCommunication, PostCommunicationData, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(regularFormsStyle),
    withStyles(sweetAlertStyle),
)(CommForm); 